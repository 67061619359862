import Vue from "vue";

import { collection, doc, addDoc, getDoc, getDocs, updateDoc, deleteDoc, onSnapshot, serverTimestamp } from "firebase/firestore";

import { firestore } from "@/common/firebase";

import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, GET, CREATE, UPDATE, REMOVE, LISTEN, UNLISTEN, RESET } from "../../actions";
import { SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},

  unsubscribe: null,
});

const getters = {
  itemsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId, documentId }) {
    try {
      const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}`);
      const blocksCollectionRef = collection(docRef, "blocks");
      const blocksSnapshot = await getDocs(blocksCollectionRef);

      // Extract data from each document in the 'blocks' subcollection
      const data = blocksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      if (data) {
        Object.entries(data).forEach(([id, value]) => {
          commit(SET_ITEM, { id, ...value });
        });
      }

      return { documentId, ...data };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [GET](_, { projectId, documentId, blockId }) {
    try {
      // Reference to the main document
      const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}/blocks/${blockId}`);
      const contentSnapshot = await getDoc(docRef);

      if (!contentSnapshot.exists()) {
        throw new Error("Document not found");
      }

      // Get the data from the main document
      const docData = contentSnapshot.data();

      return { blockId, ...docData };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [CREATE]({ rootGetters }, { projectId, documentId, ...payload }) {
    const uid = rootGetters["auth/uid"];

    try {
      let createdAt = serverTimestamp();
      const collectionRef = collection(firestore, `projects/${projectId}/documents/${documentId}/blocks`);
      const result = await addDoc(collectionRef, { uid, createdAt, ...payload });

      return { isSuccess: true, id: result.id };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [UPDATE](_, { projectId, documentId, blockId, fieldPath, value }) {
    try {
      // Document reference
      const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}/blocks/${blockId}`);

      const updatePayload = {
        [fieldPath]: value,
      };

      await updateDoc(docRef, updatePayload);

      return { isSuccess: true, blockId };
    } catch (error) {
      console.log(error);
      return { isSuccess: false, error };
    }
  },
  async [REMOVE](_, { projectId, documentId, blockId }) {
    try {
      const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}/blocks/${blockId}`);
      await deleteDoc(docRef);

      return { isSuccess: true, blockId };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ state, commit }, { projectId, documentId }) {
    const docRef = doc(firestore, `projects/${projectId}/documents/${documentId}`);
    const blocksCollectionRef = collection(docRef, "blocks");

    state.unsubscribe = onSnapshot(blocksCollectionRef, (blocksSnapshot) => {
      blocksSnapshot.docs.forEach((doc) => {
        commit(SET_ITEM, { id: doc.id, ...doc.data() });
      });
    });
  },
  async [UNLISTEN]({ state }) {
    if (state.unsubscribe) {
      state.unsubscribe();
      state.unsubscribe = null;
    }
  },

  async [RESET]({ commit }) {
    commit(RESET_STORE);
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
