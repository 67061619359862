<template>
  <div class="spinner-container bg-dark-jungle">
    <switch-dark-mode class="d-none" />

    <div>
      <img src="@/assets/character/smiling.png" class="d-block m-auto" width="100" height="100" />
    </div>
  </div>
</template>

<script>
import SwitchDarkMode from "@/components/Navigation/SwitchDarkMode";

export default {
  name: "Spinner",
  components: { SwitchDarkMode },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.spinner-container {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme--dark .spinner-container {
  background: $black-light;
}
</style>
