export const NUMBER_ITEMS_MAX = 5;

export const LANDING_STATE_MACHINE_SEO_URL = {
  id: "wizard-empty",
  initialStep: "step-wizard",
  context: {
    currentStep: "step-wizard",
    formData: {
      name: "",
    },
  },
  steps: {
    "step-wizard": {
      NEXT: {},
      BACK: {},
      options: {
        fields: [
          {
            componentName: "Url",
            name: "url",
            validators: ["url"],
            label: "Your URL",
            attributes: {
              placeholder: "Insert the URL of your website or blog...",
            },
          },
        ],
      },
    },
  },
};

export const LANDING_STATE_MACHINE_SEO_POST = {
  id: "form-lp-goodbyeseo",
  initialStep: "step-wizard",
  context: {
    currentStep: "step-wizard",
    formData: {
      structureId: "how-to",
      name: "",
      description: "",
      keywords: "",
    },
  },
  steps: {
    "step-wizard": {
      NEXT: {},
      BACK: {},
      options: {
        fields: [
          {
            componentName: "Select",
            name: "structureId",
            validators: ["required"],
            label: "Structure",
            attributes: {
              options: [
                { value: "how-to", text: "How-to Guide" },
                { value: "listicle", text: "Listicle" },
                { value: "news-update", text: "News Update" },
                { value: "opinion", text: "Opinion Piece" },
                { value: "case-study", text: "Case Study" },
                { value: "tutorial", text: "Tutorial" },
                { value: "comparison", text: "Comparison Post" },
                { value: "FAQ", text: "FAQ" },
                { value: "checklist", text: "Checklist" },
                { value: "other", text: "Other" },
              ],
            },
          },
          {
            componentName: "Message",
            name: "description",
            validators: ["required"],
            label: "Description",
            attributes: {
              placeholder: "Insert the description of your blog post...",
            },
          },
          {
            componentName: "SeoKeywords",
            name: "keywords",
            validators: ["required"],
            label: "Keywords",
            attributes: {
              placeholder: "Insert the keywords you'd like to optimize for (separate each keyword with a comma)...",
            },
          },
        ],
      },
    },
  },
};

export const WIZARD_STATE_MACHINE = {
  id: "wizard-empty",
  initialStep: "step-wizard",
  context: {
    currentStep: "step-wizard",
    formData: {
      name: "",
    },
  },
  steps: {
    "step-wizard": {
      NEXT: {},
      BACK: {},
      options: {
        fields: [
          {
            componentName: "Input",
            name: "name",
            validators: ["required"],
            label: "Name",
            attributes: {
              placeholder: "Insert the name of your space...",
            },
          },
        ],
      },
    },
  },
};

export const NAVIGATION = [
  // { id: "billing", icon: "navigation/billing", name: "Billing", to: "UserBilling", color: "#151718" },
  // {
  //   id: "teammates",
  //   icon: "navigation/teammates",
  //   name: "AI Teammates",
  //   to: "ProjectTeammates",
  //   color: "#151718",
  //   condition: () => true,
  //   container: "teammates",
  // },
  {
    id: "archive",
    icon: "navigation/archive",
    name: "Archive",
    to: "ProjectArchive",
    color: "#151718",
    condition: () => true,
  },
  {
    id: "settings",
    icon: "navigation/settings",
    name: "Settings",
    to: "ProjectSettings",
    color: "#151718",
    condition: (isHome) => !isHome,
  },
];

export const NAVIGATION_PUBLIC = [
  {
    id: "archive",
    icon: "navigation/archive",
    name: "Archive",
    to: "ProjectArchive",
    color: "#151718",
  },
  {
    id: "settings",
    icon: "navigation/settings",
    name: "Settings",
    to: "ProjectSettings",
    color: "#151718",
  },
];

export const BILLING = {
  TEAMMATES: "teammates",
  STORAGE: "storage",
  MESSAGES: "messages",
  CREDITS: "credits",
};
