export const passwordMustScoreHigh = (_, $vm) => {
  const { passwordScore = 0 } = $vm;
  if (passwordScore >= 3) return true;
  return false;
};

export const priceComparisonLessThanPrice = (_, $vm) => {
  const { price, priceComparison } = $vm;
  if (!price || !priceComparison) return true;
  else if (parseInt(price) < parseInt(priceComparison)) return true;
  return false;
};

export const isValidEthereumAddress = (_, $vm) => {
  const { to } = $vm;
  return /^0x[a-fA-F0-9]{40}$/.test(to);
};
