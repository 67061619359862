<template>
  <div>
    <b-button pill data-cy="link-login" variant="link" @click="$emit('previous')" class="p-1 pr-2">
      <small><b-icon-chevron-left font-scale="0.8" style="margin-bottom: 3px" /> {{ "Back" | __t("button__back") }}</small>
    </b-button>

    <h3 class="mt-50 text-center text-uppercase text-white">
      {{ "Login With Email" | __t("auth__login-with-email") }}
    </h3>

    <p class="mb-50 text-center">{{ "Fill the following form." | __t("auth__fill-the-following-form") }}</p>

    <login-form @success="$router.push({ name: 'Projects' })" />

    <router-link :to="{ name: 'password' }" data-cy="link-password" class="text-info d-inline-block mt-1">
      {{ "Forgotten password?" | __t("auth__forgotten-password") }}
    </router-link>
  </div>
</template>

<script>
import { BButton, BIconChevronLeft } from "bootstrap-vue";

import LoginForm from "@/components/Forms/Login";

export default {
  name: "EmailPassword",
  components: {
    BButton,
    BIconChevronLeft,
    LoginForm,
  },
};
</script>
