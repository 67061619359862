import store from "@/store/";
import { CREATE } from "@/store/actions";

import { DEFAULT_LANGUAGE } from "@/constants/language";

const { NODE_ENV } = process.env;

export const getTranslation = (defaultText, location, languageOverride) => {
  const language =
    languageOverride || store.state.auth.user?.private?.language || store.state.locales.language || DEFAULT_LANGUAGE;

  // console.log("location", location);

  if (!defaultText) return "";
  if (!location) return defaultText;
  if (language === DEFAULT_LANGUAGE || language.includes(DEFAULT_LANGUAGE)) return defaultText;

  const getTranslateFromLocale = store.getters["locales/getTranslation"];

  const result = getTranslateFromLocale(language, location);

  if (!result && NODE_ENV === "development") {
    const isAdmin = store.getters["auth/isAdmin"];
    if (isAdmin) store.dispatch(`locales/${CREATE}`, { language, location, defaultText });
    else console.log("Location", location);
  }

  return result || defaultText;
};
