/* eslint-disable no-unused-vars */
import Vue from "vue";
import { uploadBytesResumable, getDownloadURL, ref as refStorage, getBlob, deleteObject } from "firebase/storage";
import {
  ref,
  push,
  get,
  set,
  update,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  runTransaction,
  serverTimestamp,
} from "firebase/database";
import { saveAs } from "file-saver";

import { database, storage } from "@/common/firebase";
import { removeStorage } from "@/common/functions";

import { isAboveLimit } from "@/helpers/users";
import { getFileDuration } from "@/helpers/storage";
import { createModuleResetter, ObjectToSortedArray } from "@/helpers/utils";

import { UPLOAD, DOWNLOAD, FETCH, GET, REMOVE } from "../actions";
import { SET_ITEM, DEL_ITEM, RESET_STORE } from "../mutations";
import { ERRORS_CODES } from "@/constants/errors";

const getInitState = () => ({
  items: {},
});

const getters = {
  uploadsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    Vue.set(state.items, id, { ...payload });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};
const actions = {
  async [UPLOAD](
    { rootGetters },
    {
      projectId = null,
      assetId = null,
      stateCallback = () => {},
      errorCallback = () => {},
      uploadedCallback = () => {},
      ...payload
    }
  ) {
    const uid = rootGetters["auth/uid"];

    if (isAboveLimit("storage")) return { isSuccess: false, errorCode: ERRORS_CODES.MAX_STORAGE };

    const createdAt = serverTimestamp();

    const { file, languageCode = null } = payload;
    const { type, size } = file;

    let { name } = file;
    name = name.replace(/[.#$[/]^]/g, "^"); // Sanitize to fit Firebase key

    let _ = null;
    let format = null;
    if (type.includes("application")) [_, format] = type.split("/");
    else [format] = type.split("/");

    let duration = null;
    if (type.startsWith("audio") || type.startsWith("video")) {
      duration = await getFileDuration(file);
    }

    try {
      const data = { uid, createdAt, uploadedBy: uid, name, type, format, size, duration, languageCode };
      const path = projectId ? `projects/${projectId}` : `users/${uid}`;

      let id = null;
      if (assetId) id = assetId;
      else id = await push(ref(database, `${path}/storage`)).key;

      set(ref(database, `${path}/storage/${id}`), data);
      set(ref(database, `${path}/index/storage/${id}`), data);

      const storageRef = refStorage(storage, `${path}/storage/${id}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      await new Promise((r) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            stateCallback(snapshot);
          },
          (error) => {
            errorCallback(error);
            r();
          },
          () => {
            uploadedCallback();
            r();
          }
        );
      });

      const url = await getDownloadURL(uploadTask.snapshot.ref);

      update(ref(database, `${path}/storage/${id}`), { url });
      update(ref(database, `${path}/index/storage/${id}`), { url });

      return { isSuccess: true, id, name, url, size, duration, uploadTask };
    } catch (error) {
      console.log(error);
      return { isSuccess: false, error };
    }
  },

  async [DOWNLOAD](store, { path, filename = "noname" }) {
    try {
      const ref = refStorage(storage, path);
      const url = await getDownloadURL(ref);

      saveAs(url, filename);

      return url;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [FETCH]({ commit, rootGetters }, { projectId }) {
    const uid = rootGetters["auth/uid"];

    const path = projectId ? `projects/${projectId}` : `users/${uid}`;

    const uploadsRef = ref(database, `${path}/index/storage`);
    const uploads = await get(uploadsRef).then((snapshot) => snapshot.val());

    for (const uploadId in uploads) commit(SET_ITEM, { id: uploadId, ...uploads[uploadId] });

    onChildAdded(uploadsRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(uploadsRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(uploadsRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },
  async [GET]({ rootGetters }, { projectId, id }) {
    const uid = rootGetters["auth/uid"];

    const path = projectId ? `projects/${projectId}` : `users/${uid}`;

    try {
      const storageRef = ref(database, `${path}/storage/${id}`);
      const snapshot = await get(storageRef);

      return snapshot.val();
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, error };
    }
  },
  async [REMOVE]({ rootGetters }, { projectId, id }) {
    const uid = rootGetters["auth/uid"];

    const path = projectId ? `projects/${projectId}` : `users/${uid}`;

    try {
      await removeStorage({ projectId, id });

      const dbRef = ref(database, `${path}/storage/${id}`);
      await set(dbRef, null);

      return { isSuccess: true };
    } catch (error) {
      console.log(error);
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
