import Vue from "vue";
import { getDatabase, ref, query, get, orderByKey, onChildAdded, onChildRemoved } from "firebase/database";

import { initiateStripeCheckoutSetup, confirmStripePaymentMethod, removeStripePaymentMethod } from "@/common/functions";

import { objectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, REMOVE, LISTEN, INTENT, CONFIRM } from "../../actions";
import { SET_KEY, SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const database = getDatabase();

const getInitState = () => ({
  items: {},
});

const getters = {
  itemsSorted: (state) => {
    return objectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET_KEY](state, { key, value }) {
    state[key] = value;
  },

  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },

  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit, rootGetters }) {
    const uid = rootGetters["auth/uid"];

    const resultRef = query(ref(database, `users/${uid}/billing/paymentMethods`), orderByKey());
    const snapshot = await get(resultRef);

    const data = snapshot.val();
    console.log("data", data);
    if (data) {
      Object.entries(data).forEach(([id, data]) => {
        commit(SET_ITEM, { id, ...data });
      });
    }

    return { isSuccess: true, data };
  },
  async [REMOVE](_, payload) {
    try {
      await removeStripePaymentMethod(payload);

      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ rootGetters, commit }) {
    const uid = rootGetters["auth/uid"];

    const resultRef = query(ref(database, `users/${uid}/billing/paymentMethods`), orderByKey());

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },

  async [INTENT](_, payload) {
    try {
      const { data } = await initiateStripeCheckoutSetup(payload);

      return { isSuccess: true, ...data };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [CONFIRM](_, payload) {
    try {
      await confirmStripePaymentMethod(payload);

      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
