/* eslint-disable no-inner-declarations */

import { createVisit } from "@/common/functions";

import { createModuleResetter } from "@/helpers/utils";

import { CREATE } from "../../actions";
import { RESET_STORE } from "../../mutations";

const getInitState = () => ({});

const getters = {};

const mutations = {
  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [CREATE](_, payload) {
    const { url } = payload;
    const utmOverride = {};

    if (url.includes("try-now") && !url.includes("utm_name")) {
      utmOverride.name = "single-sentence__non-fiction";
    }

    if (url.includes("try-now") && !url.includes("utm_source")) {
      utmOverride.source = "none";
    }

    try {
      const { data } = await createVisit({ ...payload, utmOverride });

      return { isSuccess: true, ...data };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, result: null };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
