<template>
  <div class="base-input">
    <b-form-input
      ref="innerInput"
      :value="value"
      :name="name"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :disabled="disabled"
      :id="id"
      @keyup.enter="$emit('success')"
    />
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";

export default {
  name: "BaseInput",
  components: { BFormInput },
  props: {
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setFocus() {
      this.$refs.innerInput.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.base-input {
  input {
    background-color: transparent !important;
    color: white;
    border: 0px !important;
    font-size: 1.3rem;
  }

  input[disabled="disabled"] {
    background-color: $white-100;
    color: $gray-darker;
  }

  input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}
</style>
