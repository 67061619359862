export const GRADIENTS = [
  ["#FFB178", "#FF3C8E"],
  ["#A0BBFF", "#EC77FF"],
  ["#A0F9FF", "#7795FF"],
  ["#8E2DE2", "#4A00E0"],
  ["#FDC830", "#F37335"],
  ["#FDC830", "#F37335"],
  ["#ED213A", "#93291E"],
  ["#4e54c8", "#8f94fb"],
  ["#00b09b", "#96c93d"],
  ["#00b09b", "#96c93d"],
  ["#ff9966", "#ff5e62"],
];

export const FOLDERS_COLORS = {
  collection: "#0575E6",
};

export const WIDTH_PROJECT_SIDEBAR = 250;
export const HEIGHT_BUILDER_TOPBAR = 125;
export const HEIGHT_QUILL_TOOLBAR = 45;
export const WIDTH_BUILDER_SIDEBAR = 340;
export const WIDTH_BUILDER_CODE_SIDEBAR = 50;
export const WIDTH_FLOATING_QUILL = 30;
export const HEIGHT_FLOATING_QUILL = 30;

export const DEFAULT_PROJECT_ICON = "https://cdn.purplewave.ai/onboarding/icons/144_144.png";

export const IMAGE_MISSING = "https://cdn.purplewave.ai/onboarding/icons/144_144.png";

export const SCREEN_SIZES = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const STYLES_MODE = {
  LIGHT: "light",
  DARK: "dark",
};

export const WINDOW_BREAKPOINTS = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
};

export const WINDOW_BREAKPOINTS_VALUES = {
  XS: 576,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const WIDTH_USER_SIDEBAR = 80;
