<template>
  <div
    @click="$emit('click')"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="projectcategory__icon rounded-lg pl-2 pr-2 pt-1 pb-1"
  >
    <span class="float-right p-0">
      <!-- <img v-if="isHovering" src="@/assets/icons/write-active.svg" width="20" height="20" /> -->
      <!-- <img v-else src="@/assets/icons/write.svg" width="20" height="20" /> -->
      <img src="@/assets/icons/write-active.svg" width="20" height="20" />
    </span>

    <strong>
      <span
        :class="{ 'text-white': isHovering }"
        class="pr-2 pl-2 text-white text-uppercase font-weight-semibold cursor-pointer"
        >{{ name }}</span
      >
    </strong>
  </div>
</template>

<script>
export default {
  name: "Category",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isHovering: false,
  }),
};
</script>

<style lang="scss" scoped>
.projectcategory__icon {
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  width: 90%;

  span {
    color: #9b9b9b;
    font-weight: 300;
    font-size: 1.3rem;
  }
}

.projectcategory__icon:hover {
  background-color: #151718;

  img {
    transform: rotate(360deg);
  }
}
</style>
