import Vue from "vue";
import { ref, query, get, update, onChildAdded, onChildChanged, serverTimestamp } from "firebase/database";

import { database } from "@/common/firebase";
import { removeProject } from "@/common/functions";

import { createModuleResetter } from "@/helpers/utils";

import { GET, UPDATE, REMOVE, LISTEN } from "../../actions";
import { SET_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
});

const getters = {};

const mutations = {
  [SET_ITEM](state, { key, value }) {
    Vue.set(state.items, key, value);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [GET]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/settings`));

    const snapshot = await get(resultRef);
    const data = snapshot.val();

    for (const key in data) {
      commit(SET_ITEM, { key, value: data[key] });
    }

    return data;
  },
  async [UPDATE]({ rootGetters }, { projectId, ...payload }) {
    const updatedBy = rootGetters["auth/uid"];
    const updatedAt = serverTimestamp();

    const updates = {};

    try {
      updates[`projects/${projectId}/settings`] = { updatedAt, updatedBy, ...payload };

      // TODO: Handle update for other users
      updates[`users/${updatedBy}/projects/${projectId}/name`] = payload.name;
      updates[`users/${updatedBy}/projects/${projectId}/logo`] = payload.logo;

      await update(ref(database), updates);

      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [REMOVE]({ state }, { projectId }) {
    try {
      const { name } = state.items;
      const { data } = await removeProject({ projectId, name });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
  async [LISTEN]({ commit }, payload) {
    const { projectId } = payload;

    const resultRef = query(ref(database, `projects/${projectId}/settings`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { key: snapshot.key, value: snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { key: snapshot.key, value: snapshot.val() });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
