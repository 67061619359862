/*eslint-disable no-unused-vars*/
import Vue from "vue";
import { collection, addDoc, getDocs, orderBy, query, serverTimestamp } from "firebase/firestore";
import { firestore } from "@/common/firebase";
import diffMatchPatch from 'diff-match-patch';

import { createModuleResetter, base64Encode, base64Decode } from "@/helpers/utils";
import { GET, UPDATE, CREATE_HISTORY, FETCH_HISTORY, INIT_HISTORY, UNDO_HISTORY, REDO_HISTORY } from "../../actions";
import { SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
  undoStack: [],
  redoStack: [],
});

const getters = {
  canUndo(state) {
    return state.undoStack.length > 0;
  },
  canRedo(state) {
    return state.redoStack.length > 0;
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },
  [RESET_STORE]: createModuleResetter(getInitState),
  PUSH_TO_UNDO_STACK(state, action) {
    state.undoStack.push(action);
  },
  PUSH_TO_REDO_STACK(state, action) {
    state.redoStack.push(action);
  },
  POP_FROM_UNDO_STACK(state) {
    return state.undoStack.pop();
  },
  POP_FROM_REDO_STACK(state) {
    return state.redoStack.pop();
  },
  CLEAR_REDO_STACK(state) {
    state.redoStack = [];
  },
};

const actions = {
async [CREATE_HISTORY]({ commit, rootGetters }, { projectId, docId, oldContent, newContent }) {
    const uid = rootGetters["auth/uid"];
    const dmp = new diffMatchPatch();

    const oldContentStr = base64Encode(JSON.stringify(oldContent.content || {}));
    const newContentStr = base64Encode(JSON.stringify(newContent.content));

    const diffs = dmp.diff_main(oldContentStr, newContentStr);
    dmp.diff_cleanupSemantic(diffs);
    const patches = dmp.patch_make(oldContentStr, diffs);
    const changes = dmp.patch_toText(patches);

    const [newContentAfterPatch] = dmp.patch_apply(patches, oldContentStr);
    console.log("Reconstructed content:", base64Decode(newContentAfterPatch));

    const historyEntry = {
      uid,
      changes, 
      createdAt: serverTimestamp(),
    };
    await addDoc(collection(firestore, `projects/${projectId}/documents/${docId}/history`), historyEntry);

    commit('PUSH_TO_UNDO_STACK', { type: CREATE_HISTORY, docId, changes });
  },
  
async [FETCH_HISTORY](_, { projectId, docId, limit = 10 }) {
  const historyRef = query(collection(firestore, `projects/${projectId}/documents/${docId}/history`), orderBy("createdAt", "desc"), limit(limit));
  const snapshot = await getDocs(historyRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
},

async [INIT_HISTORY]({ commit }, { projectId, docId }) {
  const historyRef = query(collection(firestore, `projects/${projectId}/documents/${docId}/history`), orderBy("createdAt", "desc"));
  const snapshot = await getDocs(historyRef);
  const historyRecords = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  console.log(historyRecords, 'historyRecords')

  historyRecords.forEach(record => {
    commit('PUSH_TO_UNDO_STACK', record);
  });

},

async [UNDO_HISTORY]({ commit, state, dispatch }, { projectId, documentId }) {
  if (state.undoStack.length === 0) return;

  const lastChange = state.undoStack.pop();
  commit('PUSH_TO_REDO_STACK', lastChange);

  const dmp = new diffMatchPatch();
  const patches = dmp.patch_fromText(lastChange.changes);
  
  const documentData = await dispatch(`projectsDocuments/${GET}`, { projectId, id: documentId }, { root: true });
  const currentContentStr = JSON.stringify(documentData.content);

  console.log(currentContentStr, 'currentContentStr')
  console.log(patches, 'patches')
  
  const [reversedContentStr, _] = dmp.patch_apply(patches, currentContentStr);
  console.log(reversedContentStr, 'reversedContentStr')

  await dispatch(`projectsDocuments/${UPDATE}`, { 
    projectId, 
    id: documentId, 
    content: JSON.parse(reversedContentStr) 
  }, { root: true });
},

async [REDO_HISTORY]({ commit, state, dispatch }, { projectId, documentId }) {
  if (state.redoStack.length === 0) return;

  const lastChange = state.redoStack.pop();
  commit('PUSH_TO_UNDO_STACK', lastChange);

  const dmp = new diffMatchPatch();
  const patches = dmp.patch_fromText(lastChange.changes);
  
  const documentData = await dispatch(`projectsDocuments/${GET}`, { projectId, id: documentId }, { root: true });
  const currentContentStr = JSON.stringify(documentData.content);
  
  const [appliedContentStr, _] = dmp.patch_apply(patches, currentContentStr);

  await dispatch(`projectsDocuments/${UPDATE}`, { 
    projectId, 
    id: documentId, 
    content: JSON.parse(appliedContentStr) 
  }, { root: true });
},

};

export default {
  namespaced: true,
  state: getInitState(), 
  actions,
  mutations,
  getters
};