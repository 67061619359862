<template>
  <div data-cy="register-form">
    <errors v-if="errorMessage" :error="errorMessage" class="mb-4" data-cy="server-error" />

    <b-form @submit="triggerRegister">
      <fieldset class="form-group" data-cy="fieldset-email">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">{{ "Email" | __t("input__email") }}</p>
          <base-input v-model="email" placeholder="mycoolmail@email.com" @keyup.enter="triggerLogin" :disabled="isSending" />
        </div>

        <input-error
          v-if="!$v.email.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="field-error"
        />
        <input-error v-if="!$v.email.email" :error-code="'email'" :has-submit-error.sync="hasSubmitError" data-cy="field-error" />
      </fieldset>

      <fieldset class="password-field form-group" data-cy="fieldset-password">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">{{ "Password" | __t("input__password") }}</p>
          <base-password
            name="password"
            type="password"
            id="password"
            v-model="password"
            :disabled="isSending"
            placeholder="*******"
            counter
            @keyup.enter.native="triggerRegister"
          />
          <strength-meter
            v-model="password"
            strength-meter-only
            @score="updatePasswordScore"
            class="strength-meter"
            strengthMeterClass="Password__strength-meter mb-1"
          />
        </div>

        <input-error
          v-if="!$v.password.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="field-error"
        />

        <input-error v-if="!$v.password.minLength" :has-submit-error.sync="hasSubmitError" data-cy="field-error">
          <!-- input-error -->
          {{ "Password must have at least" | __t("input-error__password-must-have-at-least") }}
          {{ $v.password.$params.minLength.min }}
          {{ "characters." | __t("input-error__password-characters") }}
        </input-error>

        <input-error
          v-if="!$v.password.passwordMustScoreHigh"
          error-code="'passwordNotStrong'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="field-error"
        >
          {{ "Password is too weak" | __t("input-error__password-is-too-weak") }}
        </input-error>
      </fieldset>

      <fieldset class="form-group" data-cy="login-fieldset">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">{{ "Confirm Password" | __t("input__password-confirm") }}</p>
          <base-password
            name="passwordConfirm"
            id="confirm-password"
            v-model="passwordConfirm"
            :disabled="isSending"
            placeholder="*******"
            @keyup.enter.native="triggerRegister"
            data-cy="text-input"
          />
        </div>

        <input-error
          v-if="!$v.passwordConfirm.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="field-error"
        />
        <input-error
          v-if="!$v.passwordConfirm.sameAsPassword"
          :error-code="'passwordNotMatch'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="field-error"
        />
      </fieldset>

      <div class="text-right mt-4">
        <b-button @click="triggerRegister" variant="success" :disabled="isSending" data-cy="submit-button" pill>
          {{ cta | __t(`button__${toSlug(cta)}`) }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { REGISTER } from "@/store/actions";

import { BForm, BButton } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import StrengthMeter from "vue-password-strength-meter";

import { getLanguageFromBrowser, toSlug } from "@/helpers/utils";
import { passwordMustScoreHigh } from "@/helpers/vuelidate";

import Errors from "@/components/Data/Errors";
import InputError from "@/components/Data/ErrorsInput";
import BaseInput from "@/components/Forms/Base/BaseInput";
import BasePassword from "@/components/Forms/Base/BasePassword";

export default {
  name: "Register",
  mixins: [validationMixin],
  components: { BForm, BaseInput, BButton, Errors, InputError, StrengthMeter, BasePassword },
  props: {
    cta: {
      type: String,
      default: "Join now",
    },
  },
  data() {
    return {
      firstName: "Author",
      lastName: "Author",
      email: "",
      password: "",
      passwordConfirm: null,
      passwordScore: null,

      utm: {
        id: null,
        source: null,
        medium: null,
        campaign: null,
        term: null,
        content: null,
        referral: null,
      },

      errorMessage: null,

      isShowingPassword: false,
      hasSubmitError: null,
      isSending: false,
    };
  },
  mounted() {
    const { utm_id, utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_referral, referral } = this.$route.query;

    if (utm_id) this.utm.id = utm_id;
    if (utm_source) this.utm.source = utm_source;
    if (utm_medium) this.utm.medium = utm_medium;
    if (utm_campaign) this.utm.campaign = utm_campaign;
    if (utm_term) this.utm.term = utm_term;
    if (utm_content) this.utm.content = utm_content;
    if (utm_referral || referral) this.utm.referral = utm_referral || referral;
  },
  methods: {
    ...mapActions("auth", { register: REGISTER }),
    async triggerRegister(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { language: forcedLanguage } = this.$route.query;
      const { firstName, lastName, email, password } = this;

      const language = forcedLanguage || getLanguageFromBrowser();

      const { isSuccess, error } = await this.register({ firstName, lastName, email, password, language });
      //TODO: update user's utm

      if (isSuccess) {
        this.$emit("success");
        this.isSending = false;
        return;
      }

      this.errorMessage = error;
      this.isSending = false;
    },

    updatePasswordScore(value) {
      this.passwordScore = value;
    },

    toSlug,
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      passwordMustScoreHigh,
    },
    passwordConfirm: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:map";
@import "@/assets/scss/_settings";
.control-buttons {
  .btn {
    width: auto;
    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;
    }
  }
}
.strength-meter {
  max-width: 100%;
  padding: 0 10px;
}
</style>
