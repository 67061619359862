<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <layout-desktop v-else-if="!isLoading && !isMobile" />
    <layout-mobile v-else-if="!isLoading && isMobile" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FETCH, LISTEN } from "@/store/actions";

import { ObjectToSortedArray } from "@/helpers/utils";

import Spinner from "@/components/Spinner/";

import LayoutDesktop from "./LayoutDesktop";
import LayoutMobile from "./LayoutMobile";

export default {
  name: "Projects",
  components: { Spinner, LayoutDesktop, LayoutMobile },
  computed: {
    ...mapGetters("projects", ["projectsSorted"]),
    projectId() {
      return this.$route.params.projectId;
    },
    routeName() {
      return this.$route.name;
    },

    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    isLoading: false,
  }),
  mounted() {
    this.init();
  },
  watch: {
    routeName(newValue) {
      if (newValue === "Projects") this.checkRoute();
    },
  },
  methods: {
    ...mapActions("projects", { fetchProjects: FETCH, listenProjects: LISTEN }),
    async init() {
      this.isLoading = true;

      const projects = await this.fetchProjects();
      const projectsSorted = ObjectToSortedArray(projects) || [];

      const { projectId } = this;

      if (!projectId && projectsSorted.length) {
        const [{ id: projectId }] = projectsSorted;
        this.$router.push({ name: "ProjectTaskWizard", params: { projectId } });
      } else if (!projectId) {
        this.$router.push({ name: "ProjectWizard" });
      }

      this.listenProjects();

      this.isLoading = false;
    },

    checkRoute() {
      const { projectId: newProjectId, name: routeName } = this;

      if (routeName !== "ProjectWizard" && !newProjectId) {
        const { projectsSorted } = this;

        if (projectsSorted.length) {
          const [{ id: projectId }] = projectsSorted;
          this.$router.push({ name: "ProjectTaskWizard", params: { projectId } });
        } else {
          this.$router.push({ name: "ProjectWizard" });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "@/assets/scss/_settings";

.app-container {
  height: 100%;
  overflow-y: scroll;
}

.top-section {
  background-color: #e2dfd7;
}

.limits__description {
  margin-top: 10px;
  .limits__description-label,
  .limits__description-max {
    text-transform: capitalize;
    font-family: "Quicksand";
    font-size: 14px;
    font-weight: 400;
    color: #b9bfd5;
  }
  .limits__description-value {
    font-size: 16px;
    font-family: "Quicksand";
    font-weight: 400;
    color: #7f00ff;
  }
}

// Use media queries if needed for specific styling on certain screen sizes
@media (max-width: map.get($grid-breakpoints, "sm")) {
  // styles for mobile screens go here
}

.title {
  font-weight: 600;
  font-size: 19px;
  margin-top: 5px;
  font-family: "Poppins";
}

.project-wrapper {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    height: calc(100vh - 100px);
  }
}
</style>
