export const PLANS = {
  FREE: "free",
  PRO: "pro",
  ULTRA: "ultra",
  ENTERPRISE: "enterprise",
};

export const PLANS_FREQUENCY = {
  PER_YEAR: "per-year",
  PER_MONTH: "per-month",
};

export const PLANS_FREQUENCY_TEXT = {
  [PLANS_FREQUENCY.PER_YEAR]: "/ year",
  [PLANS_FREQUENCY.PER_MONTH]: "/ month",
};

export const PLANS_PRICE = {
  NORMAL: "normal",
  SPECIAL: "special",
};

export const USAGE = {
  STORAGE: "storage",
};

export const USAGE_FREQUENCY = {
  ACTIVE: "active",
  PER_MONTH: "per-month",
};

export const PLANS_OPTIONS = [
  {
    level: 0,
    id: "free",
    name: "Free",
    class: "bg-light-seagreen",
    description: "Ideal to start and play around.",
    features: [
      { icon: "credits", text: "<strong>2000 credits</strong> per month" },
      { icon: "storage", text: "<strong>1 GB</strong> storage" },
    ],
    ["per-month"]: {
      ["normal"]: 0,
      ["special"]: 0,
    },
    ["per-year"]: {
      ["normal"]: 0,
      ["special"]: 0,
    },
  },
  {
    level: 1,
    id: "pro",
    name: "Pro",
    class: "bg-light-seagreen",
    description: "Ideal for solopreneurs.",
    features: [
      { icon: "credits", text: "<strong>30 000 credits</strong> per month" },
      { icon: "storage", text: "<strong>50 GB</strong> storage" },
    ],
    ["per-month"]: {
      ["normal"]: 29,
    },
    ["per-year"]: {
      ["normal"]: 290,
      ["normal-text"]: "Instead of <s>$US 468</s> per year.",
      ["special"]: 190,
      ["special-text"]: "Instead of <s>$US 468</s> per year.",
    },
  },
  {
    level: 2,
    id: "ultra",
    name: "Ultra",
    class: "bg-light-mutedrosered",
    description: "Ideal for small business.",
    features: [
      { icon: "credits", text: "<strong>70 000 credits</strong> per month" },
      { icon: "storage", text: "<strong>150 GB</strong> storage" },
    ],
    ["per-month"]: {
      ["normal"]: 59,
    },
    ["per-year"]: {
      ["normal"]: 590,
      ["normal-text"]: "Instead of <s>$US 948</s> per year.",
      ["special"]: 490,
      ["special-text"]: "Instead of <s>$US 690</s> per year.",
    },
  },
  {
    level: 3,
    id: "enterprise",
    name: "Enterprise",
    class: "bg-light-springgreen",
    description: "Ideal for bigger companies.",
    features: [
      { icon: "credits", text: "<strong>350 000 credits</strong> per month" },
      { icon: "storage", text: "<strong>450 GB</strong> storage" },
    ],
    ["per-month"]: {
      ["normal"]: 279,
    },
    ["per-year"]: {
      ["normal"]: 2790,
      ["normal-text"]: "Instead of <s>$US 3348</s> per year.",
      ["special"]: 2490,
      ["special-text"]: "Instead of <s>$US 3348</s> per year.",
    },
  },
];

export const PLANS_FREQUENCY_LIST = ["per-year", "per-month"];

export const PLANS_PRICE_LIST = ["normal", "special"];

export const TOPUP_LIST = ["wordsCreditsUsed", "words2CreditsUsed", "imagesSDCreditsUsed", "imagesHDCreditsUsed"];

export const DEFAULT_POPULAR_PLAN = "prolificWriter";

export const DEFAULT_FREQUENCY_PLAN = "per-year";

export const DEFAULT_PRICE_PLAN = "normal";

export const LIMITS_USERS = {
  free: {
    aiChat: 3,
    projects: 3,
    scansCreditsUsed: 0,
    wordsCreditsUsed: 2000,
    words2CreditsUsed: 2000,
    wordsResearchCreditsUsed: 0,
    imagesSDCreditsUsed: 5,
    imagesHDCreditsUsed: 3,
    batchsInProgress: 1,
    storage: 500 * 1024 * 1024, // 500MB
    storageFormats: [],
  },
  aspiring: {
    projects: 999999999999,
    scansCreditsUsed: 0,
    wordsCreditsUsed: 0,
    words2CreditsUsed: 35000,
    wordsResearchCreditsUsed: 70000,
    imagesSDCreditsUsed: 25,
    imagesHDCreditsUsed: 5,
    batchsInProgress: 1,
    storage: 500 * 1024 * 1024, // 500MB
    storageFormats: ["image", "docx"],
  },
  writer: {
    projects: 999999999999,
    scansCreditsUsed: 0,
    wordsCreditsUsed: 0,
    words2CreditsUsed: 90000,
    wordsResearchCreditsUsed: 180000,
    imagesSDCreditsUsed: 50,
    imagesHDCreditsUsed: 10,
    batchsInProgress: 1,
    storage: 1000 * 1024 * 1024, // 1GB
    storageFormats: ["image", "docx"],
  },
  prolificWriter: {
    projects: 999999999999,
    scansCreditsUsed: 10000,
    wordsCreditsUsed: 0,
    words2CreditsUsed: 200000,
    wordsResearchCreditsUsed: 400000,
    imagesSDCreditsUsed: 100,
    imagesHDCreditsUsed: 20,
    batchsInProgress: 1,
    storage: 10000 * 1024 * 1024, // 10GB
    storageFormats: ["image", "docx", "audio", "video"],
  },
  prolific: {
    projects: 999999999999,
    scansCreditsUsed: 10000,
    wordsCreditsUsed: 0,
    words2CreditsUsed: 200000,
    wordsResearchCreditsUsed: 400000,
    imagesSDCreditsUsed: 100,
    imagesHDCreditsUsed: 20,
    batchsInProgress: 1,
    storage: 10000 * 1024 * 1024, // 10GB
    storageFormats: ["image", "docx", "audio", "video"],
  },
  editor: {
    projects: 999999999999,
    scansCreditsUsed: 20000,
    wordsCreditsUsed: 0,
    words2CreditsUsed: 400000,
    wordsResearchCreditsUsed: 800000,
    imagesSDCreditsUsed: 200,
    imagesHDCreditsUsed: 40,
    batchsInProgress: 10,
    storage: 50000 * 1024 * 1024, // 50GB
    storageFormats: ["image", "docx", "audio", "video"],
  },
};

export const LIMITS_LABELS = {
  projects: "Projects",
  storage: "Storage",
  wordsCreditsUsed: "Magic Words Written With Quill I",
  words2CreditsUsed: "Magic Words Written With Quill II",
  scansCreditsUsed: "Magic Scans",
  imagesSDCreditsUsed: "Magic Images Created In SD (Small Definition)",
  imagesHDCreditsUsed: "Magic Images Created In HD (High Definition)",
};

export const LIMITS_TOPUP_LABELS = {
  wordsCreditsLeft: "Words Written With Quill I Available",
  words2CreditsLeft: "Words Written With Quill II Available",
  scansCreditsLeft: "Magic Scans",
  imagesSDCreditsLeft: "Magic Images Created In SD (Small Definition)",
  imagesHDCreditsLeft: "Magic Images Created In HD (High Definition)",
};

export const LIMITS_MONTHLY = [
  "wordsCreditsUsed",
  "words2CreditsUsed",
  "scansCreditsUsed",
  "imagesSDCreditsUsed",
  "imagesHDCreditsUsed",
  "tokensUsed",
  "tokensPrompts",
  "tokensCompletion",
];

export const BILLINGS_STEPS = {
  SELECTION: "selection",
  PAYMENT: "payment",
  CONFIRMATION: "confirmation",
};

export const HIGHEST_PLAN_LEVEL = 3; // Enterprise level
