function extractIdAndNames(content) {
  const regex = /<a.*?href=".*?\/(?<id>[^/"]+)".*?<span[^>]*>(?<name>[^<]+)/gs;
  const matches = content.matchAll(regex);
  const results = [];

  for (const match of matches) {
    if (match && match.groups) {
      const { id, name } = match.groups;
      results.push({ id: `${id}`, name: name.trim() });
    }
  }

  return results;
}

export const listReferences = (message) => {
  if (!message || !message.content) return [];
  if (!message.content.includes("document-link")) return [];

  const { content } = message;

  const result = extractIdAndNames(content);

  return result;
};

export const addDownloadImageButton = (message) => {
  if (!message || !message.content) return [];
  if (!message.content.includes("document-link")) return [];

  const { content } = message;

  const result = extractIdAndNames(content);

  return result;
};
