<template>
  <b-container id="app" class="m-0 p-0 vh-100 overflow-y-hidden-desktop" :class="classEnv" :style="styleEnv" style="" fluid>
    <router-view />

    <cookies-modal />

    <notifications-audio />
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import { INIT } from "@/store/actions";

import { BContainer } from "bootstrap-vue";

import CookiesModal from "@/components/Modals/Cookies";
import NotificationsAudio from "@/components/Notifications/Audio";

export default {
  name: "App",
  components: { BContainer, CookiesModal, NotificationsAudio },
  computed: {
    styleEnv() {
      const { NODE_ENV } = this;

      if (NODE_ENV === "stagging") return `background-color: #eddc97`;
      return null;
    },
    classEnv() {
      const { NODE_ENV } = this;
      return `env-${NODE_ENV}`;
    },
  },
  data: () => ({
    NODE_ENV: process.env.NODE_ENV,
  }),
  mounted() {
    this.initCheckout();
  },
  methods: {
    ...mapActions("checkout", { initCheckout: INIT }),
  },
};
</script>
