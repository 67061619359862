/* eslint-disable no-console */
import Vue from "vue";
import { sendFacebookEvent } from "@/common/functions";

import { createModuleResetter, getCookie } from "@/helpers/utils";
import { triggerEventFacebook } from "@/assets/trackers/facebookAds";
// import { triggerEventGoogleAds } from "@/assets/trackers/googleAds";

import { UPDATE_COOKIES, TRIGGER_EVENT } from "../../actions";
import { SET_KEY, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  necessary: true,
  performance: false,
  functions: false,
  ads: false
});

const getters = {};

const mutations = {
  [SET_KEY](state, { key, value }) {
    Vue.set(state, key, value);
  },

  [RESET_STORE]: createModuleResetter(getInitState)
};

const actions = {
  [UPDATE_COOKIES]({ commit }, { cookies }) {
    cookies.forEach(({ key, status: value }) =>
      commit(SET_KEY, { key, value })
    );
  },
  [TRIGGER_EVENT]({ state }, { event, ...payload }) {
    const { ads } = state;
    const fbp = getCookie("_fbp");
    const fbc = getCookie("_fbc");

    switch (event) {
      case "lead":
        if (ads) triggerEventFacebook({ event: "Lead" });
        // if (ads) triggerEventGoogleAds({ event: "Lead" });
        sendFacebookEvent({ eventName: "Lead", fbp, fbc });
        break;

      case "checkout": {
        const { currency, value, amount, vueInstance } = payload;
        if (ads)
          triggerEventFacebook({
            event: "InitiateCheckout",
            currency,
            value,
            amount
          });
        // if (ads) triggerEventGoogleAds({ event: "InitiateCheckout" });
        sendFacebookEvent({
          eventName: "InitiateCheckout",
          currency,
          value,
          amount,
          fbp,
          fbc
        });

        if (vueInstance && vueInstance.$gtag) {
            vueInstance.$gtag.event("checkout", {
              event_category: "Conversion",
              event_label: `${amount} ${currency}`,
              value: amount
            });
        } else {
            console.error("Vue instance or $gtag not available");
        }

        break;
      }

      case "purchase": {
        const { currency, value, amount, vueInstance } = payload;
        if (ads)
          triggerEventFacebook({ event: "Purchase", currency, value, amount });
        sendFacebookEvent({
          eventName: "Purchase",
          currency,
          value,
          amount,
          fbp,
          fbc
        });
        // if (ads) triggerEventGoogleAds({ event: "Purchase", currency, value });

        if (vueInstance && vueInstance.$gtag) {
          vueInstance.$gtag.event("purchase", {
            event_category: "Conversion",
            event_label: `${amount} ${currency}`,
            value: amount
          });
        } else {
          console.error("Vue instance or $gtag not available");
        }
        
        break;
      }

      case "landing_step": {
        const { step, vueInstance } = payload;
        let event_label = `Step ${step}`
        if (step === 0) event_label = 'init'
        if (vueInstance && vueInstance.$gtag) {
          vueInstance.$gtag.event("landing_step", {
            event_category: "Stepper",
            event_label,
            value: step
          });
        } else {
          console.error("Vue instance or $gtag not available");
        }
        break;
      }
        
      case "landing_cover": {
        const { step, vueInstance } = payload;
        let event_label = `Step ${step}`
        if (step === 0) event_label = 'init'
        if (vueInstance && vueInstance.$gtag) {
          vueInstance.$gtag.event("landing_cover", {
            event_category: "Stepper",
            event_label,
            value: step
          });
        } else {
          console.error("Vue instance or $gtag not available");
        }
        break;
      }

      case "pageview": {
        if (ads) triggerEventFacebook({ event: "PageView" });
        sendFacebookEvent({ eventName: "PageView", fbp, fbc });
      }
    }
  }
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters
};
