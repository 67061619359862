<template>
  <div>
    <h3 class="mt-50 mb-50 text-center text-uppercase text-white">{{ "Login Now" | __t("auth__login-now") }}</h3>

    <b-button
      @click="$emit('continue-with-email-magiclink')"
      data-cy="link-register-magiclink"
      variant="light"
      class="w-100"
      :disabled="isLoading"
      pill
    >
      <b-icon-envelope-fill /> {{ "Continue With Email And Magic Link" | __t("auth__continue-with-email-and-magiclink") }}
    </b-button>

    <b-button
      @click="$emit('continue-with-email-password')"
      data-cy="link-register-password"
      variant="light"
      class="w-100 mt-3"
      :disabled="isLoading"
      pill
    >
      <b-icon-envelope-fill /> {{ "Continue With Email And Password" | __t("auth__continue-with-email-and-password") }}
    </b-button>

    <!--  <b-button
      @click="$emit('continue-with-google')"
      data-cy="link-register-google"
      variant="light"
      class="w-100 mt-3"
      :disabled="isLoading"
      pill
    >
      <b-spinner v-if="isLoading" style="width:20px; height: 20px"></b-spinner>
      <span v-else> <b-icon-google /> {{ "Continue With Google" | __t("auth__continue-with-google") }} </span>
    </b-button> -->

    <p class="text-center mt-50 mb-50">
      {{ "Don't have an account yet?" | __t("auth__don-t-have-an-account-yet") }}<br />

      <router-link :to="{ name: 'Register' }">{{ "Register" | __t("auth__headline__register") }}</router-link
      >.
    </p>

    <p class="text-center">
      <em>
        <small>
          {{ "By clicking one of the 'Continue' button, you agree to the" | __t("auth__conditions__1-by-clicking-you-agree") }}
          <router-link :to="{ name: 'TermsOfService' }" target="_blank" class="small">
            {{ "Terms and Conditions" | __t("auth__conditions__2-terms-and-conditions") }}
          </router-link>
          {{ "and" | __t("auth__conditions__3-and") }}
          <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank" class="small">
            {{ "Privacy Statement" | __t("auth__conditions__4-privacy-statement") }} </router-link
          >.
        </small>
      </em>
    </p>
  </div>
</template>

<script>
import { BButton, /* BSpinner, */ BIconEnvelopeFill /* BIconGoogle */ } from "bootstrap-vue";

export default {
  name: "LoginSelect",
  components: { BButton, /* BSpinner, */ BIconEnvelopeFill /* BIconGoogle */ },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
