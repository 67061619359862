<template>
  <b-container id="app" class="m-0 p-0 h-100" fluid>
    <h1>Maintenance</h1>
    <p>We've got an unexpected error. We'll be back very soon.</p>
  </b-container>
</template>

<script>
import { BContainer } from "bootstrap-vue";

export default {
  name: "Maintenance",
  components: { BContainer },
};
</script>
