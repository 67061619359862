import Vue from "vue";
import {
  updateProfile,
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  signOut,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import { get, ref, onValue } from "firebase/database";

import { authentication, database } from "@/common/firebase";
import { removeUser, sendMagicLinkEmail, sendWelcomeEmail } from "@/common/functions";

import { putTokenToStorage } from "@/helpers/auth";
import { decodeFromFirebaseKey, getLanguageFromBrowser, createModuleResetter, generatePassword } from "@/helpers/utils";
import { resetStoreModules } from "@/helpers/storeResetter";
import { getCurrentMonth } from "@/helpers/date";

import {
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  REGISTER,
  FETCH,
  GET,
  UPDATE,
  REMOVE,
  TRIGGER_EVENT,
  SEND_LOGIN_LINK,
  LOGIN_BY_LINK,
  LOGIN_BY_GOOGLE,
  AUTH_METHODS,
} from "../actions";
import { SET_AUTH, SET_PERMISSIONS, SET_KEY, SET_PROJECT, PURGE_AUTH, RESET_STORE } from "../mutations";

import { PLANS, PLANS_OPTIONS, LIMITS_USERS } from "@/constants/users";
import { EMAIL_FOR_AUTH } from "@/constants/local-storage";

const getInitState = () => ({
  uid: null,
  user: {},

  // user data from the firebase db, not mixed with the firebase auth object.
  // we need it to understand when the user object was fetched from the db
  userData: null,

  access: {},
  permissions: {},

  isAuthenticated: false,
  isAuthorized: false,
});

// TODO: Check how to handle isAuthorized and permissions
const getters = {
  fetchPlansOptions(_, getters) {
    const { pathname } = window.location;

    const isAuthenticated = getters.isAuthenticated;

    if (pathname === "/m/special-offer") return PLANS_OPTIONS;
    if (!isAuthenticated) return PLANS_OPTIONS;
    return PLANS_OPTIONS;
  },
  uid(state) {
    return state.user.uid;
  },
  language(state) {
    let { language = "en" } = state.user?.private || {};
    return language;
  },
  languageFamily(state) {
    let { language = "en" } = state.user?.private || {};

    if (language.includes("-")) [language] = language.split("-");
    return language;
  },
  projectId(state) {
    return state.projectId;
  },

  billing(state) {
    return state.user?.billing;
  },
  billingCurrentMonth(_, getters) {
    const { billing } = getters;

    return billing?.monthly?.[getCurrentMonth()];
  },
  subscriptions(state) {
    return state.user?.billing?.subscriptions;
  },
  plan(state) {
    const { plan } = state.user?.billing || {};
    return plan;
  },
  planLevel(state, getters) {
    const { plan } = state.user?.billing || {};
    const plans = getters.fetchPlansOptions;

    const { level = 0 } = plans.find((p) => p.id === plan) || {};
    return level;
  },
  planLimits(_, getters) {
    const { plan } = getters;

    const plans = LIMITS_USERS;

    return plans[plan];
  },

  isAdmin(state) {
    return state.user.billing?.isAdmin || false;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isAuthorized(state) {
    return state.isAuthorized;
  },
  isClient(state) {
    const { plan = PLANS.FREE } = state.user?.billing || {};
    return plan !== PLANS.FREE;
  },
  hasPermission(state) {
    return (section, action) => state.permissions[section][action];
  },

  isFetchingUser(state) {
    return !Object.keys(state.userData || {}).length;
  },
  // Users registered less than 96 hours ago
  isRecentlyJoined(state) {
    return (new Date() - new Date(Number(state.user?.public?.createdAt || 0))) / 1000 / 60 / 60 <= 96;
  },
  isFreeUser(state) {
    return state?.user?.billing?.plan === PLANS?.FREE;
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    for (const key in user) Vue.set(state.user, key, user[key]);
  },
  [SET_PERMISSIONS](state, permissions) {
    for (const key in permissions) Vue.set(state.permissions, key, permissions[key]);
  },

  [SET_KEY](state, { key, value }) {
    Vue.set(state, key, value);
  },
  [SET_PROJECT](state, { id, ...data }) {
    Vue.set(state.projects, id, { ...data });
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.permissions = {};
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [SEND_LOGIN_LINK](_, { email, query = "", bookName = "", forcedLanguage }) {
    try {
      const language = forcedLanguage || getLanguageFromBrowser();

      const authMethods = await fetchSignInMethodsForEmail(authentication, email);
      const isNewUser = authMethods?.length == 0;

      await sendMagicLinkEmail({
        email,
        language,
        isNewUser,
        bookName,
        url: `/login-by-link?${query}`,
      });

      window.localStorage.setItem(EMAIL_FOR_AUTH, email);

      return { isSuccess: true };
    } catch (e) {
      console.log(e);
      return { isSuccess: false, error: e };
    }
  },
  async [LOGIN_BY_LINK]({ getters, commit, dispatch }, { url = null, forcedLanguage = "" }) {
    try {
      if (isSignInWithEmailLink(authentication, url || window.location.href)) {
        const urlParams = new URLSearchParams(window.location.search);
        console.warn("Getting e-mail from params", urlParams);
        const emailParam = urlParams.get("email");
        let email = decodeFromFirebaseKey(emailParam);

        console.log("email", email);

        if (!email) {
          console.warn("User email not found");
          email = prompt("Please enter your Email:");
        }

        const authMethods = await fetchSignInMethodsForEmail(authentication, email);

        console.log("authMethods", authMethods);
        const isNewUser = authMethods?.length == 0;

        const credentials = await signInWithEmailLink(authentication, email, window.location.href);
        console.log("credentials", credentials);

        const { user } = credentials;
        const token = await user.getIdToken(true);
        console.log("user", user);
        console.log("token", token);
        console.log("isNewUser", isNewUser);

        if (isNewUser) {
          dispatch(`trackings/${TRIGGER_EVENT}`, { event: "lead" }, { root: true });
          await sendWelcomeEmail({ email: user.email });

          const language = forcedLanguage || getLanguageFromBrowser();
          await dispatch(`userPrivate/${UPDATE}`, { language }, { root: true });
        }

        putTokenToStorage(token);
        commit(SET_AUTH, user);

        console.log("Getting user...");
        dispatch(GET);

        //wait until the user is created and/or received completely.
        let i = 0;
        while (getters.isFetchingUser) {
          await new Promise((r) => setTimeout(() => r(), 1000));
          i++;
          if (i > 40) {
            return {
              isSuccess: false,
              error: "Fail to fetch user",
            };
          }
        }

        return { isSuccess: true };
      } else {
        return { isSuccess: false, error: "Failed to login via link" };
      }
    } catch (e) {
      console.log(e);
      return { isSuccess: false, error: e };
    }
  },

  // TODO: onAuthStateChanged triggers too early now, before user was created. Needs to be refactored
  async [LOGIN_BY_GOOGLE]({ commit, dispatch }, payload) {
    try {
      const language = payload?.forcedLanguage || getLanguageFromBrowser();
      authentication.languageCode = language;

      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(authentication, provider);
      const { isNewUser } = getAdditionalUserInfo(result);
      const user = result.user;

      const credential = await GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      if (isNewUser) {
        await dispatch(`userPrivate/${UPDATE}`, { language }, { root: true });

        await sendWelcomeEmail({ email: user.email });

        dispatch(`trackings/${TRIGGER_EVENT}`, { event: "lead" }, { root: true });
      }

      putTokenToStorage(token);
      commit(SET_AUTH, user);

      dispatch(GET);

      return { isSuccess: true };
    } catch (e) {
      console.log(e);
      return { isSuccess: false, error: e };
    }
  },
  async [LOGIN]({ commit, dispatch }, { email, password }) {
    try {
      const { user } = await signInWithEmailAndPassword(authentication, email, password);

      const token = await user.getIdToken(true);
      putTokenToStorage(token);

      dispatch(GET);

      commit(SET_AUTH, user);

      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error, errorCode: error?.code };
    }
  },
  async [LOGOUT]({ rootState, commit }) {
    try {
      signOut(authentication);

      commit(PURGE_AUTH);
      commit(RESET_STORE);

      resetStoreModules(rootState);
    } catch (error) {
      console.log(error?.code);
      return { isSuccess: false, error };
    }
  },
  async [RESET_PASSWORD](_, { email }) {
    try {
      await sendPasswordResetEmail(authentication, email);
      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [REGISTER]({ commit, dispatch }, { firstName, lastName, email, password, language }) {
    try {
      if (!password) password = generatePassword(8);

      const { user } = await createUserWithEmailAndPassword(authentication, email, password);

      await updateProfile(user, { displayName: firstName, emailVerified: false, disabled: false });
      const token = await user.getIdToken(true);
      putTokenToStorage(token);

      commit(SET_AUTH, { ...user });
      commit(SET_KEY, { key: "isNewUser", value: true });

      await dispatch(
        `userPrivate/${UPDATE}`,
        { firstName, lastName, language: language || getLanguageFromBrowser() },
        { root: true }
      );

      dispatch(`trackings/${TRIGGER_EVENT}`, { event: "lead" }, { root: true });

      // const { uid } = user;

      await dispatch(GET);
      await sendWelcomeEmail({ email });

      return { isSuccess: true };
    } catch (error) {
      console.log("error", error);
      console.log("errorCode", error.code);
      console.log("errorMessage", error.message);
      return { isSuccess: false, error };
    }
  },
  async [AUTH_METHODS](_, email) {
    return await fetchSignInMethodsForEmail(authentication, email);
  },

  // User logic
  async [GET]({ state, commit, dispatch }) {
    const { uid } = state.user;

    console.log(`users/${uid}`);

    try {
      const userRef = ref(database, `users/${uid}`);

      const snapshot = await get(userRef);
      commit(SET_AUTH, { ...snapshot.val() });
      commit(SET_KEY, { key: "userData", value: snapshot.val() });
      dispatch(`projects/${FETCH}`, {}, { root: true });

      // Listen to the user
      onValue(userRef, (snapshot) => {
        commit(SET_AUTH, snapshot.val());
        commit(SET_KEY, { key: "userData", value: snapshot.val() });
      });

      return { ...snapshot.val() };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, error };
    }
  },
  async [UPDATE]() {
    // TODO: Handle that update logic
    /* await api.put(`authentication`, payload);

    const { config, notifications } = payload;

    if (config) commit(SET_AUTH_CONFIG, { ...config });
    if (notifications) commit(SET_AUTH_NOTIFICATIONS, { ...notifications }); */
  },
  async [REMOVE]({ commit, rootState }) {
    await removeUser();

    commit(RESET_STORE);
    resetStoreModules(rootState);

    signOut(authentication);
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
