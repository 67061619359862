import { TEAMMATES } from "./teammates";

export const TEMPLATES = {
  TRANSCRIPTION: "transcription",
  PRODUCT: "product",
  COMPANY: "company",
  PERSONA: "persona",
  BLOG_POST: "blogPost",
  FACEBOOK_POST: "facebookPost",
  LINKEDIN_POST: "linkedinPost",
  MARKETING_EMAIL: "marketingEmail",
  KNOWLEDGE_BASE_ARTICLE: "Knowledge Base Articles",
  FAQ: "FAQ Documents",
  DOCUMENTATION: "Product Manuals and Documentation",
  WIKI: "Internal Wiki Pages",
  TRAINING_MATERIAL: "Training Materials",
  TROUBLESHOOTING: "Troubleshooting Guides",
  TECHNICAL_BULLETIN: "Technical Bulletins and Updates",
  POLICY_AND_PROCEDURE: "Policy and Procedure Manuals",
  RESPONSE_SCRIPT: "Scripted Responses",
  NONE: "none",
};

export const TEMPLATES_OPTIONS = [
  { value: TEMPLATES.BLOG_POST, text: "Blog Post" },
  { value: TEMPLATES.FACEBOOK_POST, text: "Facebook Post" },
  { value: TEMPLATES.LINKEDIN_POST, text: "Linkedin Post" },
  { value: TEMPLATES.MARKETING_EMAIL, text: "Marketing Email" },
  { value: TEMPLATES.NONE, text: "Blank" },
];

export const TEMPLATES_SUPPORT_OPTIONS = [
  { value: TEMPLATES.KNOWLEDGE_BASE_ARTICLE, text: "Knowledge Base Articles" },
  { value: TEMPLATES.FAQ, text: "FAQ Documents" },
  { value: TEMPLATES.DOCUMENTATION, text: "Product Manuals and Documentation" },
  { value: TEMPLATES.WIKI, text: "Internal Wiki Pages" },
  { value: TEMPLATES.TRAINING_MATERIAL, text: "Training Materials" },
  { value: TEMPLATES.TROUBLESHOOTING, text: "Troubleshooting Guides" },
  { value: TEMPLATES.TECHNICAL_BULLETIN, text: "Technical Bulletins and Updates" },
  { value: TEMPLATES.POLICY_AND_PROCEDURE, text: "Policy and Procedure Manuals" },
  { value: TEMPLATES.RESPONSE_SCRIPT, text: "Scripted Responses" },
  { value: TEMPLATES.NONE, text: "Blank" },
];

export const DOCUMENTS_TYPES = {
  FOLDER: "folder",
  FILE: "file",
};

export const BLOCKS = {
  SUBTITLES: "subtitles",
  CONTENT: "content",
  CONTENT_PERSONA: "contentPersona",
  CONTENT_RULES_WRITING: "contentRulesWriting",
  CONTENT_EXAMPLES: "contentExamples",
};

export const STRUCTURES_OPTIONS = {
  [TEMPLATES.BLOG_POST]: [
    { value: "how-to", text: "How-to Guide" },
    { value: "listicle", text: "Listicle" },
    { value: "news-update", text: "News Update" },
    { value: "opinion", text: "Opinion Piece" },
    { value: "case-study", text: "Case Study" },
    { value: "tutorial", text: "Tutorial" },
    { value: "comparison", text: "Comparison Post" },
    { value: "FAQ", text: "FAQ" },
    { value: "checklist", text: "Checklist" },
    { value: "other", text: "Other" },
    { value: null, text: "None" },
  ],
};

export const DOCUMENTS_OPTIONS_COMMAND = [
  {
    id: TEMPLATES.NONE,
    name: "Blank",
    description: "Start with a blank canvas for any document",
    icon: "",
    category: "General",
  },
  // {
  //   id: TEMPLATES.COMPANY,
  //   name: "Company",
  //   description: "Create a new company profile",
  //   icon: "",
  //   category: "Profile",
  // },
  {
    id: TEMPLATES.PRODUCT,
    name: "Product",
    description: "Draft a new product description",
    icon: "",
    category: "Catalog",
  },
  // {
  //   id: TEMPLATES.PERSONA,
  //   name: "Persona",
  //   description: "Create a new persona profile",
  //   icon: "",
  //   category: "Profile",
  // },
  {
    id: TEMPLATES.BLOG_POST,
    name: "Blog Post",
    description: "Create a new blog post",
    icon: "",
    category: "Write",
  },
  {
    id: TEMPLATES.FACEBOOK_POST,
    name: "Facebook Post",
    description: "Create a new Facebook post",
    icon: "",
    category: "Write",
  },
  {
    id: TEMPLATES.LINKEDIN_POST,
    name: "LinkedIn Post",
    description: "Create a new LinkedIn post",
    icon: "",
    category: "Write",
  },
  {
    id: TEMPLATES.MARKETING_EMAIL,
    name: "Marketing Email",
    description: "Create a new marketing e-mail",
    icon: "",
    category: "Write",
  },

  // Support
  {
    id: TEMPLATES.KNOWLEDGE_BASE_ARTICLE,
    name: "Knowledge Base Articles",
    description: "Craft an informative articles for your knowledge base",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.FAQ,
    name: "FAQ Documents",
    description: "Compile frequently asked questions and their answers",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.DOCUMENTATION,
    name: "Product Manuals and Documentation",
    description: "Create detailed manuals and documentation to guide users through your product's features and functionalities",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.WIKI,
    name: "Internal Wiki Pages",
    description: "Develop internal wiki pages to centralize company knowledge and resources for team reference",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.TRAINING_MATERIAL,
    name: "Training Materials",
    description:
      "Design comprehensive training materials to educate employees or customers about your products, services, or policies",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.TROUBLESHOOTING,
    name: "Troubleshooting Guides",
    description: "Prepare step-by-step guides to help users diagnose and solve problems with your products or services",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.TECHNICAL_BULLETIN,
    name: "Technical Bulletins and Updates",
    description:
      "Issue technical bulletins and updates to inform users about the latest technical developments, fixes, and improvements",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.POLICY_AND_PROCEDURE,
    name: "Policy and Procedure Manuals",
    description:
      "Write clear and detailed manuals outlining the policies and procedures governing how things are done within your organization",
    icon: "",
    category: "Support",
  },
  {
    id: TEMPLATES.RESPONSE_SCRIPT,
    name: "Scripted Responses",
    description:
      "Create scripted responses for customer support to ensure consistent and efficient handling of common queries and issues",
    icon: "",
    category: "Support",
  },
];

export const WIZARD_DOCUMENT = {
  [TEAMMATES.COPYWRITER]: {
    stateMachine: {
      id: "wizard-url",
      initialStep: "step-url",
      context: {
        currentStep: "step-url",
        formData: {
          templateId: "blogPost",
          name: "",
          content: "",
        },
      },
      steps: {
        "step-url": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "templateId",
                validators: ["required"],
                label: "Template",
                attributes: {
                  options: [...TEMPLATES_OPTIONS],
                },
              },
              {
                componentName: "Input",
                name: "name",
                validators: ["required"],
                label: "Name",
                attributes: {
                  placeholder: "Insert the name of your asset...",
                },
              },
              {
                componentName: "Message",
                name: "content",
                validators: ["required"],
                label: "Content",
                attributes: {
                  placeholder: "Insert the content of your asset...",
                },
              },
            ],
          },
        },
      },
    },
  },
  [TEAMMATES.SUPPORT]: {
    stateMachine: {
      id: "wizard-url",
      initialStep: "step-url",
      context: {
        currentStep: "step-url",
        formData: {
          templateId: "blogPost",
          name: "",
          content: "",
        },
      },
      steps: {
        "step-url": {
          NEXT: {},
          BACK: {},
          options: {
            fields: [
              {
                componentName: "Select",
                name: "templateId",
                validators: ["required"],
                label: "Template",
                attributes: {
                  options: [...TEMPLATES_SUPPORT_OPTIONS],
                },
              },
              {
                componentName: "Input",
                name: "name",
                validators: ["required"],
                label: "Name",
                attributes: {
                  placeholder: "Insert the name of your asset...",
                },
              },
              {
                componentName: "Message",
                name: "content",
                validators: ["required"],
                label: "Content",
                attributes: {
                  placeholder: "Insert the content of your asset...",
                },
              },
            ],
          },
        },
      },
    },
  },
};
