<template>
  <div class="vh-100 overflow-y-scroll">
    <p class="text-center">{{ "You are now logged out." | __t("auth__you-are-now-logged-out") }}</p>

    <layout-spacer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGOUT } from "@/store/actions";

import LayoutSpacer from "@/components/Layouts/Spacer";

export default {
  name: "Logout",
  components: { LayoutSpacer },
  beforeRouteEnter(_, __, next) {
    next((vm) => {
      if (!vm.isAuthenticated) next({ name: "Login" });
    });
  },
  async mounted() {
    await this.logOut();
  },
  methods: {
    ...mapActions("auth", { logOut: LOGOUT }),
  },
};
</script>
