<template>
  <div class="base-password d-flex w-100" :class="{ is_disabled: disabled }">
    <b-form-input
      :value="value"
      :name="name"
      :type="showPassword ? 'text' : 'password'"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :disabled="disabled"
      :id="id"
      class="flex-grow-1"
    ></b-form-input>

    <span v-if="counter" class="counter" :class="{ is_success: value.length >= minLength }">{{ value.length }}</span>

    <b-button @click="triggerShowPassword" variant="transparent" class="float-right rounded-full">
      <b-icon-eye v-if="showPassword" variant="primary"></b-icon-eye>
      <b-icon-eye-slash v-else variant="primary"></b-icon-eye-slash>
    </b-button>
  </div>
</template>

<script>
import { BFormInput, BButton, BIconEye, BIconEyeSlash } from "bootstrap-vue";

export default {
  name: "BasePassword",
  components: { BFormInput, BButton, BIconEye, BIconEyeSlash },
  props: {
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    // uses to change couner color after min length reached
    minLength: {
      type: Number,
      default: 6,
    },
    counter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    triggerShowPassword() {
      if (this.disabled) return;
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_settings";
.base-password {
  position: relative;

  .counter {
    opacity: 0.8;
    font-size: 0.9em;
    pointer-events: none;
    display: block;
    padding: 0 4px;
    min-width: 23px;
    text-align: center;
    border-radius: 5px;
    background-color: $danger;
    font-weight: bolder;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3.2em;
    &.is_success {
      background-color: $success;
    }
  }

  input {
    background-color: transparent !important;
    color: white;
    border: 0px !important;
    font-size: 1.3rem;
  }

  input:focus {
    background-color: transparent;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  button {
    border-radius: 0 25px 25px 0 !important;
  }

  &.is_disabled {
    input,
    button {
      cursor: not-allowed;
      color: inherit !important;
    }
  }
}

.base-password {
  background-color: transparent;
  color: $black-lighter;
  border: 0px;

  &.is_disabled {
    color: $gray-darker;
  }
}
</style>
