export const SOURCES = {
  CUSTOMIZE: "customize",
  MODE_FOCUS: "modeFocus",
};

export const SELECTIONS = {
  DARK_MODE: "dark-mode",
  FONT_FAMILY: "font-family",
  FONT_SIZE: "font-size",
  KEYBOARD_SOUND: "keyboard-sound",
};

export const SELECTIONS_VALUES = {
  [SELECTIONS.DARK_MODE]: {
    name: "Dark Mode",
    options: [
      { text: "Light", value: "light" },
      { text: "Dark", value: "dark" },
    ],
  },
  [SELECTIONS.FONT_FAMILY]: {
    name: "Font Family",
    options: [
      { text: "T<sub>1</sub>", value: "libre-baskerville" },
      { text: "T<sub>2</sub>", value: "special-elite" },
      { text: "T<sub>3</sub>", value: "delius" },
      { text: "T<sub>4</sub>", value: "quicksand" },
      { text: "T<sub>5</sub>", value: "merriweather" },
    ],
  },
  [SELECTIONS.FONT_SIZE]: {
    name: "Font Size",
    options: [
      { text: "S<sub>1</sub>", value: "12" },
      { text: "S<sub>2</sub>", value: "16" },
      { text: "S<sub>3</sub>", value: "21" },
      { text: "S<sub>4</sub>", value: "25" },
      { text: "S<sub>5</sub>", value: "30" },
      { text: "S<sub>6</sub>", value: "45" },
    ],
  },
  [SELECTIONS.KEYBOARD_SOUND]: {
    name: "Keyboard Sound",
    options: [
      { text: "None", value: null },
      { text: "Typewriter<sub>1</sub>", value: "typewriter-1" },
      /* { text: "Modern<sub>1</sub>", value: "modern-1" }, */
      { text: "Harp<sub>1</sub>", value: "harp-1" },
    ],
  },
};

export const SELECTIONS_SAVED = [SELECTIONS.DARK_MODE];

export const SELECTIONS_DEFAULT = {
  [SELECTIONS.DARK_MODE]: SELECTIONS_VALUES[SELECTIONS.DARK_MODE].options[0].value,
  [SELECTIONS.FONT_FAMILY]: SELECTIONS_VALUES[SELECTIONS.FONT_FAMILY].options[0].value,
  [SELECTIONS.FONT_SIZE]: SELECTIONS_VALUES[SELECTIONS.FONT_SIZE].options[1].value,
  [SELECTIONS.KEYBOARD_SOUND]: null,
};

export const KEYBOARD_SOUND = {
  "typewriter-1": "https://cdn-private.purplewave.ai/-NVn6SuXy5tsFRmEiH2W/f/-NWmOzTgoI595X2uEtRU.mp3/original",
  "harp-1": "https://cdn-private.purplewave.ai/-NVn6SuXy5tsFRmEiH2W/f/-NWmOitULdKKhCYelCzV.mp3/original",
};

export const SOURCES_LIST = [SOURCES.CUSTOMIZE, SOURCES.MODE_FOCUS];
