<template>
  <div @click="$emit('go-to')">
    <div
      @mouseenter="isHovering = true"
      @mouseleave="isHovering = false"
      :class="{ 'is-active': isActive }"
      class="projectbar__item pr-2 pl-2 rounded-lg cursor-pointer"
    >
      <span v-if="emoji" class="mt-2 mr-3" style="width: 15px; height: 15px">{{ emoji }}</span>
      <img
        v-if="!emoji && icon && isHovering"
        :src="require(`@/assets/icons/navigation/${icon}-active.svg`)"
        @click="triggerCheckTask"
        width="12"
        height="12"
        class="float-left mt-2 mr-3 check-icon"
      />
      <img
        v-else-if="!emoji && icon && !isHovering"
        :src="require(`@/assets/icons/navigation/${icon}.svg`)"
        width="12"
        height="12"
        class="float-left mt-2 mr-3"
      />
      <img
        v-else-if="!emoji && !icon"
        src="@/assets/icons/navigation/check.svg"
        width="12"
        height="12"
        class="float-left mt-2 mr-3"
        style="visibility: hidden"
      />

      <span class="projectbar__item-name text-capitalize overflow-hidden">{{ stripHtmlTags(name) }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CHECK } from "@/store/actions";

import { gsap } from "gsap";

import { createFireworkSparks } from "@/helpers/animations";

import { stripHtmlTags } from "@/helpers/strings";
import { sleep } from "@/helpers/utils";

export default {
  name: "Item",
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: String,
      default: null,
    },
    taskId: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: "",
    },
    emoji: {
      type: String,
      default: "",
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHovering: false,
  }),
  methods: {
    ...mapActions("projectsTasks", { checkTask: CHECK }),
    async triggerCheckTask(event) {
      const { projectId, taskId } = this;
      createFireworkSparks(event);

      gsap.to(this.$el, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.checkTask({ projectId, taskId });
        },
      });

      await sleep(500);
      this.$router.push({ name: "ProjectTaskWizard", params: { projectId } });
    },

    stripHtmlTags,
  },
};
</script>

<style lang="scss" scoped>
.projectbar__item {
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  width: 90%;

  span {
    color: #9b9b9b;
  }
}

.projectbar__item.is-active,
.projectbar__item:hover {
  background-color: #151718;

  span {
    color: #ffffff;
  }
}

.projectbar__item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: calc(100% - 30px);
}

.check-icon:hover {
  transform: scale(1.3);
  transition: all 0.1s ease-in-out;
}
</style>
