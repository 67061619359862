<template>
  <div class="h-100">
    <router-view class="h-100" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET, LISTEN } from "@/store/actions";

export default {
  name: "Project",
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  mounted() {
    const { projectId } = this;

    this.getSettings({ projectId });
    this.listenSettings({ projectId });
  },
  methods: {
    ...mapActions("projectsSettings", { getSettings: GET, listenSettings: LISTEN }),
  },
};
</script>

<style></style>
