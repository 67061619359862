import { ref, update } from "firebase/database";
import { updatePassword, updateEmail } from "firebase/auth";

import { authentication, database } from "@/common/firebase";

import { UPDATE, UPDATE_PASSWORD, UPDATE_EMAIL } from "../../actions";
import { SET_AUTH } from "../../mutations";

const getInitState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  async [UPDATE]({ rootGetters }, values = {}) {
    try {
      const uid = rootGetters["auth/uid"];
      const updates = {};

      if (Object.prototype.hasOwnProperty.call(values, "firstName")) updates[`users/${uid}/private/firstName`] = values.firstName;
      if (Object.prototype.hasOwnProperty.call(values, "lastName")) updates[`users/${uid}/private/lastName`] = values.lastName;
      if (Object.prototype.hasOwnProperty.call(values, "language")) updates[`users/${uid}/private/language`] = values.language;

      await update(ref(database), updates);
      return { isSuccess: true };
    } catch (e) {
      return { isSuccess: false, error: e?.message };
    }
  },
  async [UPDATE_PASSWORD](_, { newPassword }) {
    try {
      await updatePassword(authentication.currentUser, newPassword);
      return { isSuccess: true };
    } catch (e) {
      console.log(e, e?.code, e?.message);
      return { isSuccess: false, errorCode: e?.code, error: e?.message };
    }
  },
  async [UPDATE_EMAIL]({ commit }, { email }) {
    try {
      await updateEmail(authentication.currentUser, email);
      commit("auth/" + SET_AUTH, authentication.currentUser, { root: true });

      return { isSuccess: true };
    } catch (e) {
      console.log(e, e?.code, e?.message);
      return { isSuccess: false, errorCode: e?.code, error: e?.message };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
