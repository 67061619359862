export const FOCUS = {
  BACKGROUND: "focus_background",
  ANIMATION: "focus_animation",
  SOUND: "focus_sound",
  MUSIC: "focus_music",
};

export const FOCUS_VALUES = {
  [FOCUS.BACKGROUND]: {
    name: "Background",
    options: [
      { text: "Off", value: null },
      { text: "Foggy Forest<sub>1</sub>", value: "foggy-forest-1" },
      { text: "Snowy Forest<sub>1</sub>", value: "snowy-forest-1" },
      { text: "Night Sky<sub>1</sub>", value: "night-sky-1" },
      { text: "Rainy Sea<sub>1</sub>", value: "rainy-sea-1" },
    ],
  },
  [FOCUS.ANIMATION]: {
    name: "Animation",
    options: [
      { text: "Off", value: false },
      { text: "On", value: true },
    ],
  },
  [FOCUS.SOUND]: {
    name: "Sound",
    options: [
      { text: "Off", value: false },
      { text: "On", value: true },
    ],
  },
  [FOCUS.MUSIC]: {
    name: "Music",
    options: [
      { text: "Off", value: null },
      { text: "Lofi<sub>1</sub>", value: "lofi-1" },
      { text: "Meditation<sub>1</sub>", value: "meditation-1" },
      { text: "Piano<sub>1</sub>", value: "piano-1" },
    ],
  },
};

export const FOCUS_DEFAULT = {
  [FOCUS.BACKGROUND]: "snowy-forest-1",
  [FOCUS.ANIMATION]: true,
  [FOCUS.SOUND]: true,
  [FOCUS.MUSIC]: "lofi-1",
};

export const BACKGROUNDS = {
  "foggy-forest-1": {
    url:
      "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fimages%2Fbg-foggy-forest-1.jpeg?alt=media&token=aa5b7dd1-74cb-430c-8bab-43e8e07ce069",
    animation: ["fog", "rain"],
    sound: "thunder-forest-1",
  },
  "snowy-forest-1": {
    url:
      "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fimages%2Fbg-snowy-forest-1.jpg?alt=media&token=0a1b2c4b-ee40-4dc8-aaab-85132100de5d",
    animation: "snow",
    sound: "snowy-forest-1",
  },
  "night-sky-1": {
    url:
      "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fimages%2Fbg-night-sky-1.jpg?alt=media&token=8185f2c5-dd3b-441b-9b7d-f02ef317c4ad",
    animation: "stars",
  },
  "rainy-sea-1": {
    url:
      "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fimages%2Fbg-rainy-sea-1.jpg?alt=media&token=5604b961-8806-4ffd-8035-dc074072aa34",
    animation: ["fog", "rain"],
    sound: "calm-rain-1",
  },
};

export const ANIMATION_SOUNDS = {
  "thunder-forest-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fsounds%2Fthunder-forest-1.mp3?alt=media&token=4fd1dff5-55a5-40d4-b8da-9a195222cb7f",
  "snowy-forest-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fsounds%2Fsnowy-forest-1.mp3?alt=media&token=45415bea-a86f-44d9-bd7b-df77e3d2d078",
  "calm-rain-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fsounds%2Fcalm-rain-1.mp3?alt=media&token=45f12623-bc7b-48ad-beb0-8a07ed691cf3",
};

export const MUSICS = {
  "lofi-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fmusics%2Flofi-1.mp3?alt=media&token=584947aa-8ed0-4fb1-9819-52085123f544",
  "meditation-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fmusics%2Fmeditation-1.mp3?alt=media&token=9396c53c-ade6-4564-82a7-fa8256bfd25d",
  "piano-1":
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Fmusics%2Fpiano-1.mp3?alt=media&token=24bf729a-7222-4e7a-92cd-a4d62d5d1fd6",
};
