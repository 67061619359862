import Vue from "vue";
import { ref, get, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { database } from "@/common/firebase";
import { removeBatch, stopBatch, bulkStopBatch } from "@/common/functions";

import { createModuleResetter, ObjectToSortedArray } from "@/helpers/utils";

import { FETCH, REMOVE, STOP, BULK_STOP } from "../../actions";
import { SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
});

const getters = {
  batchsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
  batchsByProjectId: (state, getters) => ({ projectId, status = null }) => {
    const { batchsSorted } = getters;
    if (status) return batchsSorted.filter((b) => b.projectId === projectId && b.status === status);
    return batchsSorted.filter((b) => b.projectId === projectId);
  },
  batchById: (state) => ({ id }) => {
    return state.items[id] || {};
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    Vue.set(state.items, id, { ...payload });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ rootGetters, commit }) {
    const uid = rootGetters["auth/uid"];

    const usersRef = ref(database, `users/${uid}/batchs`);
    const batchs = await get(usersRef).then((snapshot) => snapshot.val());

    if (batchs) for (const batchId in batchs) commit(SET_ITEM, { id: batchId, ...batchs[batchId] });

    onChildAdded(usersRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(usersRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(usersRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },
  async [REMOVE](_, payload) {
    try {
      const { data } = await removeBatch(payload);
      return data;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [STOP](_, payload) {
    try {
      const { data } = await stopBatch(payload);
      return data;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [BULK_STOP](_, payload) {
    try {
      const { data } = await bulkStopBatch(payload);
      return data;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
