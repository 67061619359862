import { ref } from "vue";
import { SCREEN_SIZES } from "../constants/style";

export default {
  install: (Vue) => {
    Vue.prototype.$screen = {
      width: ref(0),
      alias: ref("lg"),
      is(alias = "xs") {
        return this.width.value >= SCREEN_SIZES[alias];
      },
    };

    if (document) {
      new ResizeObserver(() => {
        Vue.prototype.$screen.width.value = document.body.getBoundingClientRect().width;

        for (let key in SCREEN_SIZES) {
          if (Vue.prototype.$screen.width.value >= SCREEN_SIZES[key]) {
            Vue.prototype.$screen.alias.value = key;
          }
        }
      }).observe(document.body);
    }
  },
};
