import Vue from "vue";
import VueRouter from "vue-router";
import VueBus from "vue-bus";
import VueConfetti from "vue-confetti";
import VueInteractJs from "vue-interactjs";
import VueGtag from "vue-gtag";
import { VBTooltip, VBToggle } from "bootstrap-vue";
import { mapState } from "vuex";

import Screen from "./plugins/screen";
import Notify from "./plugins/notify";

import router, { applyBeforeEachRouterHook } from "./router";
import store from "./store";

import Spinner from "./components/Spinner/";
import App from "./App";
import Maintenance from "./Maintenance";

import { setAmount } from "./filters/amount";
import { getTranslation } from "./filters/translation";

import ClickOutsideDirective from "./directives/ClickOutside";

import "./assets/scss/app.scss";

Vue.config.productionTip = false;

Vue.use(VueBus);
Vue.use(VueRouter);
Vue.use(VueConfetti);
Vue.use(VueInteractJs);
Vue.use(Screen);
Vue.use(Notify);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_FIREBASE_ANALYTICS }
});

Vue.directive("b-tooltip", VBTooltip);
Vue.directive("b-toggle", VBToggle);
Vue.directive("click-outside", ClickOutsideDirective);

Vue.filter("__t", getTranslation);
Vue.filter("__amount", setAmount);

Vue.component("v-style", {
  render(createElement) {
    return createElement("style", this.$slots.default);
  }
});

applyBeforeEachRouterHook(store);

const isMaintenance = false;

new Vue({
  store,
  router,
  computed: {
    ...mapState(["initialized"])
  },
  render(h) {
    if (isMaintenance) return h(Maintenance);
    if (!this.initialized) return h(Spinner);
    return h(App);
  }
}).$mount("#app");
