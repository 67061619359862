import TurndownService from "turndown";

import { CHATBOT_ROLES } from "@/constants/aiChat";

import { vertexAIModel } from "@/common/firebase";

const turndownService = new TurndownService();

export const sanitizeMessages = (messages) => {
  const generationConfig = {
    maxOutputTokens: 8000,
  };

  const history = messages.map((message) => ({
    role: message.role === CHATBOT_ROLES.ASSISTANT ? CHATBOT_ROLES.MODEL : message.role,
    parts: [{ text: turndownService.turndown(message.content) }],
  }));

  history.pop(); // Removing the last item because it is sent separately

  if (history[0].role === CHATBOT_ROLES.MODEL) {
    history.splice(0, 1);
  }

  return vertexAIModel.startChat({ history, generationConfig });
};
