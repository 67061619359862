import Vue from "vue";
import { ref, query, get, onChildAdded, onChildChanged } from "firebase/database";

import { database } from "@/common/firebase";

import { createModuleResetter } from "@/helpers/utils";

import { FETCH, LISTEN } from "../../actions";
import { SET_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
});

const getters = {};

const mutations = {
  [SET_ITEM](state, { key, value }) {
    Vue.set(state.items, key, value);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/billing`));
    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        commit(SET_ITEM, { key, value });
      });
    }

    return { isSuccess: true, data };
  },

  async [LISTEN]({ commit }, payload) {
    const { projectId } = payload;

    const resultRef = query(ref(database, `projects/${projectId}/billing`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { key: snapshot.key, value: snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { key: snapshot.key, value: snapshot.val() });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
