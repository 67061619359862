import store from "@/store";

import { LIMITS_MONTHLY, LIMITS_USERS } from "@/constants/users";

import { getCurrentMonth } from "@/helpers/date";

export const isMonthlyLimit = (limit) => {
  return LIMITS_MONTHLY.includes(limit);
};

export const isAboveLimit = (limit = "wordsCreditsUsed", fileSize = 0) => {
  const { billing } = store.state.auth.user;
  const { plan } = store.state.auth.user.billing;

  const max = LIMITS_USERS[plan][limit];

  let used = 0;
  if (isMonthlyLimit(limit)) used = billing.monthly[getCurrentMonth()][limit] || 0;
  else used = billing.active[limit] || 0;

  if (used > max) return true;
  if (fileSize + used > max) return true;
  return false;
};

export const isFormatAllowed = (limit = "storageFormats", format) => {
  const { plan } = store.state.auth.user.billing;

  return LIMITS_USERS[plan][limit].includes(format);
};

export const isVideoDurationAboveLimit = (seconds = 1) => {
  const { plan } = store.state.auth.user.billing;
  const minutes = (Number(seconds) || 1) / 60;

  return LIMITS_USERS[plan]?.videoDuration && minutes > LIMITS_USERS[plan]?.videoDuration;
};

export const getUserLimits = () => {
  const { plan } = store.state.auth.user.billing;
  return LIMITS_USERS[plan];
};
