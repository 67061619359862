import Vue from "vue";
import { ref, get, update } from "firebase/database";

import { database } from "@/common/firebase";

import { createModuleResetter } from "@/helpers/utils";

import { INIT, GET, CREATE } from "../../actions";
import { SET_ITEM, SET_ITEM_NESTED, RESET_STORE } from "../../mutations";

import { DEFAULT_LANGUAGE } from "@/constants/language";

const getInitState = () => ({
  language: null,
  locales: {},
  localesDates: {},
});

const getters = {
  getTranslation: (state) => (language, location) => {
    const keys = location.split("__");
    let value = state.locales[language];

    if (!value) return null;

    for (const key of keys) {
      // eslint-disable-next-line no-prototype-builtins
      if (!value.hasOwnProperty(key)) return null;
      value = value[key];
    }

    return value;
  },
  getCurrentDateLocale: (state) => () => {
    return state.localesDates?.[state.language] || null;
  },
};

const mutations = {
  [SET_ITEM](state, value) {
    for (const key in value) Vue.set(state, key, value[key]);
  },
  [SET_ITEM_NESTED](state, { parent, ...others }) {
    for (const key in others) Vue.set(state[parent], key, others[key]);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [INIT]({ dispatch, commit }, payload) {
    const { language } = payload;
    commit(SET_ITEM, { language });

    if (language !== DEFAULT_LANGUAGE) await dispatch(GET, { language });
  },

  async [GET]({ commit }, payload) {
    try {
      const { language } = payload;

      // App translation
      const snapshot = await get(ref(database, `languages/${language}`));
      const data = snapshot.val();

      commit(SET_ITEM_NESTED, { parent: "locales", [language]: data });
      if (!data) commit(SET_ITEM, { language: DEFAULT_LANGUAGE }); // If no data available, we've got to fallback to default

      // Date translation
      // const { default: dataDate } = await import(`date-fns/locale/${language}`);

      // commit(SET_ITEM_NESTED, { parent: "localesDates", [language]: dataDate });

      return { isSuccess: true, data };
    } catch (error) {
      return { isSuccess: false, message: error };
    }
  },
  async [CREATE]({ rootGetters }, { language, ...payload }) {
    const isAdmin = rootGetters["auth/isAdmin"];

    if (!isAdmin) return;

    try {
      const { location, defaultText } = payload;
      const path = location.replaceAll("__", "/");

      const updates = {};
      updates[`languages/${language}/${path}`] = defaultText;

      update(ref(database), updates);

      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, message: error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
