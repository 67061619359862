import firebase from "@/common/firebase";
import { EMAIL_PROVIDERS } from "@/constants/auth";

let refreshTokenTimer;

export const clearRefreshTokenTimeout = () => clearTimeout(refreshTokenTimer);

export const putTokenToStorage = (token) => {
  localStorage.setItem("token", token);

  clearTimeout(refreshTokenTimer);
  const expirationTime = 1000 * 60 * 55; // 55 minutes
  refreshTokenTimer = setTimeout(
    async () => putTokenToStorage(await firebase.auth().currentUser.getIdToken(true)),
    expirationTime
  );
};

export const getEmailProviderWebmailUrl = (email) => {
  const domain = email.split("@")[1];

  return EMAIL_PROVIDERS[domain] || { name: null, url: null };
};
