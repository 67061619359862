import { ref, query, get } from "firebase/database";

import { database } from "@/common/firebase";

import { encodeAsFirebaseKey, createModuleResetter } from "@/helpers/utils";

import { GET } from "../actions";
import { RESET_STORE } from "../mutations";

const getInitState = () => ({});

const getters = {};

const mutations = {
  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [GET](_, payload) {
    try {
      const { email } = payload;
      const emailKey = encodeAsFirebaseKey(email);
      console.log("emailKey", emailKey);

      const authTemporaryRef = query(ref(database, `usersTemporary/${emailKey}`));
      const result = await get(authTemporaryRef).then((snapshot) => snapshot.val() || {});

      console.log(result);

      return result;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
