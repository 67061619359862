<template>
  <!-- TODO: This can't be higher that 100vh - 100px -->
  <div class="h-100 sidebar-project">
    <div class="d-flex flex-column h-100" data-test="sidebar-project">
      <div class="mt-3 pl-3 pr-3">
        <h5 class="w-100 text-uppercase">
          {{ projectName }}
        </h5>
      </div>

      <div>
        <div class="p-2">
          <sidebar-category
            @click="goToTaskWizardMobile"
            :name="'New Task' | __t('projects__tasks__headline__new-task')"
            class="mt-3 pr-1 mb-3 bg-lavender rounded-lg task-category"
          />

          <sidebar-task
            v-for="t in openTasks()"
            @go-to="goToTask(t)"
            :key="t.id"
            :name="t.name"
            :is-active="t.id === taskId"
            :params="{ taskId: t.id }"
            :project-id="projectId"
            :task-id="t.id"
            to="ProjectTask"
            class="mb-1"
            icon="check"
          />

          <div v-if="!openTasks().length">
            <p class="ml-2">
              <small>
                <em>{{ "You don't have any task yet." | __t("projects__tasks__you-dont-have-any-task-yet") }}</em>
              </small>
            </p>
          </div>
          <!-- <div class="mt-4 mb-4" />
          <sidebar-category-dropdown
            @create-file="$router.push({ name: 'ProjectDocumentWizard' })"
            @create-folder="triggerCreateDocumentFolder"
            name="Files"
            class="pr-1 mb-1"
          /> -->

          <!--   <div style="width: 90%;" ref="dragArea">
            <sidebar-document-tree
              v-for="child in tree"
              :key="child.id"
              :project-id="projectId"
              :item="child"
              v-on="$listeners"
              class="pr-1 mb-1 drag-handle"
            />
          </div> -->
        </div>
      </div>

      <div class="ml-2 mt-auto">
        <div v-for="i in NAVIGATION" :key="i.id">
          <sidebar-item
            v-if="i.condition(projectIsHome)"
            :name="i.name"
            :icon="i.icon"
            :color="i.color"
            :is-active="i.to === routeName || (i.container && routePath.includes(i.container))"
            class="mb-1"
            @click="goTo(i.to)"
          />
        </div>

        <div v-if="currentPlanId === PLANS.FREE">
          <div class="m-4 p-2 rounded-lg" style="background-color: #FAE795">
            <img src="@/assets/character/smiling.png" class="d-block m-auto" />

            <div class="pl-3 pr-3 pb-3">
              <p class="mb-0 text-black text-uppercase text-xl">
                <strong>Upgrade Now</strong>
              </p>
              <p class="text-black">Upgrade now, get more CPU usage (aka credits) and accomplish so much more.</p>

              <div class="text-center">
                <b-button
                  :to="{ name: 'MemberBilling' }"
                  variant="transparent"
                  class="pl-4 pr-4"
                  style="background-color: #F8DC64; border-color: #F8DC64"
                  pill
                >
                  <strong class="mt-n2 text-l text-uppercase text-black">Upgrade Now</strong>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { FETCH, CREATE, LISTEN, UPDATE } from "@/store/actions";

import { BButton } from "bootstrap-vue";

import { NAVIGATION } from "@/constants/project";
import { PLANS } from "@/constants/billing";
import { DOCUMENTS_TYPES } from "@/constants/documents";

// import SidebarCategoryDropdown from "./CategoryDropdown";
import SidebarCategory from "./Category";
import SidebarItem from "./Item";
// import SidebarDocumentTree from "./DocumentTree";
import SidebarTask from "./Task";

export default {
  name: "ProjectSidebar",
  components: { BButton, /* SidebarCategoryDropdown, */ SidebarCategory, SidebarItem, /* SidebarDocumentTree, */ SidebarTask },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    teammateId: {
      type: String,
      default: null,
    },
    taskId: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    routePath: {
      type: String,
      default: null,
    },
    isShowingSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      projectName: (state) => state.projectsSettings.items?.name,
      projectIsHome: (state) => state.projectsSettings.items?.isHome,
      projectBilling: (state) => state.projectsBilling.items,
    }),
    ...mapGetters("projectsDocuments", ["tree"]),
    ...mapGetters("projectsTasks", ["openTasks"]),
    currentPlanId() {
      return this.projectBilling.plan || PLANS.FREE;
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data() {
    return {
      currentFileId: null,
      localTree: [],

      currentlyHighlighted: null,

      isLoading: true,

      NAVIGATION,
      PLANS,
    };
  },
  watch: {
    tree: {
      handler() {
        this.localTree = JSON.parse(JSON.stringify(this.tree));
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("projectsDocuments", {
      fetchDocuments: FETCH,
      listenDocuments: LISTEN,
      createDocument: CREATE,
      updateDocument: UPDATE,
    }),
    ...mapActions("projectsTeammates", { fetchTeammates: FETCH, listenTeammates: LISTEN }),
    ...mapActions("projectsTasks", { fetchTasks: FETCH, listenTasks: LISTEN }),
    async init() {
      this.isLoading = true;

      const { projectId } = this;

      await this.fetchTeammates({ projectId });
      await this.listenTeammates({ projectId });

      await this.fetchDocuments({ projectId });
      await this.listenDocuments({ projectId });

      await this.fetchTasks({ projectId });
      await this.listenTasks({ projectId });

      const { createTask } = this.$route.query;

      if (createTask) this.$router.push({ name: "ProjectTaskWizard" });
      this.isLoading = false;
      this.localTree = JSON.parse(JSON.stringify(this.tree));
    },

    async triggerCreateDocumentFolder() {
      this.isSending = true;

      const { projectId } = this;
      const type = DOCUMENTS_TYPES.FOLDER;
      const { id: documentId } = await this.createDocument({ projectId, type });

      this.$router.push({ name: "ProjectDocument", params: { documentId } });

      this.isSending = false;
    },

    onStart(event) {
      event.stopPropagation();
      this.drag = true;
    },

    onEnd(event) {
      event.stopPropagation();
      this.drag = false;
      this.triggerUpdateDocument(event);

      this.currentlyHighlighted.style.backgroundColor = "unset";
      this.currentlyHighlighted = null;
    },

    onMove(event) {
      if (this.currentlyHighlighted) {
        this.currentlyHighlighted.style.backgroundColor = "unset"; // Reset background color
      }

      this.currentlyHighlighted = event.related;
      this.currentlyHighlighted.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

      return true;
    },

    triggerUpdateDocument(event) {
      const { projectId } = this;

      let movedElement = event.item.querySelector("[data-id]");
      let movedElementId = movedElement.getAttribute("data-id");

      let newParentEl = event.to.previousSibling;

      let newParentId = newParentEl ? newParentEl.getAttribute("data-id") : null;
      const newIndex = event.newIndex;

      this.updateDocument({
        projectId,
        id: movedElementId,
        parentId: newParentId,
        position: newIndex,
      });
    },

    goToTaskWizardMobile() {
      const { isShowingSidebar, isMobile } = this;
      const { name: routeName } = this.$route;

      if (!isMobile) {
        this.$router.push({ name: "ProjectTaskWizard" });
        return;
      }

      if (routeName === "ProjectTaskWizard") {
        this.$emit("update:is-showing-sidebar", !isShowingSidebar);
        return;
      }

      this.$router.push({ name: "ProjectTaskWizard" });
    },
    goToTask({ id: taskId }) {
      const { isShowingSidebar, isMobile } = this;
      const { name: routeName } = this.$route;
      const { taskId: currentTaskId } = this.$route.params;

      if (!isMobile) {
        this.$router.push({ name: "ProjectTask", params: { taskId } });
        return;
      }

      if (routeName === "ProjectTask" && currentTaskId === taskId) {
        this.$emit("update:is-showing-sidebar", !isShowingSidebar);
        return;
      }

      this.$router.push({ name: "ProjectTask", params: { taskId } });
    },
    goTo(routeName) {
      const { isMobile } = this;
      const { name: currentRouteName } = this.$route;

      if (!isMobile) {
        this.$router.push({ name: routeName });
        return;
      }

      if (currentRouteName === routeName) {
        this.$emit("update:is-showing-sidebar", false);
        return;
      }

      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.task-category:hover {
  background-color: $black !important;
}

.sidebar-project {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.list-change-enter-active,
.list-change-leave-active {
  transition: opacity 0.5s;
}
.list-change-enter,
.list-change-leave-to {
  opacity: 0;
}
</style>
