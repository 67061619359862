export const SET = "set";
export const DEL = "del";

// App
export const SET_INITIALIZED = "setInitialized";

// Items
export const ADD_ITEM = "addItem";
export const SET_ITEM = "setItem";
export const DEL_ITEM = "delItem";
export const INCREMENT_ITEM = "incrementItem";

export const SET_ITEM_NESTED = "setItemNested";
export const DEL_ITEM_NESTED = "delItemNested";

// Queues
export const ADD_QUEUE = "addQueue";
export const DEL_QUEUE = "delQueue";

// Global
export const SET_KEY = "setKey";
export const ADD_NESTED = "addNested";
export const SET_NESTED = "setNested";
export const RESET_STORE = "resetStore";

// Sidebar
export const SET_SIDEBAR = "setSidebar";
export const DEL_SIDEBAR = "delSidebar";

// Skeleton
export const SET_SKELETON = "setSkeleton";
export const DEL_SKELETON = "delSkeleton";

// State
export const SET_IS_LOADING = "setIsLoading";
export const SET_IS_SENDING = "setIsSending";
export const SET_IS_PUBLISHING = "setIsPublishing";
export const SET_IS_INIT = "setIsInit";
export const SET_IS_ACTIVE = "setIsActive";
export const SET_IS_SAVED = "setIsSaved";
export const SET_HAS_CHANGES = "setHasChanges";
export const SET_HAS_ENDED = "setHasEnded";

// Auth
export const SET_AUTH_ACCOUNT = "setAuthAccount";
export const SET_AUTH_CONFIG = "setAuthConfig";
export const SET_AUTH_WALLET = "setAuthWallet";
export const SET_AUTH_NOTIFICATIONS = "setAuthNotifications";
export const SET_AUTH_ONBOARDING = "setAuthOnboarding";

export const SET_WALLET = "setWallet";
export const SET_WALLET_ERROR = "setWalletError";

// Account
export const SET_ACCOUNT = "setAccount";
export const SET_EVENT = "setEvent";

/**************** MARKETING ****************/

// Campaigns

// Pages
export const SET_PAGE = "setPage";
export const DEL_PAGE = "delPage";
export const SET_PAGE_ID = "setPageId";

// Templates
export const SET_TEMPLATE = "setTemplate";
export const DEL_TEMPLATE = "delTemplate";

/**************** INNOVATION ****************/

// Apps
export const SET_PROJECT = "setApp";
export const SET_PROJECT_FULL = "setAppFull";
export const DEL_PROJECT = "delApp";

// Products
export const ADD_PRODUCT = "addProduct";
export const SET_PRODUCT = "setProduct";
export const DEL_PRODUCT = "delProduct";

export const UP_TREE = "upTree";

export const ADD_CHAPTER_TO_TREE = "addChapter";
export const UP_CHAPTER_IN_TREE = "upChapter";
export const DEL_CHAPTER_FROM_TREE = "delChapter";
export const ADD_LESSON_TO_TREE = "addLesson";
export const UP_LESSON_IN_TREE = "upLesson";
export const DEL_LESSON_FROM_TREE = "delLesson";

export const ADD_AUDIO_TO_TREE = "addAudioToTree";
export const UP_AUDIO_IN_TREE = "updateAudioInTree";
export const DEL_AUDIO_FROM_TREE = "delAudioFromTree";

/****************  NFTS ****************/

// Builder
export const SET_BUILDER = "setBuilder";

/**************** INTELLIGENCE ****************/

// Reports
export const SET_REPORT = "setReport";
export const SET_REPORT_LABEL = "setReportLabel";
export const SET_REPORT_DATA = "setReportData";
export const SET_DATASET_INTERVAL = "setDatasetInterval";
export const INCREMENT_REPORT_DATA = "incrementReportData";

/**************** ADMIN ****************/

// Files
export const SET_FILE = "setFile";

// permissions
export const SET_PERMISSIONS = "setPermissions";

export const SET_IS_ADMIN = "setIsAdmin";

export const FETCH_END = "setArticles";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "purgeAuth";
export const SET_ARTICLE = "setArticle";
export const SET_AUTH = "setUser";
export const SET_COMMENTS = "setComments";
export const SET_ERROR = "setError";
export const PURGE_ERRORS = "purgeErrors";
export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_ARTICLE_IN_LIST = "updateArticleInList";
export const RESET_STATE = "resetModuleState";
export const SET_ACCOUNT_LOGO = "setAccount";
export const PURGE_ACCOUNT = "purgeAccount";
export const SET_ROLE = "setRole";
export const SET_AUTHORIZATION = "setAuthorization";

// Form stepper
export const UPDATE_FORM_BUILDER_CURRENT_STEP = "UPDATE_FORM_BUILDER_CURRENT_STEP";
// Form builder
export const PUT_FORM_BUILDER_FORM = "PUT_FORM_BUILDER_FORM";
export const UPDATE_FORM_BUILDER_FORM = "UPDATE_FORM_BUILDER_FORM";
export const UPDATE_FORM_SELECTION = "UPDATE_FORM_SELECTION";
export const RESET_FORM_SELECTION = "RESET_FORM_SELECTION";
export const RESET_FORM_BUILDER = "RESET_FORM_BUILDER";
export const CLEAR_NEXT_FORM_HISTORY = "CLEAR_NEXT_FORM_HISTORY";
export const PUSH_NEXT_FORM_HISTORY = "PUSH_NEXT_FORM_HISTORY";
export const POP_NEXT_FORM_HISTORY = "POP_NEXT_FORM_HISTORY";
export const PUSH_PREV_FORM_HISTORY = "PUSH_PREV_FORM_HISTORY";
export const POP_PREV_FORM_HISTORY = "POP_PREV_FORM_HISTORY";

// OAuth builder
export const UPDATE_OAUTH_BUILDER_CURRENT_STEP = "UPDATE_OAUTH_BUILDER_CURRENT_STEP";
export const PUT_OAUTH_BUILDER_FORM = "PUT_OAUTH_BUILDER_FORM";
export const UPDATE_OAUTH_FORM_BUILDER_FORM = "UPDATE_OAUTH_FORM_BUILDER_FORM";

/* Extensions */

// VSL
export const SET_NODE_SCRIPT = "SET_NODE_SCRIPT";
export const SET_NODE_SUBTITLES = "SET_NODE_SUBTITLES";
export const SET_NODE_URL = "SET_NODE_URL";
export const PURGE_NODE = "PURGE_NODE";
