export const CHATBOT_ITEMS = {
  DISCUSSION: "discussion",
  MESSAGE: "message",
};

export const CHATBOT_ROLES = {
  USER: "user",
  ASSISTANT: "assistant",
  MODEL: "model",
};

export const CHATBOT_MESSAGE_TYPE = {
  TEXT: "text",
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  FILE: "file",
  DOCUMENT: "document",
  CARD: "card",
  SOURCES: "sources",
  DYNAMIC: "dynamic",
  URL: "url",
  FORM: "form",
};

export const CHATBOT_REPLY_FORMAT = {
  TEXT: "text",
  BUTTON: "button",
  GENDER: "gender",
  EMAIL: "email",
  BIRTHDAY: "birthday",
  SELECT: "select",
};

export const CHATBOT_TOOLS = {
  SUGGEST_OUTLINE: "suggest-outline",
  SUGGEST_CHAPTER: "suggest-chapter",
  SUGGEST_TEXT: "suggest-text",
  REPLACE_TEXT: "replace-text",
};

export const CHATBOT_THEME = {
  container: {
    width: "100%",
    border: "0px",
    overflowX: "hidden",
    overflowY: "scroll",
    margin: "0 auto",
    backgroundColor: "#f0f2f",
  },
  messages: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    maxWidth: "1014px",
    borderRadius: "25px",
    color: "#212529",
  },
  inputBar: {
    display: "flex",
    alignItems: "center",
  },
  replyInput: {
    backgroundColor: "#151718",
    color: "white",
    fontSize: "1.3rem",
  },
  welcomeText: {
    marginTop: "1rem",
    textAlign: "left",
    marginBottom: "10px",
    color: "#212529",
  },
  errorText: {
    padding: "1rem",
    borderRadius: "25px",
    textAlign: "left",
    marginBottom: "10px",
    color: "#FFF",
    backgroundColor: "#272a34",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  messageAssistant: {
    textAlign: "left",
    padding: "5px",
  },
  messageUser: {
    backgroundColor: "#151718",
    borderRadius: "25px",
    textAlign: "left",
    padding: "5px",
    width: "90%",
    float: "right",
  },
  cardButton: {
    variant: "outline-dark",
    style: "width: 100%;",
    isPill: true,
  },
  modalRemove: {
    variant: "dark",
  },
  removeMessageButtonUser: "white",
  removeMessageButtonAssistant: "white",
};

export const DEFAULT_DISCUSSION_ID = "main";

export const DEFAULT_WELCOME_TEXT = `<h3 class="font-handwriting">Greetings, Author.</h3>
<p>You can ask me questions about your book, a direction to take a or a feedback on what you wrote.</p>
`;

export const DEFAULT_WELCOME_TEXT_OUTLINE = `<h3 class="font-handwriting">Greetings, Author.</h3>
<p>You can ask me questions about your book, and you're outline. I can also suggest ideas, chapters and text during our chat.</p>
`;

export const DEFAULT_ASSISTANT = {
  name: "Louis AI",
  avatar:
    "https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Favatar.svg?alt=media&token=b776d303-a8c6-40b1-abcd-bb9ed7054e9d",
};
