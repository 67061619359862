import Vue from "vue";
import {
  ref,
  query,
  push,
  get,
  set,
  update,
  remove,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  serverTimestamp,
} from "firebase/database";

import { database } from "@/common/firebase";
import { renameProjectTask } from "@/common/functions";

import { getTranslation } from "@/filters/translation";
import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, CREATE, GET, UPDATE, REMOVE, LISTEN, RENAME, CHECK, UNCHECK } from "../../actions";
import { SET_ITEM, DEL_ITEM, DEL_ITEM_NESTED, RESET_STORE } from "../../mutations";
import { ALLOWED_KEYS_IN_INDEX } from "@/constants/database";

const getInitState = () => ({
  items: {},
});

const getters = {
  tasksSorted: (state) => () => {
    return ObjectToSortedArray(state.items, "createdAt");
  },

  openTasks: (state, getters) => () => {
    const tasks = getters.tasksSorted();
    return tasks.filter(({ isDone }) => Boolean(isDone) === false);
  },

  completedTasks: (state, getters) => () => {
    const tasks = getters.tasksSorted();
    return tasks.filter(({ isDone }) => isDone === true);
  },

  taskById: (state) => ({ id }) => {
    return state.items[id];
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    if (!state.items[id]) Vue.set(state.items, id, {});

    for (const key in payload) {
      Vue.set(state.items[id], key, payload[key]);
    }
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [DEL_ITEM_NESTED](state, { id, key }) {
    Vue.delete(state.items[id], key);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/tasks`));
    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([id, value]) => {
        commit(SET_ITEM, { id, ...value });
      });
    }

    return { isSuccess: true, data };
  },

  async [CREATE]({ rootGetters }, { projectId, ...others }) {
    const createdAt = serverTimestamp();
    const uid = rootGetters["auth/uid"];

    try {
      const name = getTranslation("No Name", `placeholder__no-name`);
      const data = { name, createdAt, uid, ...others };
      const request = push(ref(database, `projects/${projectId}/tasks`));
      const id = request.key;

      await set(ref(database, `projects/${projectId}/tasks/${id}`), { ...data });
      await set(ref(database, `projects/${projectId}/index/tasks/${id}`), { ...data });

      return { isSuccess: true, id, ...data };
    } catch ({ details }) {
      return { isSuccess: false, ...details };
    }
  },
  async [GET]({ commit }, { projectId, taskId }) {
    const resultRef = query(ref(database, `projects/${projectId}/tasks/${taskId}`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: taskId, [snapshot.key]: snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: taskId, [snapshot.key]: snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM_NESTED, { id: taskId, key: snapshot.key });
    });

    const snapshot = await get(resultRef);
    const data = snapshot.val();

    commit(SET_ITEM, { id: taskId, ...snapshot.val() });

    return { taskId, ...data };
  },
  async [UPDATE]({ rootGetters }, payload) {
    const updatedBy = rootGetters["auth/uid"];
    const updatedAt = serverTimestamp();

    const { projectId, taskId, ...others } = payload;

    try {
      await update(ref(database, `projects/${projectId}/tasks/${taskId}`), { updatedAt, updatedBy, ...others });

      const updates = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (ALLOWED_KEYS_IN_INDEX.includes(key)) updates[`projects/${projectId}/index/tasks/${taskId}/${key}`] = value;
      });

      update(ref(database), updates);

      return { isSuccess: true, taskId };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [REMOVE](_, { projectId, taskId }) {
    await remove(ref(database, `projects/${projectId}/index/tasks/${taskId}`));
    await remove(ref(database, `projects/${projectId}/tasks/${taskId}`));
    await remove(ref(database, `projects/${projectId}/tasksMessages/${taskId}`));

    return { taskId, isSuccess: true };
  },

  async [LISTEN]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/tasks/`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },

  async [RENAME](_, payload) {
    const { messages, ...others } = payload;

    const messagesSanitized = messages.map((message) => ({
      role: message.role,
      content: message.content,
    }));

    const formattedMessages = messagesSanitized.map((message) => `- ${message.role}: ${message.content}`).join("\n");

    try {
      const { data } = await renameProjectTask({ ...others, formattedMessages });

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },

  async [CHECK]({ rootGetters }, payload) {
    const doneBy = rootGetters["auth/uid"];
    const doneAt = serverTimestamp();

    const { projectId, taskId } = payload;

    try {
      await update(ref(database, `projects/${projectId}/tasks/${taskId}`), { isDone: true, doneAt, doneBy });
      await update(ref(database, `projects/${projectId}/index/tasks/${taskId}`), { isDone: true });

      return { isSuccess: true, taskId };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [UNCHECK](_, payload) {
    const doneBy = null;
    const doneAt = null;

    const { projectId, taskId } = payload;

    try {
      await update(ref(database, `projects/${projectId}/tasks/${taskId}`), { isDone: false, doneAt, doneBy });
      await update(ref(database, `projects/${projectId}/index/tasks/${taskId}`), { isDone: false });

      return { isSuccess: true, taskId };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
