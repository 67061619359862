import Vue from "vue";
import { ref, query, get, onChildAdded, onChildChanged, orderByKey } from "firebase/database";

import { database } from "@/common/firebase";

import { getCurrentDay } from "@/helpers/date";
import { createModuleResetter } from "@/helpers/utils";

import { FETCH, LISTEN } from "../../actions";
import { SET_ITEM_NESTED, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  today: {},
  total: {},
});

const getters = {};

const mutations = {
  [SET_ITEM_NESTED](state, { key, id, value }) {
    Vue.set(state[key], id, value);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }) {
    const totalRef = query(ref(database, `analytics/total`), orderByKey());
    const totalSnapshot = await get(totalRef);
    const totalData = totalSnapshot.val();

    const currentDay = getCurrentDay();
    const todayRef = query(ref(database, `analytics/daily/${currentDay}`), orderByKey());
    const todaySnapshot = await get(todayRef);
    const todayData = todaySnapshot.val();

    Object.entries(totalData).forEach(([id, value]) => {
      commit(SET_ITEM_NESTED, { key: "total", id, value });
    });

    Object.entries(todayData).forEach(([id, value]) => {
      commit(SET_ITEM_NESTED, { key: "today", id, value });
    });

    return { isSuccess: true, totalData, todayData };
  },

  async [LISTEN]({ commit }) {
    const totalRef = query(ref(database, `analytics/total`), orderByKey());

    onChildAdded(totalRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key: "total", id: snapshot.key, value: snapshot.val() });
    });

    onChildChanged(totalRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key: "total", id: snapshot.key, value: snapshot.val() });
    });

    const currentDay = getCurrentDay();
    const todayRef = query(ref(database, `analytics/daily/${currentDay}`), orderByKey());

    onChildAdded(todayRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key: "today", id: snapshot.key, value: snapshot.val() });
    });

    onChildChanged(todayRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key: "today", id: snapshot.key, value: snapshot.val() });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  getters,
  mutations,
  actions,
};
