import { DateTime } from "luxon";
import { formatDistanceToNow } from "date-fns";

export const convertTimestampToDate = (timestamp) => {
  if (timestamp.toString().length === 10 || new Date(timestamp).getFullYear() < 1973) {
    timestamp = timestamp * 1000;
  }

  const date = new Date(timestamp);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const convertTimestampToISO = (value) => {
  return DateTime.fromJSDate(new Date(value)).toISOTime();
};

export const distanceToNow = (value, suffix = false, locale = null) => {
  if (value) {
    const options = { addSuffix: suffix };
    if (locale) options.locale = locale;
    return formatDistanceToNow(new Date(value), options);
  }
  return "";
};

export const getCurrentMonth = () => {
  const currentDate = DateTime.now();
  return currentDate.toFormat("yyyy-MM");
};

export const getCurrentDay = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
};
