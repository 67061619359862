<template>
  <div class="vw-100 vh-100 d-flex align-items-center justify-content-center">
    <div class="d-flex align-items-center border rounded px-4 py-4">
      <img src="@/assets/icons/setting.svg" class="mr-2" width="20" height="20" />
      <span class="text-white">{{ "Authorization..." | __t("status____authorization") }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGIN_BY_LINK, LOGOUT } from "@/store/actions";

import { ERRORS_API_DEFAULT } from "@/constants/errors";
import { AUTH_ERROR_MESSAGE } from "@/constants/local-storage";

export default {
  name: "LoginByLink",
  layout: "",

  beforeRouteEnter(_, __, next) {
    next(async (vm) => {
      if (vm.$route?.query?.mode == "signIn") {
        const { language: forcedLanguage } = vm.$route.query;
        const { isSuccess } = await vm.loginByLink({ forcedLanguage });

        if (!isSuccess) {
          next({ name: "Login" });
          return;
        }

        next({ name: "Projects" });
      } else {
        next({ name: "Login" });
      }
    });
  },
  data() {
    return {
      authTimeout: null,
    };
  },
  created() {
    this.authTimeout = setTimeout(async () => {
      window?.localStorage?.setItem(
        AUTH_ERROR_MESSAGE,
        this.$options.filters.__t(ERRORS_API_DEFAULT, "common__auth-timeout-error")
      );

      await this.logout();
    }, 50000);
  },
  destroyed() {
    clearTimeout(this.authTimeout);
  },
  methods: {
    ...mapActions("auth", { loginByLink: LOGIN_BY_LINK, logout: LOGOUT }),
  },
};
</script>
