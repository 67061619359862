import Vue from "vue";
import { getDatabase, ref, query, get } from "firebase/database";

import { ObjectToSortedArrayReverse } from "@/helpers/utils";

import { GET } from "../../actions";
import { SET_ITEM, DEL_ITEM } from "../../mutations";

const database = getDatabase();

const getInitState = () => ({
  items: {},
});

const getters = {
  itemsSorted: (state) => () => {
    return ObjectToSortedArrayReverse(state.items, "createdAt");
  },
  itemsSortedByTaskId: (_, getters) => (taskId) => {
    const itemsSorted = getters.itemsSorted();
    return itemsSorted.filter((c) => c.taskId === taskId);
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    Vue.set(state.items, id, payload);
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },
};

const actions = {
  async [GET](_, { projectId, taskId, messageId }) {
    const resultRef = query(ref(database, `projects/${projectId}/tasksMessages/${taskId}/${messageId}`));

    const snapshot = await get(resultRef);
    const data = snapshot.val();

    return { messageId, ...data };
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  getters,
  mutations,
  actions,
};
