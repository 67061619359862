<template>
  <div data-cy="login">
    <h3 class="mt-50 text-center text-uppercase text-white">{{ "Reset Password" | __t("members__reset-password") }}</h3>

    <p class="mb-50 text-center">{{ "Fill the following form." | __t("auth__fill-the-following-form") }}</p>

    <errors v-if="errorMessage" :error="errorMessage" class="mb-4 mt-1" data-cy="login-errors" />

    <b-form @submit="triggerResetPassword">
      <fieldset class="form-group" data-cy="login-fieldset">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">{{ "Email" | __t("common__input__email") }}</p>

          <base-input
            name="email"
            type="email"
            id="email"
            v-model="email"
            :disabled="isSending"
            placeholder="mycoolmail@email.com"
            theme-border="border"
            @keyup.enter="triggerResetPassword"
            :theme="theme"
          />
        </div>

        <input-error
          v-if="!$v.email.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="login-error"
        />
        <input-error v-if="!$v.email.email" :error-code="'email'" :has-submit-error.sync="hasSubmitError" data-cy="login-error" />
      </fieldset>

      <div class="text-right mt-3">
        <b-button @click="triggerResetPassword" variant="primary" class="w-100" data-cy="login-submit" :disabled="isSending" pill>
          {{ "Reset" | __t("button__reset") }}
        </b-button>
      </div>
    </b-form>

    <router-link :to="{ name: 'Login' }" data-cy="link-login" class="text-info d-inline-block mt-1">
      {{ "Remember Your Password? Login now." | __t("auth__remember-password-login-now") }}
    </router-link>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { RESET_PASSWORD } from "@/store/actions";

import { BForm, BButton } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import Errors from "@/components/Data/Errors";
import InputError from "@/components/Data/ErrorsInput";
import BaseInput from "@/components/Forms/Base/BaseInput";

export default {
  name: "ResetPasswordForm",
  mixins: [validationMixin],
  components: { Errors, InputError, BForm, BButton, BaseInput },
  data() {
    return {
      email: null,

      errorMessage: null,

      hasSubmitError: null,
      isSending: false,
    };
  },
  methods: {
    ...mapActions("auth", { resetPassword: RESET_PASSWORD }),
    async triggerResetPassword(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;

      const { email } = this;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { isSuccess, error } = await this.resetPassword({ email });

      if (isSuccess) {
        this.$emit("success");
        this.isSending = false;
        return;
      }

      this.errorMessage = error;
      this.isSending = false;
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
