<template>
  <div
    @click="$emit('click')"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="projectbar__icon pr-2 pl-2 rounded"
    :class="{ 'is-active': isActive }"
    :style="style"
  >
    <img
      v-if="icon && (isHovering || isActive)"
      :src="require(`@/assets/icons/${icon}-active.svg`)"
      width="12"
      height="12"
      class="float-left mt-2 mr-3"
    />
    <img
      v-else-if="icon && !isHovering"
      :src="require(`@/assets/icons/${icon}.svg`)"
      width="12"
      height="12"
      class="float-left mt-2 mr-3"
    />

    <span class="text-capitalize">{{ name | __t(`sidebar__member__${toSlug(name)}`) }}</span>
  </div>
</template>

<script>
import { toSlug } from "@/helpers/utils";

export default {
  name: "Item",
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const { color, isHovering, isActive } = this;
      if (isHovering || isActive) return `background-color: ${color}`;
      return ``;
    },
  },
  data: () => ({
    isHovering: false,
  }),
  methods: {
    toSlug,
  },
};
</script>

<style lang="scss" scoped>
.projectbar__icon {
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  width: 90%;

  span {
    color: #9b9b9b;
  }
}

.projectbar__icon.is-active,
.projectbar__icon:hover {
  span {
    color: #ffffff;
  }
}
</style>
