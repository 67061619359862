import Router from "vue-router";

import { INIT, TRIGGER_EVENT } from "@/store/actions";

import websiteRoutes from "@/modules/website/router";
import authRoutes from "@/modules/auth/router";
import membersRoutes from "@/modules/members/router";
import appsRoutes from "@/modules/projects/router";
import adminRoutes from "@/modules/admin/router";

import { AUTH_TARGET_ROUTE } from "@/constants/local-storage";

const baseRoutes = [
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const routes = baseRoutes.concat(websiteRoutes, authRoutes, membersRoutes, appsRoutes, adminRoutes);
const router = new Router({ mode: "history", routes });

export const applyBeforeEachRouterHook = (store) => {
  const initPromise = store.dispatch(INIT);

  router.beforeEach((to, from, next) => {
    store.dispatch(`trackings/${TRIGGER_EVENT}`, { event: "pageview" });

    const { projectId } = to.params;
    const { projectId: projectIdFrom } = from.params;

    if (projectId && projectIdFrom && projectId !== projectIdFrom) {
      const { origin } = window.location;
      const { fullPath } = to;

      window.location = `${origin}${fullPath}`;
    }

    initPromise.then(() => {
      const isAuthenticated = store.getters["auth/isAuthenticated"];
      // const isAuthorized = store.getters["auth/isAuthorized"];
      // const hasPermission = store.getters["auth/hasPermission"];

      const authIsRequired = to.matched.some((record) => record.meta.auth);

      if (authIsRequired && !isAuthenticated) {
        if (!localStorage.getItem(AUTH_TARGET_ROUTE))
          localStorage.setItem(AUTH_TARGET_ROUTE, JSON.stringify({ path: to.path, query: to.query }));

        return next({ name: "ProjectPublic" });
      }

      /* const authToAccountIsRequired = authIsRequired && to.matched.some((record) => record.meta.authAccount);
      const { section, action } = to.meta;
      const shouldCheckAccess = Boolean(section && action);
      const hasAccess = !shouldCheckAccess || hasPermission(section, action);

      if (authToAccountIsRequired && !isAuthorized && !hasAccess) {
        return next({ name: "home" });
      } */

      return next();
    });
  });
};

export default router;
