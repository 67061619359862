import Vue from "vue";
import { ref, query, get, update, onChildAdded, onChildChanged, orderByKey, limitToLast, endBefore } from "firebase/database";

import { database } from "@/common/firebase";

import { createModuleResetter } from "@/helpers/utils";
import { sanitizeNotification, triggerAlarm, cleanAlarm } from "@/helpers/notifications";

import { FETCH, UPDATE } from "../../actions";
import { ADD_ITEM, SET_ITEM, RESET_STORE } from "../../mutations";

import { NUMBER_ITEMS_MAX } from "@/constants/project";

const getInitState = () => ({
  items: [],
});

const getters = {
  countUnseenNotifications(state) {
    return state.items.filter(({ hasBeenSeen }) => !hasBeenSeen).length;
  },
};

const mutations = {
  [ADD_ITEM](state, { unshift = false, ...payload }) {
    if (unshift) state.items.unshift({ ...payload });
    else state.items.push({ ...payload });
  },
  [SET_ITEM](state, { index, ...payload }) {
    for (const key in payload) Vue.set(state.items[index], key, payload[key]);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ rootGetters, state, commit }, lastKey) {
    const uid = rootGetters["auth/uid"];

    if (!lastKey) {
      const resultRef = query(ref(database, `users/${uid}/notifications`), orderByKey(), limitToLast(5));

      onChildAdded(resultRef, (snapshot) => {
        const notification = sanitizeNotification(snapshot.val());
        if (notification) commit(ADD_ITEM, { unshift: true, id: snapshot.key, ...notification });

        if (state.items.length > NUMBER_ITEMS_MAX && !notification.hasBeenSeen) triggerAlarm();
      });

      onChildChanged(resultRef, (snapshot) => {
        const { items } = state;
        const index = items.findIndex(({ id }) => id === snapshot.key);

        if (index >= 0) commit(SET_ITEM, { index, ...snapshot.val() });
      });

      return;
    }

    const resultRef = query(
      ref(database, `users/${uid}/notifications`),
      orderByKey(),
      endBefore(lastKey),
      limitToLast(NUMBER_ITEMS_MAX)
    );
    const snapshot = await get(resultRef);

    const result = Object.entries(snapshot.val())
      .sort()
      .reverse();

    result.forEach(([id, data]) => {
      const notification = sanitizeNotification(data);
      if (notification) commit(ADD_ITEM, { id, ...notification });
    });
  },
  async [UPDATE]({ rootGetters }, { ids }) {
    const uid = rootGetters["auth/uid"];

    const updates = {};
    ids.forEach((id) => {
      updates[`users/${uid}/notifications/${id}/hasBeenSeen`] = true;
    });

    update(ref(database), updates);

    cleanAlarm();
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
