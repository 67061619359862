import { loadStripe } from "@stripe/stripe-js";

import {
  initiateStripeCheckout,
  initiateStripeCheckoutTopup,
  getStripeSubscription,
  cancelStripeSubscription,
  upgradeStripeSubscription,
  topupStripePaymentMethod,
  getStripeProratedAmount,
} from "@/common/functions";

import {
  INIT,
  STRIPE_INIT_CHECKOUT,
  STRIPE_INIT_CHECKOUT_TOPUP,
  STRIPE_GET_SUBSCRIPTION,
  STRIPE_CANCEL_SUBSCRIPTION,
  STRIPE_UPGRADE_SUBSCRIPTION,
  STRIPE_PAYMENTMETHOD_CHECKOUT_TOPUP,
  STRIPE_GET_PRORATED,
} from "../actions";

const state = { stripe: null };

const getters = {};

const mutations = {};

const actions = {
  async [INIT]() {
    const { VUE_APP_STRIPE } = process.env;

    // Bad bad bad practice
    state.stripe = await loadStripe(VUE_APP_STRIPE);
  },
  async [STRIPE_INIT_CHECKOUT](_, payload) {
    try {
      const { data } = await initiateStripeCheckout({ ...payload });

      return { ...data };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false };
    }
  },
  async [STRIPE_INIT_CHECKOUT_TOPUP]({ rootGetters }, payload) {
    const { topup = { credits: 0 } } = rootGetters["auth/billing"];
    const { credits = 0 } = topup;

    try {
      const { data } = await initiateStripeCheckoutTopup({ credits, ...payload });

      return { ...data };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false };
    }
  },
  async [STRIPE_GET_SUBSCRIPTION](_, payload) {
    try {
      const { data } = await getStripeSubscription({ ...payload });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
  async [STRIPE_GET_PRORATED](_, payload) {
    try {
      const { data } = await getStripeProratedAmount({ ...payload });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
  async [STRIPE_CANCEL_SUBSCRIPTION](_, payload) {
    try {
      const { data } = await cancelStripeSubscription({ ...payload });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
  async [STRIPE_PAYMENTMETHOD_CHECKOUT_TOPUP](_, payload) {
    try {
      const { data } = await topupStripePaymentMethod({ ...payload });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
  async [STRIPE_UPGRADE_SUBSCRIPTION](_, payload) {
    try {
      const { data } = await upgradeStripeSubscription({ ...payload });

      return { ...data };
    } catch (error) {
      return { isSuccess: false };
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
