export const ALLOWED_KEYS_IN_INDEX = [
  "createdAt",
  "uid",
  "parentId",
  "name",
  "description",
  "type",
  "position",
  "templateId",
  "templateCategory",
];

export const ALLOWED_PROJECT_SETTINGS_KEYS_IN_USERS = ["name", "logo"];
