// Wrapper
import Register from "@/modules/auth/views/Register";
import Login from "@/modules/auth/views/Login";
import LoginByLink from "@/modules/auth/views/LoginByLink";
import Logout from "@/modules/auth/views/Logout";
import Password from "@/modules/auth/views/Password";

export default [
  {
    path: "/login-by-link",
    name: "LoginByLink",
    title: "Login By Link",
    component: LoginByLink,
  },
  {
    path: "/login",
    name: "Login",
    title: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    title: "Logout",
    component: Logout,
  },
  {
    path: "/password",
    name: "password",
    title: "Forget Password",
    component: Password,
  },

  {
    path: "/register",
    name: "Register",
    title: "Register",
    component: Register,
  },
];
