<template>
  <div class="h-100">
    <div class="d-flex flex-column h-100" data-test="sidebar-project">
      <div class="mt-3 pl-3 pr-3">
        <h5 class="w-100 text-uppercase"></h5>
      </div>

      <div>
        <div class="p-2">
          <sidebar-item
            v-for="i in NAVIGATION"
            :key="i.id"
            :name="i.name"
            :icon="i.icon"
            :color="i.color"
            :is-active="i.to === routeName"
            class="mb-1"
            @click="goTo(i.to)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NAVIGATION } from "@/constants/members";

import SidebarItem from "./Item";

export default {
  name: "UserSidebar",
  components: { SidebarItem },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    taskId: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
  },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data() {
    return {
      NAVIGATION,
    };
  },

  methods: {
    goTo(routeName) {
      const { isMobile } = this;
      const { name: currentRouteName } = this.$route;

      if (!isMobile) {
        this.$router.push({ name: routeName });
        return;
      }

      if (currentRouteName === routeName) {
        this.$emit("update:is-showing-sidebar", false);
        return;
      }

      this.$router.push({ name: routeName });
    },
  },
};
</script>
