<template>
  <div>
    <user-sidebar />

    <div
      :style="`margin-top: 50px; margin-left: ${WIDTH_USER_SIDEBAR}px;`"
      class="h-100 bg-dark rounded-top-left-lg project-wrapper"
    >
      <b-row no-gutters class="almostfull-height">
        <b-col cols="12" md="3" class="overflow-y-scroll">
          <member-sidebar :route-name="$route.name" />
        </b-col>

        <b-col cols="12" md="9">
          <router-view />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import UserSidebar from "@/components/Sidebar/User/";
import MemberSidebar from "@/components/Sidebar/Member/";

export default {
  name: "LayoutDesktop",
  components: { BRow, BCol, UserSidebar, MemberSidebar },
  data: () => ({
    WIDTH_USER_SIDEBAR,
  }),
};
</script>

<style lang="scss" scoped>
.almostfull-height {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
</style>
