import { STYLES_MODE } from "@/constants/style";

export const PAYMENT_LEVEL = {
  UPGRADE: "upgrade",
  DOWNGRADE: "downgrade",
  CURRENT: "current",
  FIRST_TIME: "first-time",
};

export const STEPS = {
  SELECTION: "selection",
  PAYMENT: "payment",
  CONFIRMATION: "confirmation",
};

export const STEPS_STEPPER = [
  {
    key: STEPS.SELECTION,
    label: "Selection",
    translationKey: "members__selection",
  },
  {
    key: STEPS.PAYMENT,
    label: "Payment",
    translationKey: "members__payment",
  },
  {
    key: STEPS.CONFIRMATION,
    label: "Confirmation",
    translationKey: "members__confirmation",
  },
];

export const PAYMENT_METHODS = {
  CREDIT_CARD: "credit-card",
  PAYPAL: "paypal",
};
export const CURRENCIES_DEFAULT = "usd";

export const CURRENCIES_SYMBOLS = { usd: "$", eur: "€", gbp: "£" };

export const PAYMENT_LEVEL_LIST = ["upgrade", "downgrade", "first-time"];

export const CURRENCIES_LIST = ["usd", "eur", "gbp"];

export const STRIPE_OPTIONS_DEFAULT = {
  hidePostalCode: true,
  iconStyle: "solid",
};

export const STRIPE_STYLES = {
  [STYLES_MODE.LIGHT]: {
    base: {
      iconColor: "#32325d",
      color: "black",
      fontWeight: 500,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "black",
      },
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      iconColor: "#FC2D54",
      color: "#FC2D54",
    },
  },
  [STYLES_MODE.DARK]: {
    base: {
      iconColor: "#32325d",
      backgroundColor: "#0b0b0b",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#ffffff",
      },
      "::placeholder": {
        color: "#ffffff",
      },
    },
    invalid: {
      iconColor: "#FC2D54",
      color: "#FC2D54",
    },
  },
};

export const PLANS = {
  FREE: "free",
  PRO: "pro",
  ULTRA: "ultra",
  ENTERPRISE: "enterprise",
};

export const LIMITS = {
  USERS: "users",
  CREDITS: "credits",
  MESSAGES: "messages",
  STORAGE: "storage",
};

export const LIMITS_BY_PLANS = {
  [PLANS.FREE]: {
    [LIMITS.CREDITS]: 2000,
    [LIMITS.STORAGE]: 1000 * 1024 * 1024, // 1GB
  },
  [PLANS.PRO]: {
    [LIMITS.CREDITS]: 44000,
    [LIMITS.STORAGE]: 50000 * 1024 * 1024, // 50GB
  },
  [PLANS.ULTRA]: {
    [LIMITS.CREDITS]: 92000,
    [LIMITS.STORAGE]: 150000 * 1024 * 1024, // 150GB
  },
  [PLANS.ENTERPRISE]: {
    [LIMITS.CREDITS]: 332000,
    [LIMITS.STORAGE]: 450000 * 1024 * 1024, // 450GB
  },
};
