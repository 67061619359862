<template>
  <div class="layout-wrapper">
    <user-sidebar v-if="isShowingSidebar" :project-id="projectId" />

    <div
      v-if="isShowingSidebar"
      :style="`margin-left: ${WIDTH_USER_SIDEBAR}px;`"
      class="h-100 mt-4 bg-dark rounded-lg layout-almostfull-height"
    >
      <member-sidebar :is-showing-sidebar.sync="isShowingSidebar" class="h-100" style="width: 300px" />
    </div>

    <div v-if="!isShowingSidebar" class="mt-4 peek-container">
      <router-view class="layout-almostfull-height" />
    </div>
  </div>
</template>

<script>
import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import UserSidebar from "@/components/Sidebar/User/";
import MemberSidebar from "@/components/Sidebar/Member/";

export default {
  name: "LayoutMobile",
  components: { UserSidebar, MemberSidebar },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  data: () => ({
    isShowingSidebar: false,

    WIDTH_USER_SIDEBAR,
  }),
  mounted() {
    this.$bus.on("show-sidebar", () => (this.isShowingSidebar = true));
  },
  watch: {
    routeName() {
      this.isShowingSidebar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  height: 100vh;
}

.layout-almostfull-height {
  min-height: 100%;
  height: calc(100vh - 50px) !important;
}

.peek-container {
  margin-left: 5px;
  height: 100%;
  background-color: #000;
  border-radius: 8px;
}
</style>
