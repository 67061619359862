// App
export const INIT = "init";
export const TERMINATE = "terminate";

// Common
export const FETCH = "fetch";
export const CREATE = "create";
export const GET = "get";
export const UPDATE = "update";
export const REMOVE = "remove";
export const RESET = "reset";
export const LISTEN = "listen";
export const UNLISTEN = "unlisten";
export const COPY = "copy";
export const PUBLISH = "publish";
export const UNPUBLISH = "unpublish";
export const BILL = "bill";
export const INSERT = "insert";
export const MOVE = "move";
export const RESIZE = "resize";
export const WRITE = "write";
export const OUTLINE = "outline";
export const FILES = "files";
export const STYLE = "style";
export const YOUTUBE = "youtube";
export const RESTART = "restart";
export const SUBSCRIPTION = "subscription";
export const TEMPORARY = "TEMPORARY";
export const EXPORT = "export";
export const STOP = "stop";
export const ACTIVATE = "active";
export const CHECK = "check";
export const UNCHECK = "uncheck";
export const TRAIN = "train";
export const SHORTCUTS = "shortcuts";
export const INTENT = "intent";
export const CONFIRM = "confirm";
export const ADD = "add";
export const DEL = "del";

// Create history
export const CREATE_HISTORY = "createHistory";
export const INIT_HISTORY = "initHistory";
export const FETCH_HISTORY = "fetchHistory";
export const UNDO_HISTORY = "undoHistory";
export const REDO_HISTORY = "redoHistory";

export const GET_OUTLINE = "getOuline";
export const GET_TEMPORARY = "getTemporary";
export const MAGIC_WRITE = "magicWrite";
export const MAGIC_EXCERPT = "magicExcerpt";
export const MAGIC_SETUP = "magicSetup";
export const MAGIC_SUGGEST = "magicSuggest";
export const MAGIC_IMAGE = "magicImage";
export const MAGIC_OUTLINE = "magicOutline";
export const MAGIC_OUTLINE_V2 = "magicOutlineV2";
export const MAGIC_OUTLINE_V3 = "magicOutlineV3";
export const MAGIC_CHARACTERS = "magicCharacters";
export const QUILL_SUGGEST_OUTLINE = "quillSuggestOutline";
export const QUILL_SUGGEST_FILE = "quillSuggestFile";
export const QUILL_SUGGEST_IDEA = "quillSuggestIdea";
export const QUILL_SUGGEST_TITLE = "quillSuggestTitle";
export const QUILL_SUGGEST_COVER = "quillSuggestCover";
export const QUILL_SUGGEST_BOOK_FOR_AUTHOR = "quillSuggestBooksForAuthor";
export const QUILL_SUGGEST = "quillSuggest";
export const QUILL_SKETCH = "quillSketch";
export const QUILL_DRAW = "quillDraw";
export const QUILL_SUMMARIZE = "quillSummarize";
export const QUILL_EXTRACT = "quillExtract";
export const CREATE_FILES_OUTLINE = "createFilesOutline";

// Bulk
export const BULK_CREATE = "bulkCreate";
export const BULK_STOP = "bulkStop";

// Files
export const UPLOAD = "upload";
export const DOWNLOAD = "download";

// Auth
export const LOGIN = "login";
export const SEND_LOGIN_LINK = "sendLoginLink";
export const LOGIN_BY_LINK = "loginByLink";
export const LOGIN_BY_GOOGLE = "loginByGoogle";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_PASSWORD = "updatePassword";
export const UPDATE_EMAIL = "updateEmail";
export const AUTH_METHODS = "authMethods";

// Team
export const ADD_PROJECT_TO_USER = "addAppToUser";

// Profile
export const UPDATE_DARK_MODE = "updateDarkMode";

// Metadata
export const GET_SUBDOMAIN_METADATA = "getSubdomain";
export const GET_URL_METADATA = "getURLMetadata";

// Trackings
export const UPDATE_COOKIES = "updateCookies";
export const TRIGGER_EVENT = "triggerEvent";

// AI
export const RESPOND = "respond";
export const RESPOND_PUBLIC = "respondPublic";
export const GENERATE_CHAT = "generateChat";

// Checkout
export const STRIPE_PAYMENT_INTENT = "paymentIntentStripe";
export const STRIPE_SETUP_INTENT = "setupIntentStripe";
export const STRIPE_SUBSCRIPTION = "subscriptionStripe";
export const STRIPE_GET_SUBSCRIPTION = "getSubscriptionStripe";
export const STRIPE_UPGRADE_SUBSCRIPTION = "upgradeStripeSubscription";
export const STRIPE_CANCEL_SUBSCRIPTION = "cancelStripeSubscription";
export const STRIPE_GET_PRORATED = "getProratedStripe";
export const STRIPE_INIT_CHECKOUT = "initCheckoutStripe";
export const STRIPE_INIT_CHECKOUT_TOPUP = "initCheckoutStripeTopup";
export const STRIPE_PAYMENTMETHOD_CHECKOUT_TOPUP = "paymentMethodStripeTopup";
export const USE_STRIPE = "useStripe";
export const USE_COUPON = "useCoupon";

// Admin
export const GENERATE_COUPONS = "generateCoupons";
export const FETCH_COUPONS = "fetchCoupons";

// Locales
export const SET_LANGUAGE = "setLanguage";

// Projects
export const UPDATE_FOCUS = "updateFocus";
export const UPDATE_SETTINGS = "updateSettings";
export const UPDATE_EXTENSIONS = "updateExtensions";
export const GET_INDEX_ITEM = "getIndexItem";
export const PLAGIARISM_FILE = "plagiarism_file";
export const PLAGIARISM_PROJECT = "plagiarism_project";

// Tools
export const WRITE_BLOG_POST = "writeBlogPost";
export const TRANSCRIBE_MEDIA = "transcribeMedia";
export const CREATE_JOB = "createJob";

export const RENAME = "rename";
