export const ERRORS_CODES = {
  MAX_PROJECTS: "limits/maximum-projects",
  MAX_WORDS: "limits/maximum-words",
  MAX_WORDS_RESEARCHES: "researches/maximum-words",
  MAX_BATCHS_SIMULTANEOUS: "limits/maximum-simultaneous-batchs",
  MAX_AI_MESSAGE: "limits/maximum-messages",
  MAX_ATTEMPTS: "limits/maximum-attempt",
  MAX_TIME: "limits/maximum-time",
  MAX_STORAGE: "limits/maximum-storage",
  MAX_IMAGES: "limits/maximum-images",
  AUTHENTICATED: "user/authenticated",
  NOT_AUTHENTICATED: "user/not-authenticated",
  NOT_AUTHORIZED: "user/not-authorized",
  EMPTY_USER_EMAIL: "user/empty-email",
  EMPTY_MAGIC_WRITING_ACTION: "magic-writing/empty-action",
  INVALID_USER_EMAIL: "user/invalid-email",
  INVALID_USER_PASSWORD: "user/invalid-password",
  INVALID_USER_TAG: "user/invalid-tag",
  NOT_FOUND_USER_TEMPORARY: "user-temporary/not-found",
  FAIL_USER_CREATE: "user/fail-create",
  FAIL_USER_REMOVE: "user/fail-remove",
  FAIL_USER_ADD_TO_PROJECT: "user/fail-add-to-project",
  FAIL_USER_REMOVE_FROM_PROJECT: "user/fail-remove-from-project",
  FAIL_EMAIL_MAGIC_LINK: "emails/fail-magic-link",
  FAIL_EMAIL_WELCOME: "emails/fail-welcome",
  FAIL_PROJECT_CREATE: "projects/fail-create",
  FAIL_PROJECT_REMOVE: "projects/fail-remove",
  FAIL_TEMPORARY_PROJECT: "projects-temporary/fail",
  FAIL_STORAGE_CREATE: "storage/fail-create",
  FAIL_STORAGE_REMOVE: "storage/fail-remove",
  FAIL_WRITE_FILE: "files/fail-create",
  FAIL_BULK_FILES_CREATE: "files/fail-create-bulk",
  INVALID_MAGIC_WRITING_ACTION: "magic-writing/invalid-action",
  FAIL_MAGIC_WRITING: "magic-writing/fail",
  INVALID_MAGIC_WRITING: "magic-writing/fail-copyright-or-forbidden",
  FAIL_MAGIC_MAPPING: "magic-mapping/fail",
  FAIL_EXECUTE_CHAIN: "execute-chain/fail",
};

export const ERRORS_INPUTS = {
  required: "Field is required",
  email: "Must be a valid email",
  passwordNotStrong: "Password must be strong enough",
  passwordNotMatch: "Password must match",
  passwordMinLength: "Password length must be at least 6 characters",
};

export const ERRORS_API = {
  ["limits/maximum-projects"]: "The maximum amount of projects for a free user has been reached.",
  ["limits/maximum-words"]: "The maximum amount of Magic Words is reached.",
  ["limits/maximum-scans"]: "The maximum number of words that can be scanned has been reached.",
  ["limits/maximum-messages"]: "The maximum number of messages with Quill has been reached.",
  ["limits/batchs"]: "The maximum amount of books written at the same time is reached.",
  email: "Must be a valid email",
  passwordNotStrong: "Password must be strong enough",
  passwordNotMatch: "Password must match",
  [ERRORS_CODES.MAX_WORDS_RESEARCHES]: "The researches has too many words. Please upload a smaller file.",
  [ERRORS_CODES.INVALID_MAGIC_WRITING]: "The file could not be written. The content was either copyrighted or forbidden.",
  [ERRORS_CODES.MAX_IMAGES]: "The maximum amount of images has been reached.",
};

export const ERRORS_API_DEFAULT = "An unknown error has occurred. Please try again or contact us at hello@meetlouis.ai.";

export const ERRORS_AICHAT_DEFAULT = "An unknown error has occurred. Please refresh the page.";
