import Vue from "vue";

import {
  ref,
  query,
  get,
  set,
  update,
  remove,
  orderByKey,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  serverTimestamp as realtimeServerTimestamp,
} from "firebase/database";
import { collection, doc, addDoc, updateDoc, getDoc, deleteDoc, getDocs, serverTimestamp } from "firebase/firestore";

import { database, firestore } from "@/common/firebase";

import { ObjectToSortedArray, createModuleResetter, setTreeData } from "@/helpers/utils";

import { FETCH, GET, CREATE, UPDATE, REMOVE, LISTEN, CREATE_HISTORY } from "../../actions";
import { SET_KEY, SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";
import { ALLOWED_KEYS_IN_INDEX } from "@/constants/database";
import { DOCUMENTS_TYPES } from "@/constants/documents";

const getInitState = () => ({
  items: {},
});

const getters = {
  itemsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
  itemsFilteredByTemplateIdOptions: (state, getters) => ({ templateId }) => {
    const { itemsSorted } = getters;
    const itemsFiltered = itemsSorted.filter((i) => i.templateId === templateId);

    return itemsFiltered.map((item) => ({
      value: item.id,
      text: item.name,
      id: undefined,
      name: undefined,
    }));
  },

  tree: (state) => {
    const { items } = state;

    const children = setTreeData({ flatData: items, parentName: "parentId" });

    return children;
  },
  documentsById: (state) => ({ id }) => {
    return state.items[id];
  },
  documentsByParentId: (_, getters) => ({ parentId }) => {
    const { itemsSorted } = getters;
    if (!parentId) return itemsSorted.filter((i) => !i.parentId);
    return itemsSorted.filter((i) => i.parentId === parentId);
  },
  documentsByName: (_, getters) => ({ name }) => {
    const { itemsSorted } = getters;
    return itemsSorted.find((i) => i.name === name);
  },
};

const mutations = {
  [SET_KEY](state, { key, value }) {
    Vue.set(state, key, value);
  },

  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/documents`));
    const snapshot = await get(resultRef);

    const data = snapshot.val() || {};

    commit(SET_KEY, { key: "items", value: data });

    return { isSuccess: true, data };
  },
  async [GET](_, { projectId, id, withBlocks = false }) {
    try {
      const docRef = doc(firestore, `projects/${projectId}/documents/${id}`);
      const contentSnapshot = await getDoc(docRef);

      if (!contentSnapshot.exists()) {
        throw new Error("Document not found");
      }

      const docData = contentSnapshot.data();

      let blocksData = [];

      // If withBlock === true, then load the collection's blocks under docRef
      if (withBlocks) {
        const blocksCollectionRef = collection(docRef, "blocks");
        const blocksSnapshot = await getDocs(blocksCollectionRef);

        blocksData = blocksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      }

      return { id, ...docData, blocks: blocksData };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [CREATE]({ rootGetters }, { projectId, ...payload }) {
    const uid = rootGetters["auth/uid"];

    if (!payload.name) payload.name = "No Name";
    if (!payload.type) payload.type = DOCUMENTS_TYPES.FILE;

    try {
      let createdAt = serverTimestamp();
      const collectionRef = collection(firestore, `projects/${projectId}/documents`);
      const result = await addDoc(collectionRef, { uid, createdAt, ...payload });

      createdAt = realtimeServerTimestamp();
      await set(ref(database, `projects/${projectId}/index/documents/${result.id}`), { uid, createdAt, ...payload });

      return { isSuccess: true, id: result.id };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [UPDATE]({ dispatch, rootGetters }, { projectId, id, ...payload }) {
    const updatedBy = rootGetters["auth/uid"];
    const updatedAt = serverTimestamp();

    try {
      const docRef = doc(firestore, `projects/${projectId}/documents/${id}`);
      const docSnapshot = await getDoc(docRef);
      const beforeUpdate = docSnapshot.exists() ? docSnapshot.data() : null;

      await updateDoc(docRef, { updatedBy, updatedAt, ...payload });

      const updates = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (ALLOWED_KEYS_IN_INDEX.includes(key)) updates[`projects/${projectId}/index/documents/${id}/${key}`] = value;
      });

      update(ref(database), updates);

      if (beforeUpdate) {
        dispatch(
          `projectsDocumentsHistory/${CREATE_HISTORY}`,
          {
            projectId,
            docId: id,
            oldContent: beforeUpdate,
            newContent: { updatedBy, updatedAt, ...payload },
          },
          { root: true }
        );
      }

      return { isSuccess: true, id };
    } catch (error) {
      console.error("Failed to update document:", error);
      return { isSuccess: false, error };
    }
  },

  async [REMOVE](_, { projectId, id }) {
    try {
      const docRef = doc(firestore, `projects/${projectId}/documents/${id}`);
      await deleteDoc(docRef);

      await remove(ref(database, `projects/${projectId}/index/documents/${id}`));

      return { isSuccess: true, id };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ commit }, payload) {
    const { projectId } = payload;

    const resultRef = query(ref(database, `projects/${projectId}/index/documents`), orderByKey());

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
