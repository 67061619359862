import store from "@/store";
import { RESET_STORE } from "@/store/mutations";

export const resetStoreModules = (rootState) => {
  const modulesToAvoid = ["initialized", "permissions", "global", "auth", "admin"];

  Object.keys(rootState).forEach((module) => {
    if (modulesToAvoid.includes(module)) return;
    store.commit(`${module}/${RESET_STORE}`, null, { root: true });
  });
};
