import { BACKGROUNDS, ANIMATION_SOUNDS, MUSICS } from "@/constants/modeFocus";

export const addDarkMode = (value = "dark") => {
  const $body = document.querySelector("body");

  if (value === "dark") $body.classList.add("theme--dark");
  else $body.classList.remove("theme--dark");
};

const setBackgroundImage = (imageUrl = "") => {
  const $body = document.querySelector("body");

  if (imageUrl) {
    $body.style.backgroundImage = `url('${imageUrl}')`;
    $body.style.backgroundSize = `cover`;
    $body.style.backgroundPosition = `bottom`;
  } else {
    $body.style.backgroundImage = "";
  }
};

const addStars = (starCount = 100) => {
  const canvas = document.createElement("canvas");
  document.body.appendChild(canvas);
  canvas.style.position = "fixed";
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.zIndex = "-1";
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  class Star {
    constructor(canvas) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext("2d");
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.size = Math.random() * 3 + 1;
      this.opacity = Math.random() * 1;
    }

    draw() {
      this.ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      this.ctx.fill();
    }
  }

  class StarField {
    constructor(canvas, starCount) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext("2d");
      this.stars = [];

      for (let i = 0; i < starCount; i++) {
        const star = new Star(this.canvas);
        this.stars.push(star);
      }

      this.display();
    }

    display() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      for (const star of this.stars) {
        star.draw();
      }
    }
  }

  new StarField(canvas, starCount);
};

const addSnow = (starCount = 100) => {
  const canvas = document.createElement("canvas");
  document.body.appendChild(canvas);
  canvas.style.position = "fixed";
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.zIndex = "-1";
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  class Star {
    constructor(canvas) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext("2d");
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.size = Math.random() * 3 + 1;
      this.speed = Math.random() * 3 + 1;
      this.opacity = Math.random() * 1;
    }

    draw() {
      this.ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      this.ctx.fill();
    }

    move() {
      this.y += this.speed;
      if (this.y > this.canvas.height) {
        this.y = 0;
        this.x = Math.random() * this.canvas.width;
      }
    }
  }

  class StarField {
    constructor(canvas, starCount) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext("2d");
      this.stars = [];

      for (let i = 0; i < starCount; i++) {
        const star = new Star(this.canvas);
        this.stars.push(star);
      }

      this.animate();
    }

    animate() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      for (const star of this.stars) {
        star.draw();
        star.move();
      }

      requestAnimationFrame(() => this.animate());
    }
  }

  new StarField(canvas, starCount);
};

const addFog = () => {
  const fogLayers = [document.createElement("div"), document.createElement("div"), document.createElement("div")];

  fogLayers.forEach((fogLayer, index) => {
    fogLayer.id = `foglayer_0${index + 1}`;
    fogLayer.className = "fog";

    const image01 = document.createElement("div");
    image01.className = "image01";
    fogLayer.appendChild(image01);

    const image02 = document.createElement("div");
    image02.className = "image02";
    fogLayer.appendChild(image02);

    document.body.insertBefore(fogLayer, document.body.firstChild);
  });
};

const addRain = (numberOfDrops = 300) => {
  const rainWrapper = document.createElement("div");
  rainWrapper.className = "rain";

  for (let i = 0; i < numberOfDrops; i++) {
    const drop = document.createElement("div");
    drop.className = "drop";

    // Randomize the position and animation duration of each drop
    drop.style.left = Math.random() * window.innerWidth + "px";
    drop.style.animationDuration = Math.random() * 1 + 0.3 + "s";
    drop.style.animationDelay = Math.random() * 2 + "s";
    drop.style.opacity = Math.random();

    rainWrapper.appendChild(drop);
  }

  document.body.appendChild(rainWrapper);
};

const ANIMATIONS = {
  fog: addFog,
  stars: addStars,
  snow: addSnow,
  rain: addRain,
};

export const cleanAnimation = () => {
  const $canvas = document.querySelector("canvas");
  const $fogs = document.querySelectorAll(".fog");
  const $rain = document.querySelectorAll(".rain");

  if ($canvas) $canvas.remove();
  if ($fogs && $fogs.length)
    $fogs.forEach(($el) => {
      $el.remove();
    });

  if ($rain && $rain.length)
    $rain.forEach(($el) => {
      $el.remove();
    });
};

export const addAnimation = (value = null) => {
  if (value && ANIMATIONS[value]) ANIMATIONS[value]();
};

export const addSound = (value = null) => {
  const url = ANIMATION_SOUNDS[value];

  if (!url) return;

  const audio = document.createElement("audio");
  audio.src = url;
  audio.loop = true;
  audio.id = "backgroundSound";

  document.body.appendChild(audio);

  audio.play();
};

export const addMusic = (value = null) => {
  const url = MUSICS[value];

  if (!url) return;

  const audio = document.createElement("audio");
  audio.src = url;
  audio.loop = true;
  audio.id = "music";

  document.body.appendChild(audio);

  audio.play();
};

export const cleanSound = () => {
  const audio = document.getElementById("backgroundSound");

  if (audio) {
    audio.pause();
    document.body.removeChild(audio);
  }
};

export const setVolumeSound = (value) => {
  const audio = document.getElementById("backgroundSound");

  if (audio) {
    audio.muted = !value;
  }
};

export const cleanMusic = () => {
  const audio = document.getElementById("music");

  if (audio) {
    audio.pause();
    document.body.removeChild(audio);
  }
};

export const cleanBackground = () => {
  setBackgroundImage();
};

export const addBackground = (value = null) => {
  const { url = null, animation = null, sound } = BACKGROUNDS[value] || {};

  // 1. Clean
  cleanAnimation();
  cleanSound();

  // 2. Set background image
  setBackgroundImage(url);

  // 3. Add animation
  if (Array.isArray(animation))
    animation.forEach((animation) => {
      addAnimation(animation);
    });
  else if (animation) addAnimation(animation);

  // 4. Add sound
  addSound(sound);
};
