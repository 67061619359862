export const getWordsCount = (string) => {
  if (!string) return 0;

  const strippedString = string
    .replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "") // Remove script tags and content
    .replace(/<style[^>]*>([\S\s]*?)<\/style>/gim, "") // Remove style tags and content
    .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
    .replace(/&[\w]+;/g, " ") // Replace HTML entities with space
    .replace(/[^\w\s]|_/g, " ") // Replace non-words with space, except for normal spaces and word chars
    .replace(/\s+/g, " "); // Replace multiple spaces with single space

  const words = strippedString
    .trim()
    .split(" ")
    .filter(Boolean); // Split by single space

  return words.length;
};

export const toCapitalize = (string) => {
  if (typeof string !== "string") return;

  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const toCapitalizeAllWords = (sentence) => {
  return sentence
    .split(" ")
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(" ");
};

export const titleCapitalize = (string, except = []) => {
  let _except = [
    "and",
    "as",
    "but",
    "for",
    "if",
    "nor",
    "or",
    "so",
    "yet",
    "a",
    "an",
    "the",
    "at",
    "by",
    "for",
    "in",
    "of",
    "off",
    "on",
    "per",
    "to",
    "up",
    "via",
  ];
  _except = _except.concat(except);
  const regex = new RegExp(`(^|\\b(?!(${_except.join("|")})\\b))\\w+`, "g");
  return (string || "adfasdf").toLowerCase().replace(regex, (s) => s[0].toUpperCase() + s.slice(1));
};

export const getFirstSentences = (numberOfSentences = 1, text) => {
  const endOfSentence = text.search(/[.!?]/);
  if (endOfSentence === -1) return text;

  const firstSentences = text.slice(0, endOfSentence + numberOfSentences).trim();
  return `${firstSentences}...`;
};

export const getLastWords = (str, numberOfLastWords = 500) => {
  const words = str.split(/\s+/);
  if (words.length <= numberOfLastWords) return str;

  const lastWords = words.slice(numberOfLastWords);
  return lastWords.join(" ");
};

export const wrapTextInParagraphs = (string) => {
  const paragraphs = string.split("<br /><br />").map((paragraph) => {
    return `<p>${paragraph.trim()}</p>`;
  });

  return paragraphs.join("");
};

export const removeAllTheUnecessarySpace = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  }
  return obj;
};

export const replaceLineBreaksWithBr = (text) => {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

export const isValidYoutubeUrl = (url) => {
  return !!(extractYoutubeVideoId(url) || "")?.length;
};

export const extractYoutubeVideoId = (url) => {
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export function stripHtmlTags(str) {
  if (!str) return "";
  return str.replace(/<[^>]*>/g, "");
}

export function cropString(input, maxLength = 2000) {
  if (typeof input !== "string") {
    return input;
  }

  const words = input.split(/\s+/);

  if (words.length <= maxLength) {
    return input;
  }

  const truncatedWords = words.slice(-maxLength);
  return truncatedWords.join(" ");
}

export const removeDomElementFromString = (content, elementToRemoveClass) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  const autofillElements = doc.querySelectorAll(elementToRemoveClass);

  autofillElements.forEach((el) => el.remove());

  content = new XMLSerializer().serializeToString(doc);

  return content;
};

export function findClosestSlashWithoutChevron(str, position) {
  let left = position - 1;
  let right = position + 1;
  const length = str.length;

  while (left >= 0 || right < length) {
    // Check to the left
    if (left >= 0) {
      if (str[left] === "/" && str[left - 1] !== "<" && str[left + 1] !== ">") {
        return left;
      }
      left--;
    }

    // Check to the right
    if (right < length) {
      if (str[right] === "/" && str[right - 1] !== "<" && str[right + 1] !== ">") {
        return right;
      }
      right++;
    }
  }

  // Return null if no slash is found
  return null;
}
