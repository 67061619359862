<template>
  <div class="h-100">
    <div data-test="sidebar" class="sidebar h-100 d-flex flex-column pt-4 pb-4 rounded-lg float-left" :style="style">
      <div class="align-items-start w-100 flex-grow-1">
        <router-link v-if="isAuthenticated" :to="{ name: 'Projects' }">
          <img src="@/assets/character/smiling.png" width="80" height="80" class="d-block mr-auto ml-auto" />
        </router-link>

        <img
          v-else
          @click="$emit('open-register')"
          src="@/assets/character/smiling.png"
          width="80"
          height="80"
          class="d-block mr-auto ml-auto"
        />
        <div class="mt-4 mb-4" />

        <div v-if="isAuthenticated">
          <sidebar-project
            v-for="p in projectsSorted"
            :key="p.id"
            :id="p.id"
            :name="p.name"
            :image="p.logo"
            :is-active="p.id === projectId"
            :is-home="p.isHome"
            class="mb-3"
            @click="goToProject(p.id)"
          />

          <sidebar-icon icon="plus" @click="$router.push({ name: 'ProjectWizard' })" />
        </div>

        <sidebar-icon v-else icon="plus" @click="$emit('open-register')" />
      </div>

      <div v-if="isAuthenticated" class="mt-auto mb-4">
        <sidebar-icon
          v-if="isAdmin"
          icon="admin"
          class="mb-3"
          @click="$router.push({ name: 'Admin' })"
          :is-active="$route.path.includes('admin')"
        />

        <sidebar-icon
          icon="profil"
          @click="$router.push({ name: 'MemberProfile' })"
          :is-active="$route.path.includes('profile')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import SidebarProject from "./Project";
import SidebarIcon from "./Icon";

export default {
  name: "UserSidebar",
  components: { SidebarProject, SidebarIcon },
  props: {
    projectId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters("projects", ["projectsSorted"]),
    ...mapGetters("auth", ["isAuthenticated", "isAdmin"]),
    style() {
      return `width: ${WIDTH_USER_SIDEBAR}px`;
    },
  },
  data() {
    return {
      errorMessage: null,

      hasNewNotification: false,

      isShowingSidebar: false,
      isLoading: true,
      isSending: false,
    };
  },
  methods: {
    goToProject(projectId) {
      this.$router.push({ name: "Project", params: { projectId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 0;
  z-index: 10;

  .avatar {
    width: 40px;
    height: 40px;
  }
}
</style>
