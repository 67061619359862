<template>
  <audio id="notifications-player" style="display: none">
    <source src="https://cdn.purplewave.ai/sounds/notification.mp3" type="audio/mp3" />
  </audio>
</template>

<script>
export default {
  name: "NotificationsAlert",
};
</script>
