/* eslint-disable */
const eventIds = {
  Lead: "ws8uCI-K8YIDEJXFmOgC",
  Purchase: "rwVBCLKImYMDEJXFmOgC",
  InitiateCheckout: "fNeiCN-t8YIDEJXFmOgC",
};

export const triggerEventGoogleAds = ({ event, currency, value }) => {
  const $el = document.createElement("script");

  if (event === "Purchase")
    $el.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-755376789/${eventIds[event]}', 'value': ${value}, 'currency': '${currency}' });');`;
  else $el.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-755376789/${eventIds[event]}'});');`;

  $el.setAttribute(`data-facebook-ads`, true);

  document.head.appendChild($el);
};

export default [
  { tag: "script", attributes: { async: true, src: "https://www.googletagmanager.com/gtag/js?id=AW-755376789" } },
  {
    tag: "script",
    innerHTML:
      "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-755376789');",
  },
  {
    tag: "script",
    innerHTML: "gtag('event', 'conversion', {'send_to': 'AW-755376789/ws8uCI-K8YIDEJXFmOgC'});",
  },
];
