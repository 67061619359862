import { createTaskTranscription, trainAITeammate, createTaskJob } from "@/common/functions";

import { TRANSCRIBE_MEDIA, TRAIN, CREATE_JOB } from "../../actions";

const getInitState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  async [CREATE_JOB]({ rootGetters }, payload) {
    const languageFamily = rootGetters["auth/languageFamily"];

    try {
      const { data } = await createTaskJob({ languageFamily, ...payload });

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },

  async [TRANSCRIBE_MEDIA](_, payload) {
    try {
      const { data } = await createTaskTranscription(payload);

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },
  async [TRAIN](_, payload) {
    try {
      const { data } = await trainAITeammate(payload);

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  getters,
  mutations,
  actions,
};
