export const MIME_MICROSOFT = {
  "vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
};

export const MIME_MICROSOFT_LIST = ["vnd.openxmlformats-officedocument.wordprocessingml.document"];

export const IMAGE_TYPES = ["image/jpeg", "image/png"];

export const ACCEPTED_TYPES = ["image/jpeg", "image/png", "application/pdf"];

export const ACCEPTED_LANGUAGE_CODE = [
  "af-ZA",
  "ar-AE",
  "ar-SA",
  "zh-CN",
  "zh-TW",
  "da-DK",
  "nl-NL",
  "en-AU",
  "en-GB",
  "en-IN",
  "en-IE",
  "en-NZ",
  "en-AB",
  "en-ZA",
  "en-US",
  "en-WL",
  "fr-FR",
  "fr-CA",
  "fa-IR",
  "de-DE",
  "de-CH",
  "he-IL",
  "hi-IN",
  "id-ID",
  "it-IT",
  "ja-JP",
  "ko-KR",
  "ms-MY",
  "pt-PT",
  "pt-BR",
  "ru-RU",
  "es-ES",
  "es-US",
  "sv-SE",
  "ta-IN",
  "te-IN",
  "th-TH",
  "tr-TR",
  "vi-VN",
];

export const LANGUAGE_CODES_OPTIONS = [
  { text: "Afrikaans", value: "af-ZA" },
  { text: "Arabic, Gulf", value: "ar-AE" },
  { text: "Arabic, Modern Standard", value: "ar-SA" },
  { text: "Chinese, Simplified", value: "zh-CN" },
  { text: "Chinese, Traditional", value: "zh-TW" },
  { text: "Danish", value: "da-DK" },
  { text: "Dutch", value: "nl-NL" },
  { text: "English, Australian", value: "en-AU" },
  { text: "English, British", value: "en-GB" },
  { text: "English, Indian", value: "en-IN" },
  { text: "English, Irish", value: "en-IE" },
  { text: "English, New Zealand", value: "en-NZ" },
  { text: "English, Scottish", value: "en-AB" },
  { text: "English, South African", value: "en-ZA" },
  { text: "English, US", value: "en-US" },
  { text: "English, Welsh", value: "en-WL" },
  { text: "French", value: "fr-FR" },
  { text: "French, Canadian", value: "fr-CA" },
  { text: "Farsi", value: "fa-IR" },
  { text: "German", value: "de-DE" },
  { text: "German, Swiss", value: "de-CH" },
  { text: "Hebrew", value: "he-IL" },
  { text: "Hindi, Indian", value: "hi-IN" },
  { text: "Indonesian", value: "id-ID" },
  { text: "Italian", value: "it-IT" },
  { text: "Japanese", value: "ja-JP" },
  { text: "Korean", value: "ko-KR" },
  { text: "Malay", value: "ms-MY" },
  { text: "Portuguese", value: "pt-PT" },
  { text: "Portuguese, Brazilian", value: "pt-BR" },
  { text: "Russian", value: "ru-RU" },
  { text: "Spanish", value: "es-ES" },
  { text: "Spanish, US", value: "es-US" },
  { text: "Swedish", value: "sv-SE" },
  { text: "Tamil", value: "ta-IN" },
  { text: "Telugu", value: "te-IN" },
  { text: "Thai", value: "th-TH" },
  { text: "Turkish", value: "tr-TR" },
  { text: "Vietnamese", value: "vi-VN" },
];
