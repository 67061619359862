// Wrapper
import Projects from "../views/Projects/";
import Project from "../views/Projects/Project/";

const ProjectWizard = () => import("../views/Projects/Wizard/");
const ProjectDashboard = () => import("../views/Projects/Project/Dashboard");
const ProjectSettings = () => import("../views/Projects/Project/Settings/");
const ProjectStorage = () => import("../views/Projects/Project/Storage");
const ProjectTeammateWizard = () => import("../views/Projects/Project/Teammate/Wizard");
const ProjectTeammate = () => import("../views/Projects/Project/Teammate/");
const ProjectTeammates = () => import("../views/Projects/Project/Teammates/");
const ProjectDocumentWizard = () => import("../views/Projects/Project/Document/Wizard");
const ProjectDocument = () => import("../views/Projects/Project/Document/");
const ProjectTaskWizard = () => import("../views/Projects/Project/Task/Wizard");
const ProjectArchive = () => import("../views/Projects/Project/Task/Archive");
const ProjectTask = () => import("../views/Projects/Project/Task/");

const ProjectPublic = () => import("../views/Public/");

export default [
  {
    path: "/m/projects",
    name: "Projects",
    component: Projects,
    meta: { auth: true },
    children: [
      {
        path: "wizard",
        name: "ProjectWizard",
        component: ProjectWizard,
      },
      {
        path: ":projectId",
        name: "Project",
        component: Project,
        redirect: { name: "ProjectTaskWizard" },
        props: true,
        children: [
          {
            path: "dashboard",
            name: "ProjectDashboard",
            component: ProjectDashboard,
          },
          {
            path: "settings",
            name: "ProjectSettings",
            component: ProjectSettings,
          },
          {
            path: "storage",
            name: "ProjectStorage",
            component: ProjectStorage,
          },
          {
            path: "teammates/wizard",
            name: "ProjectTeammateWizard",
            component: ProjectTeammateWizard,
          },
          {
            path: "teammates/:teammateId",
            name: "ProjectTeammate",
            component: ProjectTeammate,
          },
          {
            path: "teammates",
            name: "ProjectTeammates",
            component: ProjectTeammates,
          },
          {
            path: "documents/wizard",
            name: "ProjectDocumentWizard",
            component: ProjectDocumentWizard,
          },
          {
            path: "documents/:documentId",
            name: "ProjectDocument",
            component: ProjectDocument,
          },
          {
            path: "tasks/wizard",
            name: "ProjectTaskWizard",
            component: ProjectTaskWizard,
          },
          {
            path: "tasks/archive",
            name: "ProjectArchive",
            component: ProjectArchive,
          },
          {
            path: "tasks/:taskId",
            name: "ProjectTask",
            component: ProjectTask,
          },
        ],
      },
    ],
  },
  {
    path: "/try",
    name: "ProjectPublic",
    component: ProjectPublic,
  },
];
