<template>
  <div>
    <layout-desktop v-if="!isMobile" />
    <layout-mobile v-else />
  </div>
</template>

<script>
import LayoutDesktop from "./LayoutDesktop";
import LayoutMobile from "./LayoutMobile";

export default {
  name: "Projects",
  components: { LayoutDesktop, LayoutMobile },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
};
</script>
