<template>
  <b-container fluid class="app-container pt-4 pb-150" data-cy="dashboard">
    <form action="" @submit.prevent.stop>
      <fieldset>
        <label for="name">Migration name:</label>
        <div>
          <input name="name" type="text" v-model="migrationName" />
        </div>
      </fieldset>

      <br />
      <fieldset>
        <label for="payload">Payload:</label>
        <div>
          <input name="payload" type="text" v-model="payload" :disabled="loading" />
        </div>
      </fieldset>

      <br />
      <input type="submit" value="Run migration" @click.stop="runMigration" />
    </form>

    <hr />
    <p>Migration result:</p>
    <p v-if="loading">Loading...</p>
    <p v-html="result"></p>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

import { BContainer } from "bootstrap-vue";
import { runDbMigration } from "@/common/functions";

export default {
  name: "Projects",
  components: { BContainer },
  computed: {
    ...mapGetters("projects", ["projectsSorted"]),
  },
  data: () => ({
    migrationName: "",
    payload: "",
    result: "",
    loading: false,
  }),
  methods: {
    async runMigration() {
      try {
        this.result = "";
        this.loading = true;
        const { isSuccess, result } = await runDbMigration({ migration: this.migrationName, payload: this.payload }).then(
          (res) => res.data
        );
        if (isSuccess) {
          this.result = "Result: " + (result || "No result");
        } else {
          this.result = "Failed.";
        }
      } catch (e) {
        console.log(e);
        this.result = e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
}
</style>
