// Wrapper
import Member from "@/modules/members/views/";

// Modules
const MemberProfile = () => import("../views/Profile/");
const MemberBilling = () => import("../views/Billing/");

export default [
  {
    path: "/m",
    name: "welcome",
    title: "Welcome",
    component: Member,
    redirect: { name: "Login" },
    children: [
      {
        path: "profile",
        name: "MemberProfile",
        title: "MemberProfile",
        component: MemberProfile,
        meta: { auth: true },
      },
      {
        path: "billing",
        name: "MemberBilling",
        title: "MemberBilling",
        component: MemberBilling,
        meta: { auth: true },
      },
    ],
  },
];
