import { gsap } from "gsap";

export const createFireworkSparks = (event) => {
    const sparksCount = 50;
    const sparks = [];

    const { clientX: x, clientY: y } = event;

    for (let i = 0; i < sparksCount; i++) {
      let spark = document.createElement('div');
      spark.style.position = 'absolute';
      spark.style.left = `${x}px`;
      spark.style.top = `${y}px`;
      spark.style.width = '2px';
      spark.style.height = '2px';
      spark.style.borderRadius = '50%';
      spark.style.backgroundColor = ['#ff0', '#f00', '#0f0', '#00f'][Math.floor(Math.random() * 4)];
      spark.style.opacity = 1;
      spark.style.zIndex = 9999;

      document.body.appendChild(spark);
      sparks.push(spark);
    }

    sparks.forEach(spark => {
      const xEnd = 100 * (Math.random() - 0.5);
      const yEnd = 100 * (Math.random() - 0.5);

      gsap.to(spark, {
        opacity: 0,
        x: `+=${xEnd}`,
        y: `+=${yEnd}`,
        duration: 0.5 + Math.random(),
        ease: 'power2.out',
        onComplete: () => {
          spark.remove();
        }
      });
    });
  }