<template>
  <div :class="{ 'shake-that': isShaking }" class="text-right text-secondary">
    <em v-if="message">{{ message | __t("input-error__" + toSlug(message)) }}</em>
    <em v-else><slot></slot></em>
  </div>
</template>

<script>
import { ERRORS_INPUTS } from "@/constants/errors";
import { toSlug } from "@/helpers/utils";

export default {
  name: "ErrorsInput",
  props: {
    errorCode: {
      type: String,
      default: null,
    },
    hasSubmitError: {
      type: Boolean,
    },
  },
  computed: {
    message() {
      const { errorCode } = this;
      return ERRORS_INPUTS[errorCode];
    },
  },
  data: () => ({
    isShaking: false,
  }),
  watch: {
    hasSubmitError() {
      const { hasSubmitError } = this;
      if (hasSubmitError) this.isShaking = true;
    },
    isShaking() {
      const { isShaking } = this;

      if (isShaking)
        setTimeout(() => {
          this.isShaking = false;
          this.$emit("update:has-submit-error", false);
        }, 600);
    },
  },
  methods: {
    toSlug,
  },
};
</script>
