<template>
  <div>
    <spinner v-if="isLoading"></spinner>

    <div v-else>
      <user-sidebar :project-id="null" />

      <div :style="`margin-top: 50px; margin-left: ${WIDTH_USER_SIDEBAR}px;`" class="mr-4 bg-dark rounded-lg project-wrapper">
        <div class="h-100 overflow-y-scroll">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import Spinner from "@/components/Spinner/";
import UserSidebar from "@/components/Sidebar/User/";

export default {
  name: "Projects",
  components: { Spinner, UserSidebar },
  data: () => ({
    isLoading: false,

    WIDTH_USER_SIDEBAR,
  }),
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "@/assets/scss/_settings";

.project-wrapper {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    height: calc(100vh - 100px);
  }
}
</style>
