<template>
  <div class="vh-100 overflow-y-scroll">
    <div class="d-block text-center pt-3 pb-3">
      <b-img :src="require('@/assets/character/smiling.png')" rounded width="80" height="80" />
    </div>

    <div class="bg-dark m-auto rounded-lg p-4 mt-4" style="max-width: 600px;">
      <form-reset-password v-if="!currentStep" @success="currentStep = AUTH_STEPS.SUCCESS" />

      <div v-if="currentStep === AUTH_STEPS.SUCCESS">
        <h3 class="mt-50 text-center text-uppercase text-white">
          {{ "Reset password" | __t("auth__password__reset-password") }}
        </h3>

        <div class="mb-50 text-center">
          <p>{{ "Success!" | __t("status____success") }}</p>
          <p class="mb-0">
            {{
              "A link to reset your password has been sent." | __t("auth__password__a-link-to-reset-your-password-has-been-sent")
            }}
            {{ "Please check your mailbox." | __t("auth__password__please-check-your-mailbox") }}
          </p>
        </div>

        <div class="text-center">
          <b-button :to="{ name: 'Login' }" variant="outline-success" class="text-uppercase">
            {{ "Continue To Login" | __t("auth__continue-to-login") }}
          </b-button>
        </div>
      </div>
    </div>

    <layout-spacer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BImg, BButton } from "bootstrap-vue";

import { AUTH_STEPS } from "@/constants/auth";

import LayoutSpacer from "@/components/Layouts/Spacer";

import FormResetPassword from "@/components/Forms/ResetPassword.vue";

export default {
  name: "ResetPassword",
  components: { BImg, BButton, FormResetPassword, LayoutSpacer },
  data: () => ({
    currentStep: null,

    AUTH_STEPS,
  }),
  beforeRouteEnter(_, __, next) {
    next((vm) => {
      if (vm.isAuthenticated) next({ name: "Projects" });
    });
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  methods: {},
};
</script>
