export const TEAMMATES = {
  COPYWRITER: "copywriter",
  ILLUSTRATOR: "illustrator",
  SUPPORT: "support",
};

export const TEAMMATES_OPTIONS_COMMAND = [
  {
    id: TEAMMATES.COPYWRITER,
    name: "Copywriter",
    description: "Create an AI teammate specialized in copywriting. Train it to write in a specific style.",
    icon: "",
    category: "",
  },
  {
    id: TEAMMATES.ILLUSTRATOR,
    name: "Illustrator",
    description: "Create an AI teammate specialized in illustration. Train it to create images in a specific style.",
    icon: "",
    category: "",
  },
  {
    id: TEAMMATES.SUPPORT,
    name: "Support",
    description:
      "Create an AI teammate specialized in customer support. Train it with your own knowledge database to answer specific questions.",
    icon: "",
    category: "",
  },
];

export const TEAMMATES_DESCRIPTION = {
  [TEAMMATES.COPYWRITER]: `<p>Start training your AI copywriter by providing up to seven of your written pieces - from blog entries to emails. This
helps the AI to learn your style and replicate your voice across various formats.
</p>

<p>
  Once you have enough assets, click on "Create Teammate".<br />
  <em>One asset is a minimum. You can always add more later.</em>
</p>`,
  [TEAMMATES.ILLUSTRATOR]: `<p>Start training your AI designer by providing up to seven of images in a similar style. This
helps the AI to learn your style and replicate it across various formats.
</p>

<p>
  Once you have enough assets, click on "Create Teammate".<br />
  <em>One asset is a minimum. You can always add more later.</em>
</p>`,
  [TEAMMATES.SUPPORT]: `<p>Start training your AI support by providing multiple resources about your business, services,
products to provide accurate and helpful answers to customer inquiries.<br />
This ensures your AI can deliver precise and useful responses to customer queries, drawing directly from your provided materials.</p>

<p>Please note: Ensure the safety and privacy of your data by refraining from uploading any confidential or sensitive information.
<br />
Once you've gathered your essential assets—remember, a single asset is all it takes to begin—you can set up your AI teammate. Don't worry if you don't have everything at once; you're free to add more resources anytime to keep improving your AI's performance.
</p>

<p>
  Once you have enough assets, click on "Create Teammate".<br />
  <em>One asset is a minimum. You can always add more later.</em>
</p>`,
};

export const WIZARD = {
  ROLE: "role",
  NAME: "name",
  EXAMPLES: "examples",
};
