import { ref } from "vue";
import { BAlert } from "bootstrap-vue";

export default {
  install: (Vue) => {
    const data = ref([]);
    const current = ref(null);

    addStyles();

    Vue.prototype.$notify = (message = "", options = {}, duration = 2000) => {
      const _options = Object.assign({ variant: "secondary", show: true }, options);
      data.value = [...data.value, { id: data.length, message, options: _options, duration }];
      show();
    };

    const show = () => {
      if (current.value) return;
      if (!data.value.length) return;

      current.value = data.value.shift();

      const component = new BAlert({
        propsData: current.value?.options,
      });
      component.$slots.default = current.value?.message || "Empty message";
      const componentEl = component.$mount().$el;
      componentEl.classList.add("m-0");
      componentEl.classList.add("text-center");
      componentEl.classList.add("py-1");
      componentEl.classList.add("px-2");

      const seconds = (current.value?.duration || 4000) / 1000;

      const el = document.createElement("div");
      el.style.position = "fixed";
      el.style.bottom = "10px";
      el.style.left = "50%";
      el.style.maxWidth = "80vw";
      el.style.minWidth = "100px";
      el.style.transform = "translateX(-50%) translateY(0%)";
      el.style.zIndex = "500";
      el.style.animation = `notify-animation ${seconds}s 2`;
      el.appendChild(componentEl);

      document.body.appendChild(el);

      setTimeout(() => {
        el.remove();
        current.value = null;
        show();
      }, current.value?.duration || 2000);
    };
  },
};

function addStyles() {
  try {
    let styles = document.createElement("style");
    styles.type = "text/css";
    styles.innerHTML = `
      @keyframes notify-animation {
        0%   {transform:translateX(-50%) translateY(170%)}
        15%   {transform:translateX(-50%) translateY(0%)}
        85%   {transform:translateX(-50%) translateY(0%)}
        100%   {transform:translateX(-50%) translateY(170%)}
      }
    `;
    document.querySelector("head").appendChild(styles);
  } catch (e) {
    console.log("Notify styles error", e);
  }
}
