<template>
  <div class="layout-wrapper">
    <user-sidebar v-if="isShowingSidebar" :project-id="projectId" />

    <div
      v-if="isShowingSidebar"
      :style="`margin-left: ${WIDTH_USER_SIDEBAR}px;`"
      class="h-100 mt-4 bg-dark rounded-lg layout-almostfull-height"
    >
      <project-sidebar
        :is-showing-sidebar.sync="isShowingSidebar"
        :project-id="projectId"
        :teammate-id="teammateId"
        :task-id="taskId"
        :route-name="routeName"
        class="h-100"
        style="width: 300px"
      />
    </div>

    <div v-if="!isShowingSidebar" class="mt-4 peek-container">
      <b-button
        v-if="routeName !== 'ProjectTask' && routeName !== 'ProjectTaskWizard'"
        @click="isShowingSidebar = true"
        variant="transparent"
      >
        <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
      </b-button>

      <router-view class="layout-almostfull-height" />
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import UserSidebar from "@/components/Sidebar/User/";
import ProjectSidebar from "@/components/Sidebar/Project/";

export default {
  name: "LayoutMobile",
  components: { BButton, UserSidebar, ProjectSidebar },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    teammateId() {
      return this.$route.params.teammateId;
    },
    taskId() {
      return this.$route.params.taskId;
    },

    routeName() {
      return this.$route.name;
    },
  },
  data: () => ({
    isShowingSidebar: false,

    WIDTH_USER_SIDEBAR,
  }),
  mounted() {
    this.$bus.on("show-sidebar", () => (this.isShowingSidebar = true));
  },
  watch: {
    teammateId() {
      this.isShowingSidebar = false;
    },
    taskId() {
      this.isShowingSidebar = false;
    },
    routeName() {
      this.isShowingSidebar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  height: 100vh;
}

.layout-almostfull-height {
  min-height: 100%;
  height: calc(100vh - 50px) !important;
}

.peek-container {
  margin-left: 5px;
  height: 100%;
  background-color: #000;
  border-radius: 8px;
}
</style>
