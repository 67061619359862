import Vue from "vue";

import { SELECTIONS, SELECTIONS_DEFAULT } from "@/constants/quill";

import { addDarkMode } from "@/helpers/customize";
import { createModuleResetter } from "@/helpers/utils";

import { INIT, UPDATE } from "../../actions";
import { SET_KEY, RESET_STORE } from "../../mutations";

const getInitState = () => ({});

const getters = {};

const mutations = {
  [SET_KEY](state, { key, value }) {
    Vue.set(state, key, value);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  [INIT]({ commit }) {
    Object.values(SELECTIONS).forEach((key) => {
      let storedItem = localStorage.getItem(key);
      if (storedItem === "null") storedItem = null;
      if (storedItem === "false") storedItem = false;
      if (storedItem === "true") storedItem = true;

      const value = storedItem || SELECTIONS_DEFAULT[key];

      commit(SET_KEY, { key, value });
      if (key === SELECTIONS.DARK_MODE) addDarkMode(value);
    });
  },

  async [UPDATE]({ commit }, { key, value }) {
    localStorage.setItem(key, value);

    commit(SET_KEY, { key, value });
    if (key === SELECTIONS.DARK_MODE) addDarkMode(value);
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
