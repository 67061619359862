<template>
  <b-modal
    v-model="isShowingModal"
    modal-class="modal-cookies"
    :size="size"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    body-bg-variant="dark"
    @hidden="isShowingModal = false"
  >
    <div v-if="isStep === 'welcome'">
      <b-row no-gutters>
        <b-col class="text-center">
          <img src="@/assets/icons/cookie.svg" width="100" height="70" />
          <h5 class="mt-4 text-uppercase">{{ "Cookies" | __t }}</h5>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <p>
            We use cookies to provide you with personalized advertising, to provide functionalities relating to social media, and
            analyze our traffic.
          </p>
        </b-col>
      </b-row>

      <div class="d-small-screen">
        <b-row class="mt-4 mb-4 text-center">
          <b-col>
            <b-button @click="isStep = 'personnalize'" variant="outline-primary" class="bg-transparent text-uppercase" pill>{{
              "Personalize" | __t
            }}</b-button>
          </b-col>

          <b-col class="text-center">
            <b-button @click="triggerRefuseAll" variant="primary" class="w-100 text-uppercase font-weight-bold" pill>
              {{ "Refuse All" | __t }}
            </b-button>
          </b-col>

          <b-col class="text-center">
            <b-button @click="triggerAcceptAll" variant="primary" class="w-100 text-uppercase font-weight-bold" pill>
              {{ "Accept All" | __t }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-row no-gutters class="mt-4 mb-4 d-big-screen">
        <b-col>
          <b-button @click="isStep = 'personnalize'" variant="outline-primary" class="bg-transparent text-capitalize" pill>{{
            "Personalize" | __t
          }}</b-button>
        </b-col>

        <b-col class="text-center">
          <b-button @click="triggerRefuseAll" data-cy="declineAll" variant="primary" class="text-capitalize" pill>{{
            "Refuse All" | __t
          }}</b-button>
        </b-col>

        <b-col class="text-right">
          <b-button @click="triggerAcceptAll" data-cy="acceptAll" variant="primary" class="text-capitalize" pill>{{
            "Accept All" | __t
          }}</b-button>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col class="text-center">
          <div class="spacer"></div>
          <router-link :to="{ name: 'Cookie Policy' }">
            <small
              ><em>{{ "Consult the cookies policy" | __t }}</em></small
            >
          </router-link>
        </b-col>
      </b-row>
    </div>

    <div v-if="isStep === 'personnalize'">
      <b-row no-gutters>
        <b-col class="text-center">
          <!-- <img src="@/assets/images/cookies.png" width="100" height="70" /> -->
          <h5 class="mt-4 text-uppercase">{{ "Cookie Settings" | __t }}</h5>
          <div class="spacer"></div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <div v-for="(c, key) in cookies" :key="key">
            <b-form-checkbox v-model="c.status" :disabled="c.isDisabled" switch class="float-right"></b-form-checkbox>
            <h6>{{ c.name | __t }}</h6>
            <div class="spacer"></div>

            <p>{{ c.description | __t }}</p>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-4">
        <b-col class="text-right">
          <b-button @click="triggerUpdateCookies" variant="primary" pill>{{ "Accept" | __t }}</b-button>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col class="text-center">
          <div class="spacer"></div>
          <router-link :to="{ name: 'Cookie Policy' }">
            <small
              ><em>{{ "Consult the cookies policy" | __t }}</em></small
            >
          </router-link>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { UPDATE_COOKIES } from "@/store/actions";

import { BModal, BRow, BCol, BButton } from "bootstrap-vue";

import { updateTrackers } from "@/helpers/trackers";

import googleAnalytics from "@/assets/trackers/googleAnalytics";
import googleAds from "@/assets/trackers/googleAds";
import facebookAds from "@/assets/trackers/facebookAds";

import { COOKIES } from "@/constants/legal";

export default {
  name: "CookiesModal",
  components: { BModal, BRow, BCol, BButton },
  computed: {
    ...mapState({
      performance: (state) => state.trackings.performance,
      ads: (state) => state.trackings.ads,
    }),
    size() {
      const { isStep } = this;
      if (isStep === "personnalize") return "lg";
      return "md";
    },
  },
  data() {
    return {
      cookies: COOKIES,

      isStep: "welcome",
      isShowingModal: false,
    };
  },
  watch: {
    performance: {
      handler() {
        const { performance } = this;
        const trackers = [{ key: "google-analytics", elements: googleAnalytics }];
        const action = performance ? "add" : "remove";

        trackers.forEach(({ key, elements }) => updateTrackers({ key, elements, action }));
      },
      deep: true,
    },
    ads: {
      handler() {
        const { ads } = this;
        const trackers = [
          { key: "google-ads", elements: googleAds },
          { key: "facebook-ads", elements: facebookAds },
        ];
        const action = ads ? "add" : "remove";

        trackers.forEach(({ key, elements }) => updateTrackers({ key, elements, action }));
      },
      deep: true,
    },
  },
  mounted() {
    const storedCookies = localStorage.getItem("cookies");

    if (storedCookies) this.readCookies(storedCookies);
    else this.isShowingModal = true;
  },
  methods: {
    ...mapActions("trackings", { updateCookies: UPDATE_COOKIES }),
    triggerUpdateCookies() {
      this.isShowingModal = false;

      const { cookies } = this;

      this.updateCookies({ cookies });
      this.storeCookies();
    },
    triggerAcceptAll() {
      const { cookies } = this;

      cookies.forEach((c) => (c.status = true));
      this.triggerUpdateCookies({ cookies });
    },
    triggerRefuseAll() {
      const { cookies } = this;
      cookies.forEach((c) => {
        if (!c.isDisabled) c.status = false;
      });

      this.triggerUpdateCookies({ cookies });
    },

    storeCookies() {
      const { cookies } = this;
      const storedCookies = {};

      cookies.forEach(({ key, status }) => (storedCookies[key] = status));

      localStorage.setItem("cookies", JSON.stringify(storedCookies));
    },
    readCookies(storedCookies) {
      Object.entries(JSON.parse(storedCookies)).forEach(([key, value]) => {
        const i = this.cookies.findIndex((c) => c.key === key);
        if (i > -1) this.cookies[i].status = value;
      });

      this.triggerUpdateCookies();
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 769px) {
  .modal-cookies {
    .modal-dialog {
      width: 100%;
      position: fixed;
      bottom: 30px;
      margin: 0;
    }

    .d-big-screen {
      display: none;
    }
  }
}

@media (min-width: 769px) {
  .modal-cookies {
    .modal-dialog {
      width: 100%;
      position: fixed;
      bottom: 30px;
      left: 30px;
      margin: 0;
    }

    .modal-md .modal-content {
      width: 575px !important;
    }

    .d-small-screen {
      display: none;
    }
  }
}
</style>
