import Vue from "vue";
import { collection, query, where, limit, orderBy, startAfter, getDocs, onSnapshot } from "firebase/firestore";

import { firestore } from "@/common/firebase";

import { objectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, LISTEN } from "../../actions";
import { SET_KEY, SET_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
  isAllLoaded: false,
  lastVisible: null,
});

const getters = {
  itemsSorted: (state) => {
    return objectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET_KEY](state, { key, value }) {
    state[key] = value;
  },

  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit, rootGetters, state }, { pageSize = 10, nextPage = false } = {}) {
    const uid = rootGetters["auth/uid"];
    let logsQuery = query(
      collection(firestore, "logs"),
      where("uid", "==", uid),
      where("parentLogId", "==", "root"),
      orderBy("createdAt", "desc"),
      limit(pageSize)
    );

    // Modify query for pagination
    if (nextPage && state.lastVisible) {
      logsQuery = query(logsQuery, startAfter(state.lastVisible));
    }

    try {
      const querySnapshot = await getDocs(logsQuery);
      if (!querySnapshot.empty) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        commit(SET_KEY, { key: "lastVisible", value: lastVisible });

        let data = {};
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const value = doc.data();

          data[id] = value;
          commit(SET_ITEM, { id, ...value });
        });

        return { isSuccess: true, data };
      } else {
        commit(SET_KEY, { key: "isAllLoaded", value: true });
      }
    } catch (error) {
      console.error("Error fetching logs with pagination:", error);
      return { isSuccess: false, error };
    }
  },

  [LISTEN]({ commit, rootGetters }) {
    const uid = rootGetters["auth/uid"];
    const logsQuery = query(collection(firestore, "logs"), where("uid", "==", uid));

    onSnapshot(
      logsQuery,
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const doc = change.doc;
            const id = doc.id;
            const value = doc.data();

            commit(SET_ITEM, { id, ...value });
          }
        });
      },
      () => {}
    );
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
