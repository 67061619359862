<template>
  <div>
    <b-button pill data-cy="link-register" variant="link" @click="$emit('previous')" class="p-1 pr-2">
      <small><b-icon-chevron-left font-scale="0.8" style="margin-bottom: 3px" /> {{ "Back" | __t("button__back") }}</small>
    </b-button>

    <login-by-link-form
      @success="isLoginByLinkSuccess = true"
      :title="`Register With Magic Link` | __t('forms__register__magic-link-title')"
      :isShowingConfirmation="true"
    />
  </div>
</template>

<script>
import { BButton, BIconChevronLeft } from "bootstrap-vue";

import LoginByLinkForm from "@/components/Forms/LoginByLink";

export default {
  name: "EmailMagiclink",
  components: {
    BButton,
    BIconChevronLeft,
    LoginByLinkForm,
  },
  data() {
    return {
      isLoginByLink: true,
      isLoginByLinkSuccess: false,
    };
  },
};
</script>
