import Vue from "vue";
import {
  ref,
  query,
  get,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  orderByKey,
  endBefore,
  limitToLast,
} from "firebase/database";

import { database } from "@/common/firebase";

import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, GET, LISTEN } from "../../actions";
import { SET_ITEM_NESTED, DEL_ITEM_NESTED, RESET_STORE } from "../../mutations";

const LIMIT_PAGE_MESSAGES = 10;

const getInitState = () => ({
  items: {},
});

const getters = {
  itemsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET_ITEM_NESTED](state, { key, id, ...payload }) {
    Vue.set(state[key], id, payload);
  },
  [DEL_ITEM_NESTED](state, { key, id }) {
    Vue.delete(state[key], id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ rootGetters, commit }, payload) {
    const isAdmin = rootGetters["auth/isAdmin"];
    if (!isAdmin) return { isSuccess: false };

    const { lastKey } = payload || {};

    const resultRef = lastKey
      ? query(ref(database, `users`), orderByKey(), endBefore(lastKey), limitToLast(LIMIT_PAGE_MESSAGES))
      : query(ref(database, `users`), orderByKey(), limitToLast(LIMIT_PAGE_MESSAGES));

    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([id, data]) => {
        commit(SET_ITEM_NESTED, { key: "items", id, ...data });
      });
    }

    return { isSuccess: true, data };
  },
  async [GET](_, { uid }) {
    try {
      const userRef = ref(database, `users/${uid}/private`);

      const snapshot = await get(userRef);

      return snapshot.val() || null;
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ rootGetters, commit }) {
    const isAdmin = rootGetters["auth/isAdmin"];
    if (!isAdmin) return { isSuccess: false };

    const resultRef = query(ref(database, `users`), orderByKey(), limitToLast(LIMIT_PAGE_MESSAGES));

    const key = "items";
    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key, id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM_NESTED, { key, id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM_NESTED, { key, id: snapshot.key });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  getters,
  mutations,
  actions,
};
