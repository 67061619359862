import { createModuleResetter } from "@/helpers/utils";

import { CREATE, REMOVE } from "../../actions";
import { ADD_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: [],
});

const getters = {};

const mutations = {
  [ADD_ITEM](state, payload) {
    state.items.unshift({ ...payload });
  },
  [DEL_ITEM](state) {
    state.items.splice(state.items.length - 1, 1);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [CREATE]({ commit }, payload) {
    commit(ADD_ITEM, payload);
  },
  async [REMOVE]({ commit }) {
    commit(DEL_ITEM);
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
