<template>
  <b-alert show variant="danger" class="rounded-lg">
    <p class="text-white">
      <strong>ERROR:</strong><br />
      {{ error }}
    </p>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
  name: "Errors",
  components: { BAlert },
  props: {
    error: {
      type: [Error, String],
    },
  },
};
</script>
