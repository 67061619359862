// Wrapper
import Admin from "../views/";
import Migrations from "../views/Migrations";

const AdminDashboard = () => import("../views/Dashboard/");

export default [
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: { name: "AdminDashboard" },
    meta: { auth: true },
    children: [
      {
        path: "dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
      },
    ],
  },
  {
    path: "/admin/migrations",
    name: "Migrations",
    component: Migrations,
    meta: { auth: true },
  },
];
