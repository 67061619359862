import { getStripeSubscription } from "@/common/functions";
import { createModuleResetter } from "@/helpers/utils";

import { SUBSCRIPTION } from "../../actions";
import { RESET_STORE } from "../../mutations";

const getInitState = () => ({});

const getters = {};

const mutations = {
  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [SUBSCRIPTION]({ rootGetters }) {
    const subscriptions = rootGetters["auth/subscriptions"];

    try {
      const result = [];

      for await (const subscriptionId of Object.keys(subscriptions)) {
        const { data } = await getStripeSubscription({ subscriptionId });
        result.push({ ...data });
      }

      return { isSuccess: true, result };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
