import Vue from "vue";
import {
  getDatabase,
  ref,
  query,
  get,
  update,
  push,
  set,
  remove,
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  orderByKey,
  endBefore,
  limitToLast,
  serverTimestamp,
} from "firebase/database";
import TurndownService from "turndown";

import { replyToUser /* replyToGuest */ } from "@/common/functions";

import { listReferences } from "@/helpers/tasksMessages";
import { ObjectToSortedArrayReverse } from "@/helpers/utils";
import { sanitizeMessages } from "@/helpers/gemini";

import { FETCH, CREATE, UPDATE, REMOVE, LISTEN, UNLISTEN, RESPOND, RESPOND_PUBLIC } from "../../actions";
import { SET_ITEM, DEL_ITEM } from "../../mutations";
import { CHATBOT_ROLES } from "@/constants/aiChat";

const LIMIT_PAGE_MESSAGES = 10;

const database = getDatabase();
const turndownService = new TurndownService();

const getInitState = () => ({
  items: {},
});

const getters = {
  itemsSorted: (state) => () => {
    return ObjectToSortedArrayReverse(state.items, "createdAt");
  },
  itemsSortedByTaskId: (_, getters) => (taskId) => {
    const itemsSorted = getters.itemsSorted();
    return itemsSorted.filter((c) => c.taskId === taskId);
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    Vue.set(state.items, id, payload);
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },
};

const actions = {
  async [FETCH]({ commit }, payload) {
    const { projectId, lastKey, taskId } = payload;

    const resultRef = lastKey
      ? query(
          ref(database, `projects/${projectId}/tasksMessages/${taskId}`),
          orderByKey(),
          endBefore(lastKey),
          limitToLast(LIMIT_PAGE_MESSAGES)
        )
      : query(ref(database, `projects/${projectId}/tasksMessages/${taskId}`), orderByKey(), limitToLast(LIMIT_PAGE_MESSAGES));

    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([messageId, messageData]) => {
        commit(SET_ITEM, { id: messageId, taskId, ...messageData });
      });
    }

    return { isSuccess: true, data };
  },
  async [CREATE]({ rootGetters }, payload) {
    const uid = rootGetters["auth/uid"];
    const createdAt = serverTimestamp();

    try {
      const { projectId, taskId } = payload;

      const id = push(child(ref(database), `projects/${projectId}/tasksMessages/${taskId}`)).key;
      await set(ref(database, `projects/${projectId}/tasksMessages/${taskId}/${id}`), { ...payload, createdAt, uid, id });

      return { isSuccess: true, id };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, error };
    }
  },
  async [UPDATE]({ rootGetters }, payload) {
    const updatedBy = rootGetters["auth/uid"];

    const { id, type = null, content } = payload;
    const updatedAt = serverTimestamp();

    try {
      const { projectId, taskId } = payload;
      await update(ref(database, `projects/${projectId}/tasksMessages/${taskId}/${id}`), {
        type,
        content,
        updatedAt,
        updatedBy,
        id,
      });

      return { isSuccess: true, id };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },
  async [REMOVE](_, payload) {
    const { projectId, id } = payload;

    try {
      const { taskId } = payload;
      await remove(ref(database, `projects/${projectId}/tasksMessages/${taskId}/${id}`));

      return { isSuccess: true, id };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ commit }, payload) {
    const { projectId, taskId } = payload;

    const resultRef = query(
      ref(database, `projects/${projectId}/tasksMessages/${taskId}`),
      orderByKey(),
      limitToLast(LIMIT_PAGE_MESSAGES)
    );

    onChildAdded(resultRef, (snapshot) => {
      const references = listReferences(snapshot.val());
      commit(SET_ITEM, { taskId, id: snapshot.key, ...snapshot.val(), references });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { taskId, id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { taskId, id: snapshot.key });
    });
  },
  async [UNLISTEN]() {},

  async [RESPOND]({ rootGetters }, payload) {
    const languageFamily = rootGetters["auth/languageFamily"];
    const { messages, ...others } = payload;

    // TODO: Sanitized messages should only keep the keys { role, content } for each item of the array messages
    const messagesSanitized = messages.map((message) => ({
      role: message.role,
      content: message.content,
    }));

    try {
      const { data } = await replyToUser({ ...others, messages: messagesSanitized, languageFamily });

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },
  async [RESPOND_PUBLIC](_, payload) {
    // const language = rootGetters["auth/language"];
    const { messages } = payload;

    // const messagesSanitized = messages.map((message) => ({
    //   role: message.role,
    //   content: message.content,
    // }));
    // const { data } = await replyToGuest({ ...others, messages: messagesSanitized, language });
    // return { isSuccess: true, ...data };

    let { content: lastMessage } = messages[messages.length - 1];
    lastMessage = turndownService.turndown(lastMessage);

    const chat = sanitizeMessages(messages);

    try {
      const result = await chat.sendMessageStream(lastMessage);

      // let text = "";
      messages.push({ role: CHATBOT_ROLES.ASSISTANT, content: "" });

      for await (const chunk of result.stream) {
        const chunkText = chunk.text();
        console.log(chunkText);
        // text += chunkText;
        messages[messages.length - 1].content = messages[messages.length - 1].content += chunkText;
      }

      console.log("text");
      // console.log(text);

      return { isSuccess: true /*  role: CHATBOT_ROLES.ASSISTANT, content: text */ };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },
};
/*
async triggerVercel() {
  const chat = vertexAIModel.startChat({
    history: [
      {
        role: "user",
        parts: [{ text: "Hello, I have 2 dogs in my house." }],
      },
      {
        role: "model",
        parts: [{ text: "Great to meet you. What would you like to know?" }],
      },
    ],
    generationConfig: {
      maxOutputTokens: 8192,
    },
  });

  const msg = "How many paws are in my house?";
  const result = await chat.sendMessageStream(msg);

  let text = "";
  for await (const chunk of result.stream) {
    const chunkText = chunk.text();
    console.log(chunkText);
    text += chunkText;
  }

  console.log("text");
  console.log(text);
}, */

export default {
  namespaced: true,
  state: getInitState(),
  getters,
  mutations,
  actions,
};
