export const CURRENCIES_WITH_SYMBOL = {
    usd: "$",
    eur: "€",
    matic: "MATIC",
  };
  
  export const CRYPTOCURRENCIES = {
    polygon: "matic",
    matic: "matic",
  };
  