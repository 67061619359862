/* eslint-disable */

export default [
  { tag: "script", attributes: { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-6NRGGZ2JL2" } },
  {
    tag: "script",
    innerHTML:
      "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-6NRGGZ2JL2');",
  },
];
