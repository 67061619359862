<template>
  <div :style="style" class="w-100"></div>
</template>

<script>
export default {
  name: "LayerSpacer",
  props: {
    height: {
      type: String,
      default: "100px",
    },
  },
  computed: {
    style() {
      const { height } = this;
      return `height: ${height}`;
    },
  },
};
</script>
