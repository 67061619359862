import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import { onAuthStateChanged } from "firebase/auth";

import { authentication } from "@/common/firebase";
import { clearRefreshTokenTimeout, putTokenToStorage } from "@/helpers/auth";
import { getLanguageFromURL, getLanguageFromBrowser } from "@/helpers/utils";

import router from "../router";

/* MEMBERS */
import auth from "./modules/auth";
import authTemporary from "./modules/authTemporary";
import checkout from "./modules/checkout";

/* PROJECTS */
import projects from "./modules/projects";
import projectsSettings from "./modules/projects/settings";
import projectsBilling from "./modules/projects/billing";
import projectsBatchs from "./modules/projects/batchs";
import projectsTeammates from "./modules/projects/teammates";
import projectsDocuments from "./modules/projects/documents";
import projectsDocumentsBlocks from "./modules/projects/documentsBlocks";
import projectsDocumentsHistory from "./modules/projects/documentsHistory";
import projectsTasks from "./modules/projects/tasks";
import projectsTasksDiscussions from "./modules/projects/tasksDiscussions";
import projectsTasksDiscussionsMessages from "./modules/projects/tasksDiscussionsMessages";
import projectsTasksTools from "./modules/projects/tasksTools";

/* PROFILE */
import storage from "./modules/storage";
import billing from "./modules/profile/billing";
import locales from "./modules/profile/locales";
import customize from "./modules/profile/customize";
import trackings from "./modules/profile/trackings";
import batchs from "./modules/profile/batchs";
import notifications from "./modules/profile/notifications";
import toasts from "./modules/profile/toasts";
import userPrivate from "./modules/profile/private";

/* MEMBER */
import memberBilling from "./modules/member/billing";
import userVisit from "./modules/user/visits";
import userLogs from "./modules/user/logs";
import userCreditCards from "./modules/user/creditCards";

/* ADMIN */
import adminEvents from "./modules/admin/events";
import adminAnalytics from "./modules/admin/analytics";
import adminUsers from "./modules/admin/users";

import { INIT, FETCH, GET } from "./actions";
import { SET_INITIALIZED, SET_AUTH, PURGE_AUTH, SET_PERMISSIONS, SET_IS_ADMIN } from "./mutations";
import { AUTH_TARGET_ROUTE } from "@/constants/local-storage";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    initialized: false,
    permissions: null,
  },
  modules: {
    auth,
    authTemporary,
    projects,
    projectsSettings,
    projectsBilling,
    projectsBatchs,
    projectsTeammates,
    projectsDocuments,
    projectsDocumentsBlocks,
    projectsDocumentsHistory,
    projectsTasks,
    projectsTasksDiscussions,
    projectsTasksDiscussionsMessages,
    projectsTasksTools,
    customize,
    storage,
    billing,
    locales,
    trackings,
    batchs,
    notifications,
    toasts,
    checkout,
    userPrivate,
    userVisit,
    userLogs,
    userCreditCards,
    memberBilling,
    adminUsers,
    adminEvents,
    adminAnalytics,
  },
  plugins: [...(process.env.NODE_ENV === "development" ? [createLogger()] : [])],
  mutations: {
    [SET_INITIALIZED](state, value) {
      state.initialized = value;
    },
    [SET_PERMISSIONS](state, permissions) {
      state.permissions = permissions;
    },
    [SET_IS_ADMIN](state, isAdmin) {
      state.isAdmin = isAdmin;
    },
  },
  actions: {
    async [INIT]({ dispatch, commit, state }) {
      await new Promise((resolve) => {
        onAuthStateChanged(authentication, async (user) => {
          const { initialized } = state;
          const { currentRoute } = router;

          if (!user) {
            clearRefreshTokenTimeout();
            localStorage.removeItem("token");

            const language = getLanguageFromURL() || getLanguageFromBrowser();
            await store.dispatch(`locales/${INIT}`, { language });

            if (initialized) {
              store.commit(`auth/${PURGE_AUTH}`); // clear store if user was logged out
              router.push({ name: "Login" }); // force redirect from private route
            }
          } else {
            const { token } = await user.getIdTokenResult(true);
            putTokenToStorage(token); // save JWT to storage

            store.commit(`auth/${SET_AUTH}`, user);

            const userData = await store.dispatch(`auth/${GET}`);
            store.dispatch(`notifications/${FETCH}`);

            const language = userData.private?.language || getLanguageFromURL() || getLanguageFromBrowser();
            await store.dispatch(`locales/${INIT}`, { language });

            // redirect to account login after authorization
            if (initialized && (!state.auth.isAuthenticated || ["Login", "Register"].includes(currentRoute.name))) {
              const target = localStorage.getItem(AUTH_TARGET_ROUTE);
              if (target) {
                await router.push(JSON.parse(target));
              } else {
                await router.push({ name: "Projects" });
              }

              localStorage.removeItem(AUTH_TARGET_ROUTE);
            }
          }
          if (!initialized) resolve();
        });
      });

      dispatch(`customize/${INIT}`, null, { root: true });
      commit(SET_INITIALIZED, true);
    },
  },
});

export default store;
