import { CURRENCIES_WITH_SYMBOL } from "@/constants/currencies";

export const setAmount = (amount, currency) => {
  if (amount === null || amount === undefined) return "";

  //
  // 1. Amount are stored in cents inside the DB
  //
  if (amount > 0) amount = amount / 100;

  // 2. Currency has to be placed before or after the amount depending on the currency
  let string;
  if (currency === "eur") string = `${amount}${CURRENCIES_WITH_SYMBOL[currency]}`;
  else string = `${CURRENCIES_WITH_SYMBOL[currency]}${amount}`;

  return string;
};
