const checkTracker = ({ key }) => {
  const $script = document.querySelectorAll(`[data-${key}]`);

  if ($script && $script.length) return true;
  return false;
};

const addTracker = ({ key, elements }) => {
  elements.forEach((el) => {
    const { tag, tags, attributes, innerHTML } = el;

    const $el = document.createElement(tag);
    $el.setAttribute(`data-${key}`, true);

    if (attributes) Object.entries(attributes).forEach(([key, val]) => ($el[key] = val));
    if (innerHTML) $el.innerHTML = innerHTML;
    if (tags)
      tags.forEach((t) => {
        const $elSub = document.createElement(t.tag);
        Object.entries(t.attributes).forEach(([key, val]) => ($elSub[key] = val));
        $el.appendChild($elSub);
      });

    document.head.appendChild($el);
  });
};

const removeTracker = ({ key }) => {
  const $scripts = document.querySelectorAll(`[data-${key}]`);
  $scripts.forEach(($el) => $el.remove());
};

export const updateTrackers = ({ key, elements, action }) => {
  const isOnPage = checkTracker({ key });

  switch (action) {
    case "add":
      if (!isOnPage) addTracker({ key, elements });
      break;
    case "remove":
      if (isOnPage) removeTracker({ key });
      break;

    default:
  }
};
