export const EMAIL_PROVIDERS = {
  "gmail.com": { name: "gmail", url: "https://mail.google.com/" },
  "yahoo.com": { name: "yahoo", url: "https://mail.yahoo.com/" },
  "outlook.com": { name: "outlook", url: "https://outlook.live.com/" },
  "hotmail.com": { name: "outlook", url: "https://hotmail.com/" },
};

export const AUTH_STEPS = {
  EMAIL_PASSWORD: "password",
  EMAIL_MAGICLINK: "magiclink",
  SUCCESS: "success",
};
