<template>
  <div>
    <h3 class="mt-50 text-center text-uppercase text-white">{{ "Register Now" | __t("auth__register-now") }}</h3>

    <slot></slot>

    <div class="mb-50" />

    <b-button
      pill
      data-cy="link-register-magiclink"
      variant="light"
      @click="$emit('continue-with-email-magiclink')"
      class="w-100"
    >
      <b-icon-envelope-fill /> {{ "Continue With Email And Magic Link" | __t("auth__continue-with-email-and-magiclink") }}
    </b-button>

    <b-button
      pill
      data-cy="link-register-password"
      variant="light"
      @click="$emit('continue-with-email-password')"
      class="w-100 mt-3"
    >
      <b-icon-key-fill /> {{ "Continue With Email And Password" | __t("auth__continue-with-email-and-password") }}
    </b-button>

    <!--  <b-button pill data-cy="link-register" variant="light" @click="triggerLoginGoogle" class="w-100 mt-3 mb-50">
      <b-icon-google /> {{ "Continue With Google" | __t("auth__continue-with-google") }}
    </b-button> -->

    <p class="text-center mb-50">
      {{ "Already have an account?" | __t("auth__already-have-an-account") }}<br />
      <router-link :to="{ name: 'Login' }">{{ "Sign in" | __t("auth__signin") }}</router-link
      >.
    </p>

    <slot name="footer"></slot>

    <p class="text-center">
      <em>
        <small>
          {{ "By clicking one of the 'Continue' button, you agree to the" | __t("auth__conditions__1-by-clicking-you-agree") }}
          <router-link :to="{ name: 'TermsOfService' }" target="_blank" class="small">
            {{ "Terms and Conditions" | __t("auth__conditions__2-terms-and-conditions") }}
          </router-link>
          {{ "and" | __t("auth__conditions__3-and") }}
          <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank" class="small">
            {{ "Privacy Statement" | __t("auth__conditions__4-privacy-statement") }} </router-link
          >.
        </small>
      </em>
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGIN_BY_GOOGLE } from "@/store/actions";

import { BButton, BIconEnvelopeFill, BIconKeyFill /* BIconGoogle */ } from "bootstrap-vue";

export default {
  name: "RegisterSelect",
  components: { BButton, BIconEnvelopeFill, BIconKeyFill /* BIconGoogle */ },
  methods: {
    ...mapActions("auth", { loginByGoogle: LOGIN_BY_GOOGLE }),
    triggerLoginGoogle() {
      this.errorMessage = null;
      this.loginByGoogle();
    },
  },
};
</script>
