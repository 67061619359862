import Vue from "vue";
import {
  ref,
  query,
  push,
  get,
  set,
  update,
  remove,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  serverTimestamp,
} from "firebase/database";

import { database } from "@/common/firebase";

import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, CREATE, GET, UPDATE, REMOVE, LISTEN } from "../../actions";
import { SET_ITEM, DEL_ITEM, DEL_ITEM_NESTED, RESET_STORE } from "../../mutations";
import { ALLOWED_KEYS_IN_INDEX } from "@/constants/database";

const getInitState = () => ({
  items: {},
});

const getters = {
  teammatesSorted: (state) => () => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
  teammatesSortedOptions: (_, getters) => ({ type = null }) => {
    const { teammatesSorted } = getters;

    const teammatesFiltered = teammatesSorted().filter((t) => t.type === type);

    return teammatesFiltered.map((item) => ({
      value: item.id,
      text: item.name,
      id: undefined,
      name: undefined,
    }));
  },

  teammateById: (state) => ({ id }) => {
    return state.items[id];
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    if (!state.items[id]) Vue.set(state.items, id, {});

    for (const key in payload) {
      Vue.set(state.items[id], key, payload[key]);
    }
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [DEL_ITEM_NESTED](state, { id, key }) {
    Vue.delete(state.items[id], key);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/teammates`));
    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([id, value]) => {
        commit(SET_ITEM, { id, ...value });
      });
    }

    return { isSuccess: true, data };
  },
  async [CREATE]({ rootGetters }, { projectId, ...others }) {
    const createdAt = serverTimestamp();
    const uid = rootGetters["auth/uid"];

    try {
      const data = { name: "No Name", createdAt, uid, ...others };
      const request = push(ref(database, `projects/${projectId}/teammates`));
      const id = request.key;

      await set(ref(database, `projects/${projectId}/teammates/${id}`), { ...data });
      await set(ref(database, `projects/${projectId}/index/teammates/${id}`), { ...data });

      return { isSuccess: true, id, ...data };
    } catch ({ details }) {
      return { isSuccess: false, ...details };
    }
  },
  async [GET]({ commit }, { projectId, teammateId }) {
    const resultRef = query(ref(database, `projects/${projectId}/teammates/${teammateId}`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: teammateId, [snapshot.key]: snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: teammateId, [snapshot.key]: snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM_NESTED, { id: teammateId, key: snapshot.key });
    });

    const snapshot = await get(resultRef);
    const data = snapshot.val();

    commit(SET_ITEM, { id: teammateId, ...snapshot.val() });

    return { teammateId, ...data };
  },
  async [UPDATE]({ rootGetters }, payload) {
    const updatedBy = rootGetters["auth/uid"];
    const updatedAt = serverTimestamp();

    const { projectId, teammateId, ...others } = payload;

    try {
      await update(ref(database, `projects/${projectId}/teammates/${teammateId}`), { updatedAt, updatedBy, ...others });

      const updates = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (ALLOWED_KEYS_IN_INDEX.includes(key)) updates[`projects/${projectId}/index/teammates/${teammateId}/${key}`] = value;
      });

      update(ref(database), updates);

      return { isSuccess: true, teammateId };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, error };
    }
  },
  async [REMOVE](_, { projectId, teammateId }) {
    await remove(ref(database, `projects/${projectId}/index/teammates/${teammateId}`));
    await remove(ref(database, `projects/${projectId}/teammates/${teammateId}`));

    return { teammateId, isSuccess: true };
  },

  async [LISTEN]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/teammates/`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
