<template>
  <div
    @click="$emit('click')"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    :class="{ 'is-active': isActive }"
    class="userbar__icon rounded-lg"
  >
    <img v-if="isHome && (isHovering || isActive)" :id="`icon-project-${id}`" :src="require(`@/assets/icons/home-active.svg`)" />
    <img v-else-if="isHome" :id="`icon-project-${id}`" :src="require(`@/assets/icons/home.svg`)" />
    <img v-else-if="image" :id="`icon-project-${id}`" :src="image" />
    <img
      v-else-if="!image && (isHovering || isActive)"
      :id="`icon-project-${id}`"
      :src="require(`@/assets/icons/folder-active.svg`)"
    />
    <img v-else-if="!image && !isHovering" :id="`icon-project-${id}`" :src="require(`@/assets/icons/folder.svg`)" />

    <b-tooltip :target="`icon-project-${id}`" triggers="hover" placement="right">
      {{ name }}
    </b-tooltip>
  </div>
</template>

<script>
import { BTooltip } from "bootstrap-vue";

export default {
  name: "Projet",
  components: { BTooltip },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: null,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHovering: false,
  }),
};
</script>

<style scoped>
.userbar__icon {
  width: 35px;
  height: 35px;
  padding: 7px;
  margin: auto;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  background-color: #272926;
}

.userbar__icon img {
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
  transition: transform 0.7s ease-in-out;
}

.userbar__icon img:hover {
  transform: rotate(360deg);
}

.userbar__icon.is-active,
.userbar__icon:hover {
  background-color: #5900d9;
  border-radius: 15px;
}
</style>
