import { MIME_MICROSOFT, MIME_MICROSOFT_LIST } from "@/constants/storage";

export const getBlob = async (file) => {
  const reader = new FileReader();

  let fileToBlob = new Promise((resolve, reject) => {
    reader.onload = () => {
      let array = [];
      let dataURL = reader.result;
      let binary = atob(dataURL.split(",")[1]);

      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      resolve(new Blob([new Uint8Array(array)], { type: file.type }));
    };
    reader.onerror = reject;
  });

  reader.readAsDataURL(file);

  const blob = await fileToBlob;

  return blob;
};

export const getMimeType = (type) => {
  // eslint-disable-next-line no-unused-vars
  let none = null;

  if (type.includes("/") && type.includes("application")) [none, type] = type.split("/");
  else [type] = type.split("/");

  if (MIME_MICROSOFT_LIST.includes(type)) type = MIME_MICROSOFT[type];

  return type;
};

export async function getFileFromUrl(url, filename = "No Name") {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok.");

    const blob = await response.blob();
    const mimeType = response.headers.get("Content-Type");

    return new File([blob], filename, { type: mimeType });
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
}

export const getFileDuration = (file) => {
  return new Promise((resolve, reject) => {
    let element = document.createElement(file.type.startsWith("audio") ? "audio" : "video");
    element.src = URL.createObjectURL(file);

    element.addEventListener(
      "loadedmetadata",
      () => {
        resolve(element.duration);
        URL.revokeObjectURL(element.src);
      },
      false
    );

    element.addEventListener(
      "error",
      () => {
        reject("Error loading media file.");
      },
      false
    );
  });
};
