/* eslint-disable */
export const triggerEventFacebook = async ({ event, currency, value, amount, productId, quantity = 1 }) => {
  const $el = document.createElement("script");

  const eventData = {
    eventName: event,
    eventSourceUrl: window.location.href,
  };

  if (event === "Purchase") {
    Object.assign(eventData, { currency, value, amount, quantity, productID: productId });
    $el.innerHTML = `fbq('track', 'Purchase', {currency: "${currency}", value: ${value || amount}});`;
  } else {
    $el.innerHTML = `fbq('track', '${event}');`;
  }

  $el.setAttribute(`data-facebook-ads`, true);
  document.head.appendChild($el);
};

export default [
  {
    tag: "noscript",
    tags: [
      {
        tag: "img",
        attributes: {
          height: 1,
          width: 1,
          style: "display:none",
          src: "https://www.facebook.com/tr?id=263870472788161&ev=PageView&noscript=1",
        },
      },
    ],
  },
  {
    tag: "script",
    innerHTML:
      "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '263870472788161');fbq('track', 'PageView');",
  },
];
