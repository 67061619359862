<template>
  <div data-cy="login">
    <errors v-if="errorMessage" :error="errorMessage" class="mb-4 mt-1" data-cy="login-errors" />

    <b-alert show variant="danger" class="rounded-lg mt-1" v-if="passSetupError">
      <div class="theme--dark">
        <h2 style="font-weight: bolder; font-size: 1.4em">Your password is not setup yet</h2>
        <h3 style="font-weight: bolder; font-size: 1em">To setup your password please follow these steps:</h3>
        <ol class="m-0 pl-3">
          <li>Login with Magic Link</li>
          <li>Go to your profil settings</li>
          <li>Change password</li>
        </ol>
      </div>
    </b-alert>

    <b-form @submit="triggerLogin">
      <fieldset class="form-group" data-cy="login-fieldset">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">Email</p>

          <base-input
            name="email"
            type="email"
            id="email"
            v-model="email"
            :disabled="isSending"
            placeholder="mycoolmail@email.com"
            @keyup.enter="triggerLogin"
          />
        </div>

        <input-error
          v-if="!$v.email.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="login-error"
        />
        <input-error v-if="!$v.email.email" :error-code="'email'" :has-submit-error.sync="hasSubmitError" data-cy="login-error" />
      </fieldset>

      <fieldset class="form-group" data-cy="login-fieldset">
        <div class="p-2 bg-dark-jungle rounded">
          <p class="mb-0 text-uppercase font-weight-bold">
            {{ "Password:" | __t("forms__login__password") }}
          </p>

          <base-password
            name="password"
            type="password"
            id="password"
            :disabled="isSending"
            v-model="password"
            :placeholder="`Insert your password` | __t('auth__login__insert-your-password')"
            @keyup.enter.native="triggerLogin"
            data-cy="login-password"
          />
        </div>

        <input-error
          v-if="!$v.password.required"
          :error-code="'required'"
          :has-submit-error.sync="hasSubmitError"
          data-cy="password-error"
        />
      </fieldset>

      <div class="text-right mt-3">
        <b-button @click="triggerLogin" variant="primary" class="w-100" data-cy="login-submit" :disabled="isSending" pill>
          {{ "Log In" | __t("button__log-in") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { LOGIN, AUTH_METHODS } from "@/store/actions";

import { BForm, BButton, BAlert } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import Errors from "@/components/Data/Errors";
import InputError from "@/components/Data/ErrorsInput";
import BaseInput from "@/components/Forms/Base/BaseInput";
import BasePassword from "@/components/Forms/Base/BasePassword";

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  components: { Errors, InputError, BForm, BButton, BAlert, BasePassword, BaseInput },
  data() {
    return {
      email: null,
      password: null,

      errorMessage: null,

      hasSubmitError: null,
      isSending: false,
      passSetupError: false,
    };
  },
  methods: {
    ...mapActions("auth", { login: LOGIN, getAuthMethods: AUTH_METHODS }),
    async triggerLogin(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;
      this.passSetupError = false;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { email, password } = this;
      const { isSuccess, error, errorCode } = await this.login({ email, password });

      if (isSuccess) {
        this.$emit("success");
        return;
      }

      let authMethods = [];
      try {
        authMethods = await this.getAuthMethods(email);
      } catch (e) {
        console.warn(e);
      }

      if (errorCode === "auth/wrong-password" && authMethods.includes && !authMethods.includes("password")) {
        this.passSetupError = true;
      } else {
        this.errorMessage = error;
      }
      this.isSending = false;
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
};
</script>
