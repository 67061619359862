<template>
  <div>
    <user-sidebar :project-id="projectId" />

    <div
      :style="`margin-top: 50px; margin-left: ${WIDTH_USER_SIDEBAR}px;`"
      class="h-100 bg-dark rounded-top-left-lg project-wrapper"
    >
      <b-row no-gutters class="almostfull-height">
        <b-col v-if="projectId" cols="12" md="3" class="overflow-y-scroll">
          <project-sidebar
            :project-id="projectId"
            :teammate-id="teammateId"
            :task-id="taskId"
            :route-name="routeName"
            :route-path="routePath"
          />
        </b-col>

        <b-col cols="12" md="9">
          <router-view />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import UserSidebar from "@/components/Sidebar/User/";
import ProjectSidebar from "@/components/Sidebar/Project/";

export default {
  name: "LayoutDesktop",
  components: { BRow, BCol, UserSidebar, ProjectSidebar },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    teammateId() {
      return this.$route.params.teammateId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
    routeName() {
      return this.$route.name;
    },
    routePath() {
      return this.$route.path;
    },
  },
  data: () => ({
    WIDTH_USER_SIDEBAR,
  }),
};
</script>

<style lang="scss" scoped>
.almostfull-height {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
</style>
