import Vue from "vue";
import { ref, query, get, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { database } from "@/common/firebase";
import { checkBatch } from "@/common/functions";
import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, GET, LISTEN, CHECK } from "../../actions";
import { SET_ITEM, DEL_ITEM, DEL_ITEM_NESTED, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
});

const getters = {
  batchsSorted: (state) => () => {
    return ObjectToSortedArray(state.items, "createdAt");
  },

  batchById: (state) => ({ id }) => {
    return state.items[id];
  },
};

const mutations = {
  [SET_ITEM](state, { id, ...payload }) {
    if (!state.items[id]) Vue.set(state.items, id, {});

    for (const key in payload) {
      Vue.set(state.items[id], key, payload[key]);
    }
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [DEL_ITEM_NESTED](state, { id, key }) {
    Vue.delete(state.items[id], key);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/batchs`));
    const snapshot = await get(resultRef);

    const data = snapshot.val();

    if (data) {
      Object.entries(data).forEach(([id, value]) => {
        commit(SET_ITEM, { id, ...value });
      });
    }

    return { isSuccess: true, data };
  },

  async [GET]({ commit }, { projectId, batchId }) {
    const resultRef = query(ref(database, `projects/${projectId}/batchs/${batchId}`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: batchId, [snapshot.key]: snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: batchId, [snapshot.key]: snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM_NESTED, { id: batchId, key: snapshot.key });
    });

    const snapshot = await get(resultRef);
    const data = snapshot.val();

    commit(SET_ITEM, { id: batchId, ...snapshot.val() });

    return { batchId, ...data };
  },

  async [LISTEN]({ commit }, { projectId }) {
    const resultRef = query(ref(database, `projects/${projectId}/index/batchs/`));

    onChildAdded(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(resultRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(resultRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });
  },

  async [CHECK](_, payload) {
    try {
      const { data } = await checkBatch(payload);

      return { isSuccess: true, ...data };
    } catch (error) {
      const { details: errorCode } = JSON.parse(JSON.stringify(error));
      return { isSuccess: false, errorCode };
    }
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
