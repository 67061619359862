export const COOKIES = [
  {
    status: true,
    isDisabled: true,
    name: "Necessary",
    key: "necessary",
    description: "These cookies are necessary for the proper functioning of our website and cannot be disabled.",
  },
  {
    status: true,
    name: "Performance",
    key: "performance",
    description: "We use these cookies to provide analytics on our website. They are used to measure and improve performance.",
  },
  /* {
    status: true,
    name: "Fonctionnel",
    key: "functions",
    description:
      "Nous utilisons ces cookies pour améliorer les fonctionnalités et permettre la personnalisation, comme
      les discussions en direct, les vidéos et l'utilisation des médias sociaux.",
  }, */
  {
    status: true,
    name: "Ads",
    key: "ads",
    description: "These cookies are installed on our site by our advertising partners.",
  },
];
