const triggerAlarm = () => {
  const $player = document.getElementById("notifications-player");
  $player.play();

  const $faviconApple = document.getElementsByClassName("favicon-apple") || [];
  $faviconApple[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Fapple-touch-icon__alert.png?alt=media&token=8cfbbc46-74b5-4fd1-9075-9d2e33a3864c";

  const $favicon32 = document.getElementsByClassName("favicon-32x32") || [];
  $favicon32[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Ffavicon-32x32__alert.png?alt=media&token=67298617-2036-42ff-ab1b-336c58b244c7";

  const $favicon16 = document.getElementsByClassName("favicon-16x16") || [];
  $favicon16[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Ffavicon-16x16__alert.png?alt=media&token=442b7d14-f422-431d-8a06-25f229308de3";
};

const cleanAlarm = () => {
  const $faviconApple = document.getElementsByClassName("favicon-apple") || [];
  $faviconApple[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Fapple-touch-icon.png?alt=media&token=6448d2dc-bda1-4f68-bcff-c4bd5715f075";

  const $favicon32 = document.getElementsByClassName("favicon-32x32") || [];
  $favicon32[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Ffavicon-32x32.png?alt=media&token=0028b09f-bf2d-4987-b7db-432decdcec64";

  const $favicon16 = document.getElementsByClassName("favicon-16x16") || [];
  $favicon16[0].href =
    "https://firebasestorage.googleapis.com/v0/b/quiet-quill.appspot.com/o/assets%2Ffavicon%2Ffavicon-16x16.png?alt=media&token=0f0eaaa0-e709-4667-9c9f-cb340b1b3f28";
};

const sanitizeNotification = ({ action, status, name = "", wordsCount, ...others }) => {
  let message;

  switch (action) {
    case "write-outline": {
      if (status === "success") message = `The outline for your book ${name} is ready.`;
      else if (status === "fail") message = `The outline for your book ${name} has failed.`;
      break;
    }

    case "write-book": {
      if (status === "success") message = `Your book ${name} has been written. It has ${wordsCount} words.`;
      else if (status === "fail") message = `The writing of your book ${name} has failed.`;
      break;
    }

    default:
      break;
  }

  if (!message) return null;

  return { message, ...others };
};

module.exports = {
  triggerAlarm,
  cleanAlarm,
  sanitizeNotification,
};
