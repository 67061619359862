/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import Vue from "vue";

export const createModuleResetter = (getInitState) => (state) => {
  const initState = getInitState();
  for (const key in state) {
    const value = initState[key];
    if (typeof value !== "undefined") Vue.set(state, key, value);
    else Vue.delete(state, key);
  }
};

export const setNestedObject = (obj, path, value) => {
  const keys = path.split(".");

  if (keys.length === 1) {
    Vue.set(obj, path, value);
  } else {
    const key = keys.shift();
    Vue.set(obj, key, setNestedObject(typeof obj[key] === "undefined" ? {} : obj[key], keys.join("."), value));
  }
  return obj;
};

export const toFlatObject = (obj) => {
  const flatObject = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      flatObject[key] = value.map((arrObj) => toFlatObject(arrObj));
      return;
    }

    if (typeof value === "object") {
      const nestedFlatObject = toFlatObject(value);
      Object.keys(nestedFlatObject).forEach((nestedKey) => {
        flatObject[`${key}.${nestedKey}`] = nestedFlatObject[nestedKey];
      });
      return;
    }

    flatObject[key] = value;
  });

  return flatObject;
};

export const setFlatData = ({ treeData, subItemNameInObject }) => {
  const flatData = {};

  treeData.map((item, key) => {
    const { id, type, name, categoryId = null } = item; // categoryId is used in NFT Generator

    const data = type !== subItemNameInObject ? { id, type, name, categoryId } : { id, ...item };

    flatData[id] = { ...data };
    flatData[id].position = key;
    if (flatData[id].dataURL) flatData[id].dataURL = null;

    if (subItemNameInObject && item[subItemNameInObject].length > 0) {
      item[subItemNameInObject].map((subItem, subKey) => {
        flatData[subItem.id] = {
          type: subItem.type,
          ...subItem,
        };
        flatData[subItem.id].position = subKey;
        if (flatData[subItem.id].dataURL) flatData[id].dataURL = null;
      });
    }
  });

  return flatData;
};

export const setTreeData = ({ flatData = {}, parentName = "folderId" }) => {
  const flatDataSorted = ObjectToSortedArray(flatData);

  const map = {};
  const children = [];

  for (let i = 0; i < flatDataSorted.length; i += 1) {
    const { id } = flatDataSorted[i];
    map[id] = i;

    flatDataSorted[i].children = [];
  }

  for (let i = 0; i < flatDataSorted.length; i += 1) {
    const parentId = flatDataSorted[i][parentName];
    const { ...data } = flatDataSorted[i];

    if (parentId) {
      // added a mitigation for missing parentId in map
      if (!(parentId in map)) {
        console.warn(`Missing parentId in map: ${parentId}`);
        continue; // Skip this iteration if parentId is not found in map
      }

      flatDataSorted[map[parentId]].children.push(data);

      // Check if 'position' exists in data object, and sort children by 'position' if it does
      if ("position" in data) {
        flatDataSorted[map[parentId]].children.sort((a, b) => a.position - b.position);
      }
    } else children.push(data);
  }

  // Sort root level children as well, if 'position' exists
  if (children.length > 0 && "position" in children[0]) {
    children.sort((a, b) => a.position - b.position);
  }

  return children;
};

export const findParent = (tree, childId) => {
  for (let node of tree) {
    if (node.children && node.children.length > 0) {
      if (node.children.some((child) => child.id === childId)) {
        return node;
      } else {
        let foundNode = findParent(node.children, childId);
        if (foundNode) return foundNode;
      }
    }
  }
  return null;
};

export const sortByPosition = ({ index, subItemInObject }) => {
  index.sort((a, b) => {
    return a.position - b.position;
  });

  if (subItemInObject)
    for (let item in index) {
      if (index[item][subItemInObject])
        index[item][subItemInObject].sort((a, b) => {
          return a.position - b.position;
        });
    }

  return index;
};

export const angleRegex = /\d+(?=deg)/gi;

export const colorRegex = /(?<color>#(?:[0-9a-f]{2}){2,4}|(?:#[0-9a-f]{3})|(?:rgb|hsl)a?\((?:-?\d+%?[,\s]+){2,3}\s*[\d.]+%?\)|black|silver|gray|whitesmoke|maroon|red|purple|fuchsia|green|lime|olivedrab|yellow|navy|blue|teal|aquamarine|orange|aliceblue|antiquewhite|aqua|azure|beige|bisque|blanchedalmond|blueviolet|brown|burlywood|cadetblue|chartreuse|chocolate|coral|cornflowerblue|cornsilk|crimson|darkblue|darkcyan|darkgoldenrod|darkgray|darkgreen|darkgrey|darkkhaki|darkmagenta|darkolivegreen|darkorange|darkorchid|darkred|darksalmon|darkseagreen|darkslateblue|darkslategray|darkslategrey|darkturquoise|darkviolet|deeppink|deepskyblue|dimgray|dimgrey|dodgerblue|firebrick|floralwhite|forestgreen|gainsboro|ghostwhite|goldenrod|gold|greenyellow|grey|honeydew|hotpink|indianred|indigo|ivory|khaki|lavenderblush|lavender|lawngreen|lemonchiffon|lightblue|lightcoral|lightcyan|lightgoldenrodyellow|lightgray|lightgreen|lightgrey|lightpink|lightsalmon|lightseagreen|lightskyblue|lightslategray|lightslategrey|lightsteelblue|lightyellow|limegreen|linen|mediumaquamarine|mediumblue|mediumorchid|mediumpurple|mediumseagreen|mediumslateblue|mediumspringgreen|mediumturquoise|mediumvioletred|midnightblue|mintcream|mistyrose|moccasin|navajowhite|oldlace|olive|orangered|orchid|palegoldenrod|palegreen|paleturquoise|palevioletred|papayawhip|peachpuff|peru|pink|plum|powderblue|rosybrown|royalblue|saddlebrown|salmon|sandybrown|seagreen|seashell|sienna|skyblue|slateblue|slategray|slategrey|snow|springgreen|steelblue|tan|thistle|tomato|turquoise|violet|wheat|white|yellowgreen|rebeccapurple)/gi;
export const gradientStopColorsRegex = /(?<color>#(?:[0-9a-f]{2}){2,4}|(?:#[0-9a-f]{3})|(?:rgb|hsl)a?\((?:-?\d+%?[,\s]+){2,3}\s*[\d.]+%?\)|black|silver|gray|whitesmoke|maroon|red|purple|fuchsia|green|lime|olivedrab|yellow|navy|blue|teal|aquamarine|orange|aliceblue|antiquewhite|aqua|azure|beige|bisque|blanchedalmond|blueviolet|brown|burlywood|cadetblue|chartreuse|chocolate|coral|cornflowerblue|cornsilk|crimson|darkblue|darkcyan|darkgoldenrod|darkgray|darkgreen|darkgrey|darkkhaki|darkmagenta|darkolivegreen|darkorange|darkorchid|darkred|darksalmon|darkseagreen|darkslateblue|darkslategray|darkslategrey|darkturquoise|darkviolet|deeppink|deepskyblue|dimgray|dimgrey|dodgerblue|firebrick|floralwhite|forestgreen|gainsboro|ghostwhite|goldenrod|gold|greenyellow|grey|honeydew|hotpink|indianred|indigo|ivory|khaki|lavenderblush|lavender|lawngreen|lemonchiffon|lightblue|lightcoral|lightcyan|lightgoldenrodyellow|lightgray|lightgreen|lightgrey|lightpink|lightsalmon|lightseagreen|lightskyblue|lightslategray|lightslategrey|lightsteelblue|lightyellow|limegreen|linen|mediumaquamarine|mediumblue|mediumorchid|mediumpurple|mediumseagreen|mediumslateblue|mediumspringgreen|mediumturquoise|mediumvioletred|midnightblue|mintcream|mistyrose|moccasin|navajowhite|oldlace|olive|orangered|orchid|palegoldenrod|palegreen|paleturquoise|palevioletred|papayawhip|peachpuff|peru|pink|plum|powderblue|rosybrown|royalblue|saddlebrown|salmon|sandybrown|seagreen|seashell|sienna|skyblue|slateblue|slategray|slategrey|snow|springgreen|steelblue|tan|thistle|tomato|turquoise|violet|wheat|white|yellowgreen|rebeccapurple)+\s?(?<location>\d+(?=%))?/gi;

export const objectToURLParams = (object) => {
  let result = "";

  if (!object) return result;

  for (const key in object) {
    if (!object[key]) break;
    if (result !== "") result += "&";

    if (Array.isArray(object[key])) {
      let array = "";

      if (object[key].length) {
        for (const arrKey in object[key]) {
          array = `${array}${object[key][arrKey]},`;
        }
      }
      result = array ? `${result}${key}=${encodeURIComponent(array)}` : result;
    } else {
      result = `${result}${key}=${encodeURIComponent(object[key])}`;
    }
  }

  return result;
};

export const sanitizePricing = (pricing) => {
  Object.entries(pricing).forEach(([platform, data]) => {
    const { price, priceComparison } = data;

    if (price) pricing[platform].price = parseInt(price);

    if (!priceComparison || isNaN(priceComparison)) pricing[platform].priceComparison = null;
    else pricing[platform].priceComparison = parseInt(priceComparison);
  });

  return pricing;
};

export const searchThroughNestedObject = (object, key, predicate) => {
  // eslint-disable-next-line no-prototype-builtins
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object;

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]];
    if (typeof value === "object" && value != null) {
      let o = searchThroughNestedObject(object[Object.keys(object)[i]], key, predicate);
      if (o != null) return o;
    }
  }
  return null;
};

export const swapIdByKey = (arrayToSwap, array, keyToSwap) => {
  const result = arrayToSwap.map((id) => {
    const data = array.find((item) => item.id === id);
    if (data) return data[keyToSwap];
    return id;
  });

  return result;
};

export const generateKey = () => {
  return new Date().getTime() + Math.floor(Math.random() * 100000).toString();
};

export const ObjectToSortedArray = (obj = {}, sortBy) => {
  if (!obj) return [];
  return Object.keys(obj)
    .map((key) => ({ id: key, ...obj[key] }))
    .sort((a, b) => b[sortBy] - a[sortBy]);
};

export const ObjectToSortedArrayReverse = (obj = {}, sortBy) => {
  return Object.keys(obj)
    .map((key) => ({ id: key, ...obj[key] }))
    .sort((a, b) => a[sortBy] - b[sortBy]);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const sendRequestPOST = (url, file) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("file", file);

    const request = new XMLHttpRequest();

    request.open("POST", url);
    request.setRequestHeader("authorization", `Bearer ${token}`);

    request.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        let { response } = request;
        response = JSON.parse(response);

        resolve(response);
      } else {
        reject({
          status: this.status,
          statusText: request.statusText,
        });
      }
    };

    request.onerror = function() {
      reject({
        status: this.status,
        statusText: request.statusText,
      });
    };

    request.send(formData);
  });
};

export const checkForCharacter = (oldText, text, character) => {
  let diff = 0;
  let shouldSkip = false;

  oldText.split("").forEach((val, i) => {
    if (shouldSkip) {
      return;
    }
    if (text.substring(i, i + 1) !== val) {
      diff = i;
      shouldSkip = true;
      return;
    }
  });

  if (text.length <= 2) {
    if (text.substring(0, 1) === character) {
      return true;
    } else {
      return false;
    }
  } else {
    if (text.substring(diff, diff + 1) === `${character}`) {
      return true;
    } else if (text.substring(diff - 1, diff + 1) === `  `) {
      return false;
    } else if (oldText.substring(diff, diff + 1) === `${character}` && text.substring(diff - 1, diff) == " ") {
      return false;
    }
  }
};

export const objectToArray = (obj) => {
  return Object.keys(obj).map((key) => ({ key, ...obj[key] }));
};

export const replaceLineBreaks = (text, value = "") => {
  return text.replace(/(?:\r\n|\r|\n)/g, value);
};

export const arrayToString = (array) => {
  let result = "";

  array.forEach((item, index) => {
    result += `Name: ${item.name}\n`;
    result += `Description: ${item.description}\n`;
    if (item.subchapters && item.subchapters.length) {
      result += "Elements:\n";
      item.subchapters.forEach((element, elementIndex) => {
        result += `  ${elementIndex + 1}. ${element.name}\n`;
        result += `     ${element.description}\n`;
      });
    }

    if (index !== array.length - 1) {
      result += "\n";
    }
  });

  return result;
};

export const arrayToList = (arr) => {
  return arr.map((item) => `- ${item}`).join("\n");
};

export const arrayDeepToList = (array) => {
  let result = [];

  array.forEach((item, index) => {
    let authorName = item["author-name"] ? item["author-name"].toUpperCase() : "";
    let bookName = item["book-name"] ? item["book-name"].toUpperCase() : "";

    if (authorName || bookName) {
      result.push(`${index + 1}. ${authorName}${bookName ? ": " + bookName : ""}`);
    }
  });

  return result.join("\n");
};

export const arrayToOptions = (array) => {
  return array.map((item) => {
    return {
      value: item.id,
      text: item.name,
    };
  });
};

export const arrayOptionsWithCondition = (options, planLevel) => {
  const result = [];

  options.forEach((o) => {
    const { condition } = o;
    let { disabled = false } = o;

    if (condition) {
      if (!condition(planLevel)) {
        disabled = true;
      }
    }

    result.push({ ...o, disabled });
  });

  return result;
};

export const getURLParamsByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getLanguageFromURL = () => {
  return getURLParamsByName("language");
};

export const getLanguageFromBrowser = () => {
  console.log("getting language...");
  let language;

  if (navigator.languages && navigator.languages.length) language = navigator.languages[0];
  else language = navigator.userLanguage || navigator.language || navigator.browserLanguage;

  // Delete country characters
  /* TODO: Handle that */
  // return language.split("-").shift();
  return language;
};

export const toSlug = (text) =>
  !text?.length
    ? ""
    : text
        .toLowerCase()
        .normalize("NFC")
        .replace(/ +/g, "-")
        .replace(/  +/g, "")
        .replace(/\n/g, "-")
        .replace(/[&/\\#,+()$~%.'":*?!è§"`<>{};^=+]/g, "")
        .replace(new RegExp("@", "g"), "a")
        .replace(new RegExp("[àáâãäå]", "g"), "a")
        .replace(new RegExp("[àáâãäå]", "g"), "a")
        .replace(new RegExp("æ", "g"), "ae")
        .replace(new RegExp("ç", "g"), "c")
        .replace(new RegExp("[èéêë]", "g"), "e")
        .replace(new RegExp("[ìíîï]", "g"), "i")
        .replace(new RegExp("ñ", "g"), "n")
        .replace(new RegExp("[òóôõö]", "g"), "o")
        .replace(new RegExp("œ", "g"), "oe")
        .replace(new RegExp("[ùúûü]", "g"), "u")
        .replace(new RegExp("[ýÿ]", "g"), "y");

export const convertToCss = (styles) => {
  return Object.entries(styles)
    .map(([property, value]) => {
      let cssProperty = property.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
      return `${cssProperty}: ${value};`;
    })
    .join(" ");
};

export const getBytesToMegabytes = (bytes) => {
  if (bytes === null || bytes === undefined) return 0;
  const result = bytes * 0.000001;

  return result.toFixed(2);
};

export const pickRandomItem = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

export const countWords = (str) => {
  if (!str) return 0;

  const words = str.trim().split(/\s+/);
  return str.length === 0 ? 0 : words.length;
};

export const encodeAsFirebaseKey = (string) => {
  if (!string) return;
  string = string.toLowerCase();

  return string
    .replace(/\./g, ",")
    .replace(/\#/g, "=")
    .replace(/\$/g, "+")
    .replace(/\//g, "-")
    .replace(/\[/g, "(")
    .replace(/\]/g, ")");
};

export const decodeFromFirebaseKey = (string) => {
  if (!string) return "";
  string = string.toLowerCase();
  return string
    .replace(/\,/g, ".")
    .replace(/\=/g, "#")
    .replace(/\+/g, "$")
    .replace(/\-/g, "/")
    .replace(/\(/g, "[")
    .replace(/\)/g, "]");
};

export const getItemWithStatus = (array, status) => {
  let index = 0;

  for (let item of array) {
    for (let key in item) if (item[key] === status) return { index, task: item };

    index++;
  }

  return null;
};

export const getNumberStartingEntries = (obj) => {
  if (!obj) return;
  const result = {};

  for (let key of Object.keys(obj)) {
    if (/^\d/.test(key)) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const findWithStatus = (array, status = "in-progress") => {
  for (let item of array) {
    for (let key in item) {
      if (item[key] === status) {
        return item;
      }
    }
  }
  return null; // or return undefined, or however you want to handle no matches
};

export const areAllNumericKeysWithStatus = (obj, status) => {
  return Object.keys(obj)
    .filter((key) => /^\d/.test(key))
    .every((key) => obj[key] === status);
};

export const scrollToLastItemWithClass = (className) => {
  const elements = document.querySelectorAll(`.${className}`);

  if (elements.length > 0) {
    const lastElement = elements[elements.length - 1];
    lastElement.scrollIntoView({ behavior: "smooth" });
  }
};

export const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
};

export const scrollToBottomOfDiv = (selector) => {
  const div = document.querySelector(selector);

  if (div) {
    window.scrollTo({
      top: div.scrollHeight,
      behavior: "smooth",
    });
  }
};

export function getCookie(name) {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length == 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

export const generatePassword = (numChars) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let newPassword = "";
  for (let i = 0; i < numChars; i++) {
    newPassword += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return newPassword;
};

export const textExcerpt = (text, length = 150) => {
  return text?.length > length ? text.substr(0, length - 1) + "..." : text;
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isChildOfClass = (target, className) => {
  while (target) {
    if (target.classList.contains(className)) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
};

export const base64Encode = (str) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
};

export const base64Decode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
};

export const objectToSortedArray = (obj = {}, sortBy) => {
  return Object.keys(obj)
    .map((key) => ({ id: key, ...obj[key] }))
    .sort((a, b) => b[sortBy] - a[sortBy]);
};

export const objectToSortedArrayReverse = (obj = {}, sortBy) => {
  return Object.keys(obj)
    .map((key) => ({ id: key, ...obj[key] }))
    .sort((a, b) => a[sortBy] - b[sortBy]);
};
