const Website = () => import("../views/");

// Modules
const Landing = () => import("../views/Landing/");
const LandingClassic = () => import("../views/LandingClassic/");
const LandingGoodbyeSeo = () => import("../views/LandingGoodbyeSEO/");
const LandingWriterSeo = () => import("../views/LandingWriterSeo/");
const LandingTeammate = () => import("../views/LandingTeammate/");
const LandingUrl = () => import("../views/LandingUrl/");

const CookiesPolicy = () => import("../views/Legal/CookiesPolicy");
const PrivacyPolicy = () => import("../views/Legal/PrivacyPolicy");
const TermsOfService = () => import("../views/Legal/TermsOfService");

export default [
  {
    path: "/",
    name: "Website",
    title: "Website",
    component: Website,
    redirect: { name: "Projects" },
    children: [
      {
        path: "/try-now",
        name: "Landing",
        component: Landing,
      },

      {
        path: "/try-now",
        name: "Landing",
        component: Landing,
      },
      {
        path: "c/try-now",
        name: "LandingClassic",
        component: LandingClassic,
      },
      {
        path: "/try-now-goodbyeseo",
        name: "LandingClassic",
        component: LandingGoodbyeSeo,
      },
      {
        path: "/try-now-writerseo",
        name: "LandingClassic",
        component: LandingWriterSeo,
      },
      {
        path: "/try-now-teammate",
        name: "LandingTeammate",
        component: LandingTeammate,
      },
      {
        path: "/try-now-url",
        name: "LandingUrl",
        component: LandingUrl,
      },

      {
        path: "/legal/cookies",
        name: "CookiesPolicy",
        component: CookiesPolicy,
      },
      {
        path: "/legal/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/legal/terms-of-service",
        name: "TermsOfService",
        component: TermsOfService,
      },
    ],
  },
];
