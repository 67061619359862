import Vue from "vue";
import { ref, get, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";

import { database } from "@/common/firebase";
import { createProject, removeProject } from "@/common/functions";

import { createModuleResetter, ObjectToSortedArray } from "@/helpers/utils";

import { FETCH, CREATE, GET, REMOVE, LISTEN } from "../actions";
import { SET_KEY, SET_ITEM, DEL_ITEM, SET_ITEM_NESTED, DEL_ITEM_NESTED, RESET_STORE } from "../mutations";

const getInitState = () => ({
  items: {},
  currentProject: {},
  currentProjectIndex: {},
});

const getters = {
  projectsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
  projectById: (state) => ({ id }) => {
    return state.items[id] || {};
  },

  settings: (state) => {
    return state.currentProject?.settings;
  },
  extensions: (state) => {
    return state.currentProject?.extensions;
  },
};

const mutations = {
  [SET_KEY](state, { key, value }) {
    Vue.set(state, key, { ...value });
  },

  [SET_ITEM](state, { id, ...payload }) {
    Vue.set(state.items, id, { ...payload });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [SET_ITEM_NESTED](state, { id, nestedKey, ...payload }) {
    if (!state.items[id]) {
      Vue.set(state.items, id, { [nestedKey]: { ...payload } });
    } else if (!state.items[id][nestedKey]) {
      Vue.set(state.items[id], nestedKey, { ...payload });
    } else {
      state.items[id][nestedKey] = { ...state.items[id][nestedKey], ...payload };
    }
  },
  [DEL_ITEM_NESTED](state, { id, nestedKey, itemId }) {
    Vue.delete(state.items[id][nestedKey], itemId);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ rootGetters, commit }) {
    const uid = rootGetters["auth/uid"];

    const projectsRef = ref(database, `users/${uid}/projects`);
    const projects = await get(projectsRef).then((snapshot) => snapshot.val());

    if (projects) commit(SET_KEY, { key: "items", value: projects });

    return projects;
  },
  async [CREATE](_, payload) {
    try {
      const {
        data: { projectId, ...others },
      } = await createProject(payload);

      return { isSuccess: true, projectId, ...others };
    } catch (errorData) {
      const { details: errorCode } = JSON.parse(JSON.stringify(errorData));
      return { isSuccess: false, errorCode };
    }
  },
  async [GET]({ commit }, { id }) {
    try {
      const projectRef = ref(database, `projects/${id}`);
      const snapshot = await get(projectRef);

      const currentProject = snapshot.val();
      commit(SET_KEY, { key: "currentProject", ...currentProject });

      const projectIndexRef = ref(database, `projects/${id}/index/root`);

      const index = await get(projectIndexRef).then((snapshot) => snapshot.val() || {});
      commit(SET_ITEM_NESTED, { id, nestedKey: "index", ...index });

      onChildAdded(projectIndexRef, (snapshot) => {
        commit(SET_ITEM_NESTED, { id, nestedKey: "index", [snapshot.key]: { ...snapshot.val() } });
      });

      onChildChanged(projectIndexRef, (snapshot) => {
        commit(SET_ITEM_NESTED, { id, nestedKey: "index", [snapshot.key]: { ...snapshot.val() } });
      });

      onChildRemoved(projectIndexRef, (snapshot) => {
        commit(DEL_ITEM_NESTED, { id, nestedKey: "index", itemId: snapshot.key });
      });

      return { isSuccess: true, ...currentProject };
    } catch (error) {
      console.log("ERR", error);
      return { isSuccess: false, error };
    }
  },
  async [REMOVE](_, { projectId }) {
    try {
      await removeProject({ projectId });

      return { isSuccess: true, projectId };
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ rootGetters, commit }) {
    const uid = rootGetters["auth/uid"];

    const projectsRef = ref(database, `users/${uid}/projects`);

    onChildAdded(projectsRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildChanged(projectsRef, (snapshot) => {
      commit(SET_ITEM, { id: snapshot.key, ...snapshot.val() });
    });

    onChildRemoved(projectsRef, (snapshot) => {
      commit(DEL_ITEM, { id: snapshot.key });
    });

    return { isSuccess: true };
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
