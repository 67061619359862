<template>
  <div
    @click="$emit('click')"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="userbar__icon rounded-lg"
    :class="{ 'is-active': isActive }"
  >
    <img v-if="isHovering || isActive" :src="require(`@/assets/icons/${icon}-active.svg`)" />
    <img v-else :src="require(`@/assets/icons/${icon}.svg`)" />
  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHovering: false,
  }),
};
</script>

<style scoped>
.userbar__icon {
  width: 35px;
  height: 35px;
  padding: 7px;
  margin: auto;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  background-color: #272926;
}

.userbar__icon img {
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
  transition: transform 0.7s ease-in-out;
}

.userbar__icon img:hover {
  transform: rotate(360deg);
}

.userbar__icon.is-active,
.userbar__icon:hover {
  background-color: #00d959;
  border-radius: 15px;
}
</style>
