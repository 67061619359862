import Vue from "vue";
import { collection, getDocs, query, orderBy, startAfter, limit, onSnapshot } from "firebase/firestore";

import { firestore } from "@/common/firebase";

import { ObjectToSortedArray, createModuleResetter } from "@/helpers/utils";

import { FETCH, LISTEN } from "../../actions";
import { SET, SET_ITEM, DEL_ITEM, RESET_STORE } from "../../mutations";

const getInitState = () => ({
  items: {},
  unsubscribe: null,
  lastVisible: null, // Pagination: track the last document
  isAllLoaded: false, // Flag to indicate if all documents have been loaded
});

const getters = {
  itemsSorted: (state) => {
    return ObjectToSortedArray(state.items, "createdAt");
  },
};

const mutations = {
  [SET](state, { key, value }) {
    Vue.set(state, key, value);
  },

  [SET_ITEM](state, { id, ...others }) {
    Vue.set(state.items, id, { ...others });
  },
  [DEL_ITEM](state, { id }) {
    Vue.delete(state.items, id);
  },

  [RESET_STORE]: createModuleResetter(getInitState),
};

const actions = {
  async [FETCH]({ commit, state }, { pageSize = 10 } = {}) {
    if (state.isAllLoaded) return; // Prevent further loading if all documents are fetched

    try {
      let queryRef = query(collection(firestore, `logs`), orderBy("createdAt"), limit(pageSize));

      if (state.lastVisible) {
        queryRef = query(queryRef, startAfter(state.lastVisible));
      }

      const snapshot = await getDocs(queryRef);

      if (!snapshot.empty) {
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        commit(SET, { key: "lastVisible", value: lastVisible });

        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        data.forEach((value) => {
          commit(SET_ITEM, value);
        });
      } else {
        commit(SET, { key: "isAllLoaded", value: true });
      }

      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      return { isSuccess: false, error };
    }
  },

  async [LISTEN]({ state, commit }) {
    const collectionRef = collection(firestore, `logs`);

    state.unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        commit(SET_ITEM, { id: doc.id, ...doc.data() });
      });
    });
  },
};

export default {
  namespaced: true,
  state: getInitState(),
  actions,
  mutations,
  getters,
};
