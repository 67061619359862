import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

import { functions } from "./firebase";

// Auth
export const removeUser = httpsCallable(functions, "removeUser");
export const sendWelcomeEmail = httpsCallable(functions, "sendWelcomeEmail");
export const sendMagicLinkEmail = httpsCallable(functions, "sendMagicLinkEmail");

// Checkout
export const initiateStripeCheckout = httpsCallable(functions, "initiateStripeCheckout");
export const initiateStripeCheckoutTopup = httpsCallable(functions, "initiateStripeCheckoutTopup");
export const initiateStripeCheckoutSetup = httpsCallable(functions, "initiateStripeCheckoutSetup");
export const cancelStripeSubscription = httpsCallable(functions, "cancelStripeSubscription");
export const upgradeStripeSubscription = httpsCallable(functions, "upgradeStripeSubscription");
export const topupStripePaymentMethod = httpsCallable(functions, "topupStripePaymentMethod");
export const confirmStripePaymentMethod = httpsCallable(functions, "confirmStripePaymentMethod");
export const removeStripePaymentMethod = httpsCallable(functions, "removeStripePaymentMethod");
export const getStripeProratedAmount = httpsCallable(functions, "getStripeProratedAmount");

// Projects
export const createProject = httpsCallable(functions, "createProject");
export const removeProject = httpsCallable(functions, "removeProject");

// Projects - AI - Chatbot
export const replyToUser = httpsCallable(functions, "replyToUser", { timeout: 540000 });
export const replyToGuest = httpsCallable(functions, "replyToGuest", { timeout: 540000 });

// Tasks
export const renameProjectTask = httpsCallable(functions, "renameProjectTask");

// Teammates
export const trainAITeammate = httpsCallable(functions, "trainAITeammate");

// Tools
export const createTaskTranscription = httpsCallable(functions, "createTaskTranscription");
export const createTaskJob = httpsCallable(functions, "createTaskJob", { timeout: 540000 });

// Visitors Tools
export const visitorFetchWordpressPosts = httpsCallable(functions, "visitorFetchWordpressPosts");
export const visitorSuggestPosts = httpsCallable(functions, "visitorSuggestPosts");

export const sendFacebookEvent = httpsCallable(functions, "sendFacebookEvent");

// HTTP functions
export const getHttpUrl = (name) => {
  const functions = getFunctions();
  const app = getApp();

  const { projectId } = app.options;
  const { region } = functions;

  const emulator = functions.emulatorOrigin;

  let url = "";

  if (emulator) {
    url = `${emulator}/${projectId}/${region}/${name}`;
  } else {
    url = `https://${region}-${projectId}.cloudfunctions.net/${name}`;
  }

  return url;
};
