<template>
  <div class="vh-100 overflow-y-scroll">
    <div class="d-block text-center pt-3 pb-3">
      <b-img :src="require('@/assets/character/smiling.png')" rounded width="80" height="80" />
    </div>

    <div class="bg-dark m-auto rounded-lg p-4 mt-4" style="max-width: 600px;">
      <register-selection
        v-if="!currentStep"
        @continue-with-email-magiclink="currentStep = AUTH_STEPS.EMAIL_MAGICLINK"
        @continue-with-email-password="currentStep = AUTH_STEPS.EMAIL_PASSWORD"
      />

      <register-email-magiclink v-else-if="currentStep === AUTH_STEPS.EMAIL_MAGICLINK" @previous="currentStep = null" />
      <register-email-password v-else-if="currentStep === AUTH_STEPS.EMAIL_PASSWORD" @previous="currentStep = null" />
    </div>

    <layout-spacer />
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";
import { AUTH_STEPS } from "@/constants/auth";

import LayoutSpacer from "@/components/Layouts/Spacer";

import RegisterSelection from "@/components/Auth/Register/Select";
import RegisterEmailMagiclink from "@/components/Auth/Register/EmailMagiclink";
import RegisterEmailPassword from "@/components/Auth/Register/EmailPassword";

export default {
  name: "Register",
  components: {
    BImg,
    RegisterSelection,
    RegisterEmailMagiclink,
    RegisterEmailPassword,
    LayoutSpacer,
  },
  data() {
    return {
      currentStep: null,

      AUTH_STEPS,
    };
  },
  beforeRouteEnter(_, __, next) {
    next((vm) => {
      if (vm.isAuthenticated) next({ name: "Projects" });
    });
  },
};
</script>
