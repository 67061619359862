<template>
  <div>
    <input type="checkbox" id="theme-toggle" v-model="isDarkMode" />
    <label for="theme-toggle"><span></span></label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { UPDATE_DARK_MODE } from "@/store/actions";

export default {
  name: "SwitchDarkMode",
  computed: {
    isDarkMode: {
      get() {
        return this.$store.state.customize.isDarkMode;
      },
      set(value) {
        this.switchMode(value);
      },
    },
    timeRange() {
      return this.$store.state.customize.timeRange;
    },
  },
  created() {
    this.addTag(this.isDarkMode);
  },
  methods: {
    ...mapActions("customize", { update: UPDATE_DARK_MODE }),
    switchMode(status) {
      this.update({ status });
      this.addTag(status);
    },

    addTag(status) {
      const $body = document.querySelector("body");

      if (status) $body.classList.add("theme--dark");
      else $body.classList.remove("theme--dark");
    },
  },
};
</script>

<style lang="scss" scoped>
$toggle-size: 25px;
$switch-w: 100px;
$switch-h: 50px;
$switch-handle-scale: 0.65;
$switch-off-handle-x: -0.125em;
$switch-on-handle-x: calc(100% - 0.125em);
$switch-transition-duration: 0.2s;

#theme-toggle {
  display: none;

  & + label {
    font-size: $toggle-size;
    display: flex;
    height: $switch-h;
    width: $switch-w;
    border-radius: 50px;
    background-size: auto 8em;
    background-position: bottom;
    background-image: linear-gradient(180deg, #021037 0%, #20206a 19%, #4184b1 66%, #62e7f7 100%);
    transition: $switch-transition-duration;
    border: 0.125em solid hsl(207, 30%, 95%);
    overflow: hidden;

    span {
      background: #fffad8;
      border-radius: 50%;
      height: $switch-h;
      width: $switch-h;
      transform: translateX($switch-off-handle-x) scale($switch-handle-scale);
      transition: $switch-transition-duration;
      cursor: pointer;
      box-shadow: 0 0 0.25em 0.0625em #fbee8d, 0 0 2em 0 #ffeb3b, inset -0.25em -0.25em 0 0 #fbee8e,
        inset -0.3125em -0.3125em 0 0.625em #fff5b2;
      margin-top: $switch-off-handle-x;
    }
  }

  &:checked {
    font-size: 18px;

    & + label {
      background-position: top;
      border-color: hsl(207, 30%, 50%);

      span {
        background: transparent;
        transform: translateX($switch-on-handle-x) scale($switch-handle-scale);
        box-shadow: inset -0.1875em -0.1875em 0 0 #fbe7ef, inset -0.5625em -0.5625em 0 0 #fffff7;
      }
    }
  }
}
</style>
